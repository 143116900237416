import { IPermission } from 'interfaces'
import authRequired from './authRequired'
import hasPermission from './hasPermission'

export { authRequired, hasPermission }

interface PermissionList {
  [propName: string]: IPermission
}

export default {
  authRequired,
} as PermissionList
