import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={408} height={120} viewBox='0 0 408 120' {...props}>
      <g data-name='Grupo 822'>
        <path data-name='Caminho 496' d='M144 0h120v120H144zm0 0h120v120H144z' fill='none' />
        <path
          data-name='Caminho 497'
          d='M230.989 34.01L192.24 72.76l-21.109-21.051L162.84 60l29.4 29.4 47.04-47.04zM204 1.2A58.8 58.8 0 10262.8 60 58.821 58.821 0 00204 1.2zm0 105.84A47.04 47.04 0 11251.04 60 47.027 47.027 0 01204 107.04z'
          fill='#fff'
        />
        <g data-name='baseline-check_circle_outline-24px' opacity={0.3}>
          <path data-name='Caminho 496' d='M0 0h120v120H0zm0 0h120v120H0z' fill='none' />
          <path
            data-name='Caminho 497'
            d='M86.989 34.01L48.24 72.76 27.131 51.709 18.84 60l29.4 29.4 47.04-47.04zM60 1.2A58.8 58.8 0 10118.8 60 58.821 58.821 0 0060 1.2zm0 105.84A47.04 47.04 0 11107.04 60 47.027 47.027 0 0160 107.04z'
            fill='#fff'
          />
        </g>
        <g data-name='baseline-check_circle_outline-24px' opacity={0.3}>
          <path data-name='Caminho 496' d='M288 0h120v120H288zm0 0h120v120H288z' fill='none' />
          <path
            data-name='Caminho 497'
            d='M374.989 34.01L336.24 72.76l-21.109-21.051L306.84 60l29.4 29.4 47.04-47.04zM348 1.2A58.8 58.8 0 10406.8 60 58.821 58.821 0 00348 1.2zm0 105.84A47.04 47.04 0 11395.04 60 47.027 47.027 0 01348 107.04z'
            fill='#fff'
          />
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
