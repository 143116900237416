import * as React from 'react'

const LogoAlfa: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
  className,
  ...props
}) => {
  return (
    <svg
      {...props}
      width='60%'
      height='60%'
      viewBox='0 0 62 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_801_1649)'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M0 2.64224V0.321777H8.46394V2.64224H2.8487H0ZM0 11.6865V4.65517H2.8487H7.97089V6.93371H2.8487V9.36601H8.64198V11.6865H0Z'
          fill='#8A0808'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.17624 7.91196H10.9154C10.9428 8.56896 11.1208 9.05821 11.4769 9.37969C11.833 9.71522 12.3534 9.86897 13.0245 9.86897C13.5998 9.86897 14.0517 9.77109 14.3667 9.57544C14.6817 9.37969 14.846 9.10014 14.846 8.75067C14.846 8.56896 14.8049 8.40121 14.7365 8.26142C14.668 8.12164 14.5584 8.0098 14.4215 7.93991C14.2161 7.82808 13.7093 7.64636 12.9012 7.40872C12.0932 7.17108 11.4085 6.93344 10.8606 6.70978C10.0662 6.38828 9.44999 5.95493 9.03907 5.42374C8.6282 4.89255 8.40906 4.26351 8.40906 3.56457C8.40906 2.47423 8.80624 1.60755 9.60062 0.978506C10.395 0.321511 11.4906 0 12.8465 0C14.1886 0 15.257 0.335489 16.0376 1.00647C16.8319 1.67745 17.2154 2.60004 17.2154 3.7463H14.531C14.4763 3.21511 14.2845 2.8237 13.9832 2.57209C13.6819 2.32046 13.2436 2.19466 12.6547 2.19466C12.1891 2.19466 11.8193 2.29251 11.5317 2.48821C11.244 2.68391 11.1071 2.92155 11.1071 3.22909C11.1071 3.73232 11.7919 4.20759 13.1614 4.65491C13.408 4.73878 13.5998 4.7947 13.723 4.83664L13.8326 4.87857C15.2432 5.35385 16.1197 5.73128 16.4758 6.01085C16.8319 6.3044 17.1058 6.66785 17.2975 7.05925C17.4893 7.46464 17.5715 7.91196 17.5715 8.40121C17.5715 9.53353 17.1469 10.4141 16.2978 11.0432C15.4486 11.6723 14.2571 11.9937 12.7232 11.9937C11.2715 11.9937 10.1758 11.6583 9.40884 10.9733C8.68299 10.3023 8.25842 9.28187 8.17624 7.91196Z'
          fill='#8A0808'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M19.8726 11.6865V2.62826H16.6815V0.321777H25.9261V2.62826H22.735V11.6865H19.8726Z'
          fill='#8A0808'
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M25.7479 0.321777H29.843C30.9934 0.321777 31.8699 0.559415 32.4588 1.04867C33.0478 1.53792 33.349 2.25084 33.349 3.18742C33.349 3.7885 33.1984 4.30571 32.9108 4.7111C32.6232 5.13046 32.1849 5.45197 31.596 5.6896C32.2945 5.82939 32.8423 6.12294 33.2121 6.58424C33.5819 7.04554 33.76 7.64663 33.76 8.37352C33.76 9.50584 33.4449 10.3306 32.8012 10.8757C32.1575 11.4069 31.1714 11.6865 29.8293 11.6865H25.7479V10.5542H29.7197C30.7332 10.5542 31.4728 10.3864 31.9247 10.065C32.3767 9.7434 32.6095 9.21224 32.6095 8.48535C32.6095 7.70254 32.4041 7.12942 31.9795 6.76597C31.5549 6.40252 30.8838 6.2208 29.9662 6.2208H25.7479V5.20035H29.5005C30.4867 5.20035 31.1714 5.04658 31.5824 4.75303C31.9932 4.45948 32.1986 3.95624 32.1986 3.27128C32.1986 2.60031 31.9932 2.11105 31.596 1.80353C31.1989 1.49599 30.5277 1.35621 29.6101 1.35621H25.7479V0.321777Z'
          fill='#8A0808'
        />
        <path
          d='M32.39 11.6865L37.5259 0.321777H38.8133L43.8943 11.6865H42.7713L41.21 8.17781H35.1154L33.5404 11.6865H32.39ZM35.5948 7.07349H40.7032L38.1559 1.25835L35.5948 7.07349Z'
          fill='#8A0808'
        />
        <path
          d='M43.1144 11.6865V0.321777H44.5251L52.3179 10.5122V0.321777H53.3998V11.6865H51.9207L44.1964 1.48201V11.6865H43.1144Z'
          fill='#8A0808'
        />
        <path
          d='M52.4538 11.6865V0.321777H53.5632V6.54231L60.4659 0.321777H61.9997L56.7543 5.07454L61.9997 11.6865H60.5754L55.9188 5.81542L53.5632 7.9262V11.6865H52.4538Z'
          fill='#8A0808'
        />
      </g>
      <defs>
        <clipPath id='clip0_801_1649'>
          <rect width='100%' height='100%' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default LogoAlfa
