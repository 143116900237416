import React from 'react'
import { Box } from '@material-ui/core'

const Content: React.FC = ({ children }) => {
  return (
    <Box display='flex' flexDirection='column' justifyContent='center' p={4}>
      {children}
    </Box>
  )
}

export default Content
