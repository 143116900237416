import React from 'react'
import { Link } from 'react-router-dom'
import { Container } from './styles'

interface IAction {
  path: string
}

const Action: React.FC<IAction> = ({ children, path }) => {
  return (
    <Link to={path}>
      <Container>{children}</Container>
    </Link>
  )
}

export default Action
