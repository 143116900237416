export const spacing = 8
export const ZIndex = {
  SPINNER: 1284,
  LOADER: 1285,
  CIRCULAR_SPINNER: 1286,
  SIDEBAR: 1100,
  SIDEPANEL: 1095,
  RESPONSIVE_ACTIONS: 1000,
  NAVBAR: 900,
  STICKY: 500
}

export const Width = {
  SIDEBAR: '330px',
  SIDEBAR_COLLAPSED: '60px'
}

export const Height = {
  NAVBAR: '60px',
  SIDEBAR_ITEM: '60px',
  RESPONSIVE_ACTIONS: '60px'
}
