import styled from 'styled-components'
import { IContent } from '.'

export const Container = styled.div<IContent>`
  width: 100%;
  border-radius: 4px;
  margin: ${({ margin }) => (margin ? margin : '')};
  ${({ withoutBackground, theme, color, padding }) =>
    !withoutBackground &&
    `
      background: ${
        color === 'gray'
          ? theme.gray_200
          : color === 'light'
          ? theme.light
          : color === 'unset'
          ? 'unset'
          : 'white'
      };
      padding: ${padding};
    `}
`
