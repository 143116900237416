export const covenantCardQuery = ['id', 'empresa', 'tipo_de_recebimento', 'status']

export const covenantViewQuery = [
  'id',
  'empresa',
  'tipo_de_recebimento',
  'margem_rescisao',
  'margem_emprestimo',
  'idade_minima',
  'idade_maxima'
]
