import React from 'react'
import { UserAvatar as Avatar } from './styles'

export interface IUserAvatar {
  color?: string
  borderSize?: number
  size?: number
  children?: React.ReactNode
}
const UserAvatar: React.FC<IUserAvatar> = ({ size, color, children, borderSize }) => {
  return (
    <Avatar size={size} color={color} borderSize={borderSize}>
      {children}
    </Avatar>
  )
}

export default UserAvatar
