import { IEntity, ENTITIES } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'
import { routeCodes } from 'constants/routes'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.EMPLOYEE)
export const EmployeeEntity: IEntity = {
  id: ENTITIES.EMPLOYEE,
  name: 'Funcionário',
  path: routeCodes.ENTITY.EMPLOYEE,
  theme,
  materialTheme,
  logo,
  permissions: [],
  component: DefaultComponent,
  route: Routes,
}

export default EmployeeEntity
