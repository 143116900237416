// import { createCipheriv, createDecipheriv, randomBytes } from 'crypto'
// import Cookies from 'js-cookie'
import CryptoJS from 'crypto-js'
import Cookies from 'js-cookie'

const secretKey = '72b66284a44a35cc0fcd1df2c1649a90'

// const algorithm = 'aes-256-cbc'
// const iv = randomBytes(16)

export const cookies = Cookies.withConverter({
  read: (value) => {
    const bytes = CryptoJS.AES.decrypt(value, secretKey)
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText
  },
  write: (value) => {
    return CryptoJS.AES.encrypt(value as string, secretKey).toString()
  },
})

export const getCookies = (value: string) => {
  try {
    const currentCookies = cookies.get(value)
    if (currentCookies !== undefined && currentCookies) {
      return JSON.parse(currentCookies)
    }
    return {}
  } catch (e) {
    return {}
  }
}

export default cookies
