export const legalPersonGet = [
  'id',
  'cnpj',
  'razao_social',
  'nome_fantasia',
  'ramo_atividade',
  'valor_da_folha',
  'capital_social',
  'valor_faturamento_bruto_ano',
  'data_fundacao',
  'uf_fundacao',
  'natureza_juridica',
  'nacionalidade',
  'segmento',
  'isenta_cobranca_iof',
  'endereco',
  'nome_administrador',
  'email_administrador',
  'cpf_administrador',
  'sobrenome_administrador',
  'pagamento',
  'contatos',
  'total_funcionarios',
  'pmr',
  'pmp',
  'numero_filiais',
  'concorrentes',
  'telefone',
]
