import { IEntity, ENTITIES } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.SALES_CHANNEL)
export const SalesChannelEntity: IEntity = {
  id: ENTITIES.SALES_CHANNEL,
  name: 'Canal de vendas',
  path: '/sales-channel',
  theme,
  materialTheme,
  logo,
  permissions: [],
  component: DefaultComponent,
  route: Routes,
}

export default SalesChannelEntity
