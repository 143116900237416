import React from 'react'
import { Box, useTheme, useMediaQuery } from '@material-ui/core'

const HeaderInfos: React.FC = ({ children }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      display='flex'
      width={isMobile ? '100%' : 'auto'}
      mt={isMobile ? 3 : 0}
      textAlign={isMobile ? 'center' : 'left'}
      alignItems='flex-start'
    >
      {children}
    </Box>
  )
}

export default HeaderInfos
