import React from 'react'
import { Helmet } from 'react-helmet'

interface IMeta {
  metaTitle: string
  metaTitleSuffix?: string
  metaDescription?: string
  metaKeywords?: string
  canonicalPath?: string
  shouldBeIndexed?: boolean
}

const MetaTags: React.FC<IMeta> = ({
  metaTitle,
  metaTitleSuffix,
  metaDescription,
  metaKeywords,
  canonicalPath,
  shouldBeIndexed,
}) => {
  const baseUrl = 'https://ui-creditofolha.com'
  const fullMetaTitle = `${metaTitle} | ${metaTitleSuffix}`
  const canonicalUrl = `${baseUrl}${canonicalPath}`

  function getRobotsValue() {
    return shouldBeIndexed ? 'index,follow' : 'noindex,follow'
  }

  return (
    <Helmet>
      <title>{fullMetaTitle}</title>
      <meta name='description' content={metaDescription} />
      <meta name='keywords' content={metaKeywords} />
      <link rel='canonical' href={canonicalUrl} />
      <meta name='robots' content={getRobotsValue()} />
    </Helmet>
  )
}

export default MetaTags
