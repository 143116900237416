export enum InsurerType {
  PARCELA = 'parcela',
  FINANCIADO = 'financiado',
}

export interface IInsurer {
  id: string
  cnpj: string
  razao_social: string
  nome_fantasia: string
  idade_maxima_seguro: number
  comissao_financeira: number
  interface: string
  descricao: string
  tipo_seguro: InsurerType
  nome: string
}

export default IInsurer
