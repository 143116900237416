import * as React from 'react'

export function ProofOfIncome(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 180 100'
      width='100%'
      height='80%'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path d='M0 0h180v100H0z' />
        </clipPath>
      </defs>
      <g data-name='comprovante de renda'>
        <g data-name='Grupo 1258' clipPath='url(#prefix__a)'>
          <g data-name='Grupo 3'>
            <path
              data-name='Caminho 909'
              d='M67.702 12.98h54.436l-3.072 4.266V83.63l-59.982-.6V18.614l2.133-4.351z'
              fill='#fff'
            />
            <g data-name='deed of sale' fill='#cacaca'>
              <g data-name='Grupo 965'>
                <path
                  data-name='Linha 116'
                  d='M110.054 38.65h-19.87a.9.9 0 01-.9-.906.9.9 0 01.9-.9h19.87a.9.9 0 01.9.9.9.9 0 01-.9.906z'
                />
                <path
                  data-name='Linha 117'
                  d='M110.053 45.874H67.608a.9.9 0 01-.9-.906.9.9 0 01.9-.9h42.445a.9.9 0 01.9.9.9.9 0 01-.9.906z'
                />
                <path
                  data-name='Linha 118'
                  d='M90.187 54.002H67.608a.9.9 0 01-.9-.906.9.9 0 01.9-.9h22.579a.9.9 0 01.9.9.9.9 0 01-.9.906z'
                />
                <path
                  data-name='Caminho 855'
                  d='M86.557 73.665a5.349 5.349 0 01-3.157-.762 2.86 2.86 0 01-1.14-2.087 6.765 6.765 0 00-1.73.571 3.78 3.78 0 00-1.649 1.22q-.024.063-.05.123a.902.902 0 01-1.685-.645q.027-.081.061-.159c.165-.473.3-1.547-.322-3.8a21.244 21.244 0 00-.35-1.142 13.59 13.59 0 01-2.086 1.5 10.263 10.263 0 00-2.225 1.678 2.382 2.382 0 00-.613 2.078.903.903 0 01-1.786.268 4.205 4.205 0 011.041-3.537 11.533 11.533 0 012.623-2.018c1.1-.693 2.147-1.348 2.45-2.173a.9.9 0 011.677-.048 24.509 24.509 0 011.01 2.916 14.7 14.7 0 01.479 2.444 9.532 9.532 0 01.906-.454c.659-.288 2.282-.913 3.195-.524a1.552 1.552 0 01.832 1.378c.064.445.118.729.418.944.436.314 1.733.738 5.521.049a.903.903 0 11.323 1.777 21.411 21.411 0 01-3.743.403z'
                />
              </g>
              <g data-name='Grupo 966'>
                <path
                  data-name='Caminho 856'
                  d='M93.469 84.2H58.578a.9.9 0 01-.9-.9V20.185A8.2 8.2 0 0165.863 12h59.163v1.806H65.863a6.387 6.387 0 00-6.379 6.379V82.4h33.985z'
                />
                <path
                  data-name='Caminho 857'
                  d='M119.267 84.2h-3.33v-1.8h2.427V18.972a6.972 6.972 0 0113.943 0v3.641a.9.9 0 01-.9.9h-5.76V21.71h4.855v-2.738a5.166 5.166 0 00-10.331 0V83.3a.9.9 0 01-.904.9z'
                />
              </g>
            </g>
            <g data-name='Grupo 1137' transform='translate(94.993 69.036)'>
              <circle data-name='Elipse 40' cx={9.482} cy={9.482} r={9.482} fill='#06b71a' />
              <path
                data-name='Caminho 917'
                d='M10.991 11.747a1.291 1.291 0 00-.321-.92 2.91 2.91 0 00-1.09-.631 12.859 12.859 0 01-1.326-.575 4.225 4.225 0 01-.956-.656 2.636 2.636 0 01-.621-.871 2.924 2.924 0 01-.222-1.192 2.646 2.646 0 01.762-1.954 3.215 3.215 0 012.025-.889v-1.51h1.128V4.08a2.946 2.946 0 011.954 1.041 3.429 3.429 0 01.705 2.24h-2.038a1.947 1.947 0 00-.349-1.266 1.154 1.154 0 00-.935-.42 1.189 1.189 0 00-.9.328 1.249 1.249 0 00-.317.907 1.193 1.193 0 00.31.861 3.42 3.42 0 001.158.656 12.811 12.811 0 011.39.643 4 4 0 01.917.677 2.623 2.623 0 01.571.861 2.959 2.959 0 01.2 1.125 2.634 2.634 0 01-.748 1.947 3.28 3.28 0 01-2.06.882v1.4H9.1v-1.4a3.394 3.394 0 01-2.24-1.026 3.309 3.309 0 01-.793-2.309h2.039a1.876 1.876 0 00.4 1.288 1.454 1.454 0 001.142.445 1.4 1.4 0 00.981-.328 1.139 1.139 0 00.362-.885z'
                fill='#fff'
              />
            </g>
          </g>
          <path
            data-name='Caminho 918'
            d='M77.103 26.52v-1.53h-3.06v1.53zm-7.649 1.529v8.411h12.238v-8.411zm12.238-1.529a1.524 1.524 0 011.53 1.53v8.41a1.524 1.524 0 01-1.53 1.53H69.454a1.524 1.524 0 01-1.53-1.53l.008-8.414a1.518 1.518 0 011.522-1.526h3.06v-1.53a1.524 1.524 0 011.529-1.53h3.06a1.524 1.524 0 011.53 1.53v1.53z'
            fill='#cacaca'
          />
        </g>
      </g>
    </svg>
  )
}

export default ProofOfIncome
