import styled from 'styled-components'
import { spacing } from 'helpers'
import { ICard } from '.'

export const Container = styled.div<ICard>`
  width: 100%;
  min-height: 60px;
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.light};
  border-right: 5px solid ${({ theme, color }) => (color ? theme[color] : theme.primary)};
  cursor: pointer;
  border-radius: 4px;
  margin-top: ${spacing(1)};
  padding: ${spacing(2)} ${spacing(1)};
  position: relative;

  h1 {
    min-width: 3rem;
  }

  ${({ active, theme, color }) =>
    active &&
    `
    background: ${color ? theme[color] : theme.primary};
    border-right: 0px;
    padding-right: calc(${spacing(1)} + 5px);

    & > *, & > * > * {
      color: white !important;
    }

    & svg {
      color: white !important;
    }

    &:after {
      content: ' ';
      width: 15px;
      height: 20px;
      background: ${color ? theme[color] : theme.primary};
      position: absolute;
      top: 10px;
      right: -3px;
      transform: rotate(45deg);
    }
  `}
`
