import React, { createContext, useState, useCallback } from 'react'
import { IRequestProps, IFilters, IAPIContext, IAuth } from 'interfaces'
import moment from 'moment'
import jwtDecode from 'jwt-decode'
import { useErrors, useCore } from 'hooks'
import PATHS from 'constants/paths'
import apiAsyncRequest from 'services/api'
import { refreshToken as refreshTokenTime } from 'constants/config'
import Spinner from 'components/Spinner'
import { getToken } from 'components/Provider/CoreProvider'

interface IAPIProvider {
  children?: React.ReactNode
}

interface TokenDto {
  foo: string
  exp: number
  iat: number
}

let refreshingPromise: Promise<any> | null = null
export const ApiContext = createContext<IAPIContext>({} as IAPIContext)
const ApiProvider: React.FC<IAPIProvider> = ({ children }) => {
  const { setError } = useErrors()
  const {
    setAuth,
    logout,
    entity: { module },
  } = useCore()
  const [filters, setFilters] = useState<IFilters>({} as IFilters)
  const [requestCount, setRequest] = useState(0)
  const apiRequest = useCallback(
    (props: IRequestProps) => {
      const addRequestCount = () => setRequest((prevState) => prevState + 1)
      const subtractRequestCount = () =>
        setRequest((prevState) => (prevState <= 0 ? 0 : prevState - 1))
      addRequestCount()
      return apiAsyncRequest({
        ...props,
        setError,
      }).finally(() => subtractRequestCount())
    },
    [setError]
  )

  const isTokenExpired = useCallback((token) => {
    if (!token) {
      return false
    }
    const jwt = jwtDecode<TokenDto>(token)
    if (!jwt) {
      return false
    }
    const expiresDate = moment(jwt.exp * 1000)
    const diff = moment().valueOf() - expiresDate.valueOf()
    const timeRemaining = moment.duration(diff).asMinutes()
    if (timeRemaining >= refreshTokenTime) {
      return true
    }
    return false
  }, [])

  const doRequest = useCallback(
    (props: IRequestProps): Promise<any> => {
      const { token, refreshToken } = getToken
      const isExpired = isTokenExpired(token)
      const refreshTokenRequest = async () => {
        let response
        refreshingPromise = apiRequest({
          path: PATHS.REFRESH_TOKEN,
          method: 'POST',
          disableErrors: true,
          body: {
            refresh: refreshToken,
          },
        })
        response = await refreshingPromise
        if (response && response.refresh) {
          setAuth(response as IAuth)
          refreshingPromise = null
        } else {
          logout()
        }
        return response
      }
      const validateToken = async () => {
        if (isExpired) {
          let response
          if (!refreshingPromise) {
            response = await refreshTokenRequest()
          } else {
            response = await refreshingPromise
          }
          if (!response || !response.refresh) {
            return new Promise(() => null)
          }
        }
        return apiRequest(props)
      }
      return validateToken()
    },
    [apiRequest, isTokenExpired, logout, setAuth]
  )

  const values: IAPIContext = {
    apiRequest: doRequest,
    filters,
    setFilters,
    isTokenExpired,
    requestCount,
    module: module?.subModule,
  }

  return (
    <ApiContext.Provider value={values}>
      {requestCount > 0 && <Spinner noOverlay />}
      {children}
    </ApiContext.Provider>
  )
}

export default ApiProvider
