import React, { useState, useCallback, useEffect } from 'react'
import { Box } from '@material-ui/core'
import { Timer } from '@material-ui/icons'
import Button from 'components/Button'

interface IButtonWithTimer {
  time: number
  onClick: Function
  text: string
}

let timeoutId: number = 0

const ButtonWithTimer: React.FC<IButtonWithTimer> = ({ time, onClick, text }) => {
  const [count, setCount] = useState<number>(time)

  const buttonCount = useCallback(() => {
    timeoutId = setTimeout(() => {
      if (count > 0) {
        setCount((prevState) => prevState - 1)
      } else {
        setCount(0)
      }
    }, 1000)
  }, [count, setCount])

  const onClickButton = async () => {
    if (onClick) await onClick()
    setCount(time)
  }

  useEffect(() => {
    buttonCount()
    return () => clearTimeout(timeoutId)
  }, [buttonCount])

  return (
    <Button variant='text' onClick={() => onClickButton()} color='inherit' disabled={count > 0}>
      {count > 0 && (
        <>
          <Timer />
          <Box fontWeight='fontWeightBold' ml={1}>
            {text} {count}s
          </Box>
        </>
      )}
      {count === 0 && <Box fontWeight='fontWeightBold'>{text}</Box>}
    </Button>
  )
}

export default ButtonWithTimer
