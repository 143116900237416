import { IPermission } from 'interfaces'
import DefaultEntity from 'app/Default'

export const authRequired: IPermission = {
  validate: function () {
    const { auth } = this

    if (!auth?.token_jwt) {
      return false
    }

    return true
  },
  action: function () {
    const { history, logout } = this
    if (history && logout) {
      logout()
      history.push(DefaultEntity.path)
    }
  },
}

export default authRequired
