import React, {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
} from 'react'
import { get } from 'lodash'
import { IError } from 'interfaces'

interface IErrorProvider {
  children?: React.ReactNode
}

interface IErrorContext {
  error: IError
  setError: Dispatch<SetStateAction<IError>>
  getFieldError(path: string): string
  getArrayFieldError(path: string): any[]
  getErrorByField(fieldName: string, errors?: any): string
}

export const ErrorContext = createContext<IErrorContext>({} as IErrorContext)
export let getError = () => ({} as IError)

const ErrorProvider: React.FC<IErrorProvider> = ({ children }) => {
  const [error, setError] = useState<IError>({} as IError)
  const getFieldError = useCallback(
    (path: string) => {
      if (!error.data) {
        return ''
      }
      const text: string[] = get(error.data, path, '')
      return text.length > 0 ? text[0] : ''
    },
    [error.data]
  )
  const getErrorByField = useCallback(
    (fieldName: string, errors?: any): string => {
      if (!error.data) {
        return ''
      }
      const searchErrors = errors || error.data
      const fieldErrorKey = Object.keys(searchErrors).reduce((result, key) => {
        const currentError = get(searchErrors, key, {})
        if (key === fieldName && currentError instanceof Array) {
          return key
        }
        if (currentError instanceof Array) {
          return result
        }
        if (Object.keys(currentError).length > 0) {
          const error = getErrorByField(fieldName, currentError)
          return `${key}.${error}`
        }
        return result
      }, '')
      if (errors) {
        return fieldErrorKey
      }
      const errorText = get(error.data, fieldErrorKey, [])
      return errorText instanceof Array && errorText.length > 0 ? errorText[0] : errorText
    },
    [error]
  )
  const getArrayFieldError = useCallback(
    (path: string): any[] => {
      if (!error.data) {
        return []
      }
      const array: any[] = get(error.data, path, '')
      return array
    },
    [error.data]
  )
  const getNastedFields = useCallback((body: any, path?: string) => {
    return Object.keys(body || {}).reduce((result, field): string[] => {
      const currentField = body[field]
      if (currentField instanceof Array) {
        return [...result, path ? `${path}.${field}` : field]
      }
      if (currentField instanceof Object) {
        const childFields = getNastedFields(currentField, field)
        return [...result, ...childFields, path ? `${path}.${field}` : field]
      }
      return result
    }, [] as string[])
  }, [])

  useEffect(() => {
    if (error.code === 400 && !error.alert) {
      const alertFields = getNastedFields(error.body)
      setError({
        ...error,
        alert: [
          ...alertFields,
          'calculadora',
          'non_field_errors',
          'details',
          'cliente',
          'contratos_id',
          '0',
          'empresa',
        ],
      })
    }
  }, [error, getNastedFields, setError])

  useEffect(() => {
    getError = () => error
  }, [error])

  const values: IErrorContext = {
    error,
    setError,
    getFieldError,
    getErrorByField,
    getArrayFieldError,
  }
  return <ErrorContext.Provider value={values}>{children}</ErrorContext.Provider>
}

export default ErrorProvider
