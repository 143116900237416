import styled from 'styled-components'
import { spacing } from 'helpers'

export const Container = styled.header`
  width: 100%;
  background: ${({ theme }) => theme.primary};
  margin: 0 auto;
  padding: ${spacing(2)} 0;

  svg {
    max-height: 30px;
  }
  svg g,
  svg g path {
    fill: white;
  }
  svg g path[data-name='fundo'] {
    fill: none !important;
  }
`
