import * as React from 'react'

function Error500(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={363.487} height={120} viewBox='0 0 363.487 120' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#fff}.prefix__b{opacity:.2}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M155.617 80.865c7.437 0 13.043-5.927 13.043-13.787v-3.73c0-7.86-5.606-13.787-13.043-13.787s-13.043 5.931-13.043 13.791v3.73c0 7.86 5.606 13.783 13.043 13.783zm-7.826-17.517c0-4.964 3.292-8.57 7.826-8.57s7.826 3.605 7.826 8.57v3.73c0 4.964-3.292 8.57-7.826 8.57s-7.826-3.605-7.826-8.57zM186.857 106.667l-.261-2.319h-16.8l-18.209-20.87h-6.4c-6.613 0-10.977 4.184-12.965 12.412l-5.217 20.87 5.061 1.263 5.223-20.891c1.808-7.477 5.17-8.436 7.9-8.436h4.03l18.211 20.87h14.2a7.138 7.138 0 01-1.474 3.835 4.679 4.679 0 01-3.71 1.383h-14.39l-12.261-14.717-4.007 3.344 9.482 11.367h-4.87v5.217h83.478v-5.217H185.43a15.692 15.692 0 001.427-8.111z'
      />
      <path
        className='prefix__a'
        d='M189.53 106.961v5.217h23.478a2.609 2.609 0 002.29-1.359l15.652-28.7a2.608 2.608 0 00-2.29-3.858h-46.956a2.605 2.605 0 00-2.264 1.315l-10.435 18.261 4.529 2.588 9.683-16.946h41.048l-12.806 23.482zM226.052 0h-46.956a10.445 10.445 0 00-10.435 10.435v33.913a10.445 10.445 0 0010.435 10.435h2.609v10.435a5.142 5.142 0 003.569 4.949 5.311 5.311 0 001.688.279 5.135 5.135 0 004.135-2.1l10.174-13.563h22.174a13.058 13.058 0 0013.043-13.043v-31.3A10.445 10.445 0 00226.052 0zm5.217 41.739a7.836 7.836 0 01-7.826 7.826h-23.482a2.607 2.607 0 00-2.087 1.044l-10.952 14.608V52.174a2.608 2.608 0 00-2.609-2.609h-5.217a5.223 5.223 0 01-5.217-5.217V10.435a5.223 5.223 0 015.217-5.217h46.957a5.223 5.223 0 015.217 5.217z'
      />
      <path
        className='prefix__a'
        d='M213.773 13.808l-9.895 9.895-9.895-9.895-3.689 3.689 9.895 9.895-9.895 9.895 3.689 3.689 9.895-9.895 9.895 9.895 3.689-3.689-9.895-9.895 9.895-9.895z'
      />
      <g>
        <path
          className='prefix__a'
          d='M155.617 80.865c7.437 0 13.043-5.927 13.043-13.787v-3.73c0-7.86-5.606-13.787-13.043-13.787s-13.043 5.931-13.043 13.791v3.73c0 7.86 5.606 13.783 13.043 13.783zm-7.826-17.517c0-4.964 3.292-8.57 7.826-8.57s7.826 3.605 7.826 8.57v3.73c0 4.964-3.292 8.57-7.826 8.57s-7.826-3.605-7.826-8.57zM186.857 106.667l-.261-2.319h-16.8l-18.209-20.87h-6.4c-6.613 0-10.977 4.184-12.965 12.412l-5.217 20.87 5.061 1.263 5.223-20.891c1.808-7.477 5.17-8.436 7.9-8.436h4.03l18.211 20.87h14.2a7.138 7.138 0 01-1.474 3.835 4.679 4.679 0 01-3.71 1.383h-14.39l-12.261-14.717-4.007 3.344 9.482 11.367h-4.87v5.217h83.478v-5.217H185.43a15.692 15.692 0 001.427-8.111z'
        />
        <path
          className='prefix__a'
          d='M189.53 106.961v5.217h23.478a2.609 2.609 0 002.29-1.359l15.652-28.7a2.608 2.608 0 00-2.29-3.858h-46.956a2.605 2.605 0 00-2.264 1.315l-10.435 18.261 4.529 2.588 9.683-16.946h41.048l-12.806 23.482zM226.052 0h-46.956a10.445 10.445 0 00-10.435 10.435v33.913a10.445 10.445 0 0010.435 10.435h2.609v10.435a5.142 5.142 0 003.569 4.949 5.311 5.311 0 001.688.279 5.135 5.135 0 004.135-2.1l10.174-13.563h22.174a13.058 13.058 0 0013.043-13.043v-31.3A10.445 10.445 0 00226.052 0zm5.217 41.739a7.836 7.836 0 01-7.826 7.826h-23.482a2.607 2.607 0 00-2.087 1.044l-10.952 14.608V52.174a2.608 2.608 0 00-2.609-2.609h-5.217a5.223 5.223 0 01-5.217-5.217V10.435a5.223 5.223 0 015.217-5.217h46.957a5.223 5.223 0 015.217 5.217z'
        />
        <path
          className='prefix__a'
          d='M213.773 13.808l-9.895 9.895-9.895-9.895-3.689 3.689 9.895 9.895-9.895 9.895 3.689 3.689 9.895-9.895 9.895 9.895 3.689-3.689-9.895-9.895 9.895-9.895z'
        />
      </g>
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M28.617 80.865c7.437 0 13.043-5.927 13.043-13.787v-3.73c0-7.86-5.606-13.787-13.043-13.787s-13.043 5.931-13.043 13.791v3.73c0 7.86 5.606 13.783 13.043 13.783zm-7.826-17.517c0-4.964 3.292-8.57 7.826-8.57s7.826 3.605 7.826 8.57v3.73c0 4.964-3.292 8.57-7.826 8.57s-7.826-3.605-7.826-8.57zM59.857 106.667l-.261-2.319h-16.8l-18.209-20.87h-6.4c-6.613 0-10.977 4.184-12.965 12.412L.005 116.76l5.061 1.263 5.223-20.891c1.808-7.477 5.17-8.436 7.9-8.436h4.03l18.211 20.87h14.2a7.138 7.138 0 01-1.474 3.835 4.679 4.679 0 01-3.71 1.383h-14.39l-12.261-14.717-4.007 3.344 9.482 11.367H23.4v5.217h83.478v-5.217H58.43a15.692 15.692 0 001.427-8.111z'
        />
        <path
          className='prefix__a'
          d='M62.53 106.961v5.217h23.478a2.609 2.609 0 002.29-1.359l15.652-28.7a2.608 2.608 0 00-2.29-3.858H54.704a2.605 2.605 0 00-2.264 1.315L42.005 97.837l4.529 2.588 9.683-16.946h41.048l-12.806 23.482zM99.052 0H52.096a10.445 10.445 0 00-10.435 10.435v33.913a10.445 10.445 0 0010.435 10.435h2.609v10.435a5.142 5.142 0 003.569 4.949 5.311 5.311 0 001.688.279 5.135 5.135 0 004.135-2.1l10.174-13.563h22.174a13.058 13.058 0 0013.043-13.043v-31.3A10.445 10.445 0 0099.052 0zm5.217 41.739a7.836 7.836 0 01-7.826 7.826H72.961a2.607 2.607 0 00-2.087 1.044L59.922 65.217V52.174a2.608 2.608 0 00-2.609-2.609h-5.217a5.223 5.223 0 01-5.217-5.217V10.435a5.223 5.223 0 015.217-5.217h46.957a5.223 5.223 0 015.217 5.217z'
        />
        <path
          className='prefix__a'
          d='M86.773 13.808l-9.895 9.895-9.895-9.895-3.689 3.689 9.895 9.895-9.895 9.895 3.689 3.689 9.895-9.895 9.895 9.895 3.689-3.689-9.895-9.895 9.895-9.895z'
        />
      </g>
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M282.617 80.865c7.437 0 13.043-5.927 13.043-13.787v-3.73c0-7.86-5.606-13.787-13.043-13.787s-13.043 5.931-13.043 13.791v3.73c0 7.86 5.606 13.783 13.043 13.783zm-7.826-17.517c0-4.964 3.292-8.57 7.826-8.57s7.826 3.605 7.826 8.57v3.73c0 4.964-3.292 8.57-7.826 8.57s-7.826-3.605-7.826-8.57zM313.857 106.667l-.261-2.319h-16.8l-18.209-20.87h-6.4c-6.613 0-10.977 4.184-12.965 12.412l-5.217 20.87 5.061 1.263 5.223-20.891c1.808-7.477 5.17-8.436 7.9-8.436h4.03l18.211 20.87h14.2a7.138 7.138 0 01-1.474 3.835 4.679 4.679 0 01-3.71 1.383h-14.39l-12.261-14.717-4.007 3.344 9.482 11.367h-4.87v5.217h83.478v-5.217H312.43a15.692 15.692 0 001.427-8.111z'
        />
        <path
          className='prefix__a'
          d='M316.53 106.961v5.217h23.478a2.609 2.609 0 002.29-1.359l15.652-28.7a2.608 2.608 0 00-2.29-3.858h-46.956a2.605 2.605 0 00-2.264 1.315l-10.435 18.261 4.529 2.588 9.683-16.946h41.048l-12.806 23.482zM353.052 0h-46.956a10.445 10.445 0 00-10.435 10.435v33.913a10.445 10.445 0 0010.435 10.435h2.609v10.435a5.142 5.142 0 003.569 4.949 5.311 5.311 0 001.688.279 5.135 5.135 0 004.135-2.1l10.174-13.563h22.174a13.058 13.058 0 0013.043-13.043v-31.3A10.445 10.445 0 00353.052 0zm5.217 41.739a7.836 7.836 0 01-7.826 7.826h-23.482a2.607 2.607 0 00-2.087 1.044l-10.952 14.608V52.174a2.608 2.608 0 00-2.609-2.609h-5.217a5.223 5.223 0 01-5.217-5.217V10.435a5.223 5.223 0 015.217-5.217h46.957a5.223 5.223 0 015.217 5.217z'
        />
        <path
          className='prefix__a'
          d='M340.773 13.808l-9.895 9.895-9.895-9.895-3.689 3.689 9.895 9.895-9.895 9.895 3.689 3.689 9.895-9.895 9.895 9.895 3.689-3.689-9.895-9.895 9.895-9.895z'
        />
      </g>
    </svg>
  )
}

export default Error500
