import { useContext } from 'react'
import { ErrorContext } from 'components/Provider/ErrorProvider'

export function useErrors() {
  const error = useContext(ErrorContext)

  return error
}

export default useErrors
