import * as React from 'react'

function LogoAlfaMin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 152.85 154.31' {...props}>
      <defs>
        <style>{'.prefix__cls-2{fill:#1e2c51}'}</style>
      </defs>
      <g id='prefix__Camada_2' data-name='Camada 2'>
        <g id='prefix__Camada_1-2' data-name='Camada 1'>
          <path fill='#d9a700' d='M76.17 3.09l19.79 39.92-40.57.5L76.17 3.09z' />
          <path className='prefix__cls-2' d='M76.17 0L0 154.31h152.85zm0 5.58l72.66 146.23H4z' />
          <path className='prefix__cls-2' d='M76.23 79.9L96 43H56.89zm0-1.34l-18.38-35H95z' />
          <path
            className='prefix__cls-2'
            d='M58.42 115.83l18.24-36.94H40.07zm0-1.34L41 79.49h34.7z'
          />
          <path
            className='prefix__cls-2'
            d='M94.54 115.83l18.24-36.94h-36.6zm0-1.34l-17.4-35h34.68zM40.62 152.27l18.24-37h-36.6zm0-1.34l-17.4-35H57.9z'
          />
          <path className='prefix__cls-2' d='M76.73 152.27l18.24-37h-36.6zm0-1.34l-17.4-35H94z' />
          <path
            className='prefix__cls-2'
            d='M112.84 152.27l18.24-37H94.49zm0-1.34l-17.4-35h34.69z'
          />
          <path
            className='prefix__cls-2'
            d='M114.75 151.81a2 2 0 11-2-2 2 2 0 012 2M78.64 151.81a2 2 0 11-2-2 2 2 0 012 2M42.53 151.81a2 2 0 11-2-2 2 2 0 012 2M60.84 115.38a2 2 0 11-2-2 2 2 0 012 2M96.45 115.38a2 2 0 11-2-2 2 2 0 012 2M132.07 115.38a2 2 0 11-2-2 2 2 0 012 2M24.23 115.38a2 2 0 11-2-2 2 2 0 012 2M78.15 79a2 2 0 11-2-2 2 2 0 012 2M42.53 79a2 2 0 11-2-2 2 2 0 012 2M113.76 79a2 2 0 11-2-2 2 2 0 012 2'
          />
        </g>
      </g>
    </svg>
  )
}

export default LogoAlfaMin
