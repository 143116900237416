import React from 'react'
import { Typography } from '@material-ui/core'

import { Container } from './styles'

interface IHeaderInfosItem {
  title: string
  isContainerDivider?: boolean
}

const HeaderInfosItem: React.FC<IHeaderInfosItem> = ({
  children,
  title,
  isContainerDivider = false,
}) => {
  return (
    <Container isContainerDivider={isContainerDivider}>
      <Typography color='secondary'>{title}</Typography>
      {children}
    </Container>
  )
}

export default HeaderInfosItem
