import styled from 'styled-components'
import { spacing } from 'helpers'
import { lighten } from '@material-ui/core'

interface IItem {
  active?: boolean
}
export const Item = styled.div<IItem>`
  width: 100%;
  padding: ${spacing(2)};
  cursor: pointer;
  transition: 0.2s all cubic-bezier(0.25, 0.1, 0.23, 0.63);

  &:hover {
    background: ${({ theme }) => lighten(theme.low_dark, 0.9)};
  }
  ${({ active, theme }) =>
    active &&
    `
    background: ${lighten(theme.low_dark, 0.9)};
    font-weight: 500;
  `};
`
