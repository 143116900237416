export const userCardQuery = [
  'nome',
  'sobrenome',
  'email',
  'telefone_celular',
  'email_verificado',
  'telefone_celular_verificado',
  'ativo',
  'cpf',
  'ultimo_login',
  'possui_acesso',
  'sub_corbans',
]

export const userViewQuery = [
  'nome',
  'sobrenome',
  'email',
  'telefone_celular',
  'email_verificado',
  'telefone_celular_verificado',
  'ativo',
  'cpf',
  'ultimo_login',
  'convite_gestor',
]

export const salesChannelOperatorsList = [
  'nome',
  'sobrenome',
  'email',
  'ultimo_login',
  'possui_acesso',
  'cpf',
  'validade_certificado',
  'observacao',
]
