import styled from 'styled-components'
import { spacing, breakpoint } from 'helpers'
import { Height, ZIndex } from 'constants/css'

export const Container = styled.div`
  display: flex;
  align-items: center;

  button {
    margin-left: ${spacing(2)};
  }

  & > button:first-child {
    margin-left: 0;
  }

  @media (max-width: ${breakpoint('sm')}) {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    height: ${Height.RESPONSIVE_ACTIONS};
    justify-content: center;
    width: 100%;
    background: white;
    z-index: ${ZIndex.RESPONSIVE_ACTIONS};
  }
`
