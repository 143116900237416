import React, { useEffect, useMemo } from 'react'
import { Field, useForm } from 'react-final-form'
import { range } from 'lodash'
import { useMediaQuery } from 'hooks'
import { Grid } from '@material-ui/core'
import { Input, maxLength } from 'components/Forms'

interface IFieldToken {
  lengthToken?: number
  hasError?: boolean
  label?: string
  setToken?(field: string, value: any): void
}

export const FieldToken: React.FC<IFieldToken> = ({ hasError, label, lengthToken }) => {
  const { mutators, setConfig } = useForm()
  const setToken = (field: string, value: any) => {
    mutators.setField([field, value])
  }
  const inputs = useMemo(() => range(0, lengthToken), [lengthToken])
  const isDevice = useMediaQuery('sm')

  const onKeyDown = (event: KeyboardEvent, index: number) => {
    const currentValue = ((event.target || {}) as HTMLInputElement).value
    if (Number(event.keyCode) === 8 && (index <= inputs.length || index >= 0) && !currentValue) {
      document.getElementById(`token[${index - 1}]`)?.focus()
      return event
    }
    if (Number(event.keyCode) === 8 && currentValue) {
      return event
    }
    if (index >= inputs.length) {
      return event
    }
    if (index <= inputs.length && currentValue) {
      return document.getElementById(`token[${index + 1}]`)?.focus()
    }
  }

  const onKeyUp = (event: KeyboardEvent, index: number) => {
    const currentValue = ((event.target || {}) as HTMLInputElement).value
    if (index < inputs.length && currentValue) {
      return document.getElementById(`token[${index + 1}]`)?.focus()
    }
  }

  const onPaste = (event: ClipboardEvent) => {
    const text = String(event.clipboardData?.getData('text'))
    if (text.length > 7) {
      return false
    }
    const token = Array.from(text)
    token.map((current, i) => setToken(`token[${i}]`, current))
    document.getElementById(`token[${inputs.length - 1}]`)?.focus()
    return true
  }

  useEffect(() => {
    if (!mutators.setField) {
      setConfig('mutators', {
        ...mutators,
        setField: ([[field, value]], state, utils) => {
          utils.changeValue(state, field, () => value)
        },
      })
    }
  }, [mutators, setConfig])

  if (isDevice) {
    return (
      <Field
        name='token'
        id='token'
        component={Input}
        label='Token'
        parse={maxLength(7)}
        withError={hasError}
      />
    )
  }

  return (
    <Grid container spacing={2} style={{ display: 'flex', justifyContent: 'center' }}>
      {inputs.map((i) => (
        <Grid item xs={1} key={i} zeroMinWidth>
          <Field
            name={`token[${i}]`}
            id={`token[${i}]`}
            component={Input}
            onKeyDown={(event: KeyboardEvent) => {
              onKeyDown(event, i)
            }}
            onKeyUp={(event: KeyboardEvent) => {
              onKeyUp(event, i)
            }}
            onPaste={onPaste}
            parse={maxLength(1)}
            size='medium'
            withError={hasError}
            disableHelperText
          />
        </Grid>
      ))}
    </Grid>
  )
}

export default FieldToken
