import React from 'react'
import { Typography, Box } from '@material-ui/core'

interface ITitle {
  py?: 0 | 1 | 2 | 3 | 4
  pb?: 0 | 1 | 2 | 3 | 4
  style?: React.CSSProperties
}
const Title: React.FC<ITitle> = ({ children, py, pb, style }) => {
  return (
    <Box py={py || 0} pb={pb} flexGrow={1} display='flex' alignItems='center'>
      <Typography variant='h1' style={style}>
        {children}
      </Typography>
    </Box>
  )
}

export default Title
