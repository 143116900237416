import * as React from 'react'

const LogoAlfa: React.FC<React.SVGProps<SVGSVGElement>> = ({
  width,
  height,
  className,
  ...props
}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 1171.6533 453.54666'
      height={'100%'}
      width={'100%'}
      id='svg2'
      version='1.1'
    >
      <defs id='defs6' />
      <g transform='matrix(1.3333333,0,0,-1.3333333,0,453.54667)' id='g10'>
        <g transform='scale(0.1)' id='g12'>
          <path
            id='path14'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 2075.65,1415.45 c 282.41,216.27 514.74,532.39 544.99,895.9 342.62,21.67 665.43,153.9 960.4,322.8 -1.87,150.17 -32.66,297.66 -69.85,442.46 -289.89,-269 -619.67,-508.31 -1000.29,-629.02 -23.29,-290.97 -166.24,-567.73 -391.61,-753.24 -304.07,-270.6 -731.27,-343.95 -1125.829,-311.56 102.249,-77.09 206.639,-154.98 325.759,-203.96 271.15,-29.71 539.35,80.56 756.43,236.62'
          />
          <path
            id='path16'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 1968.33,1160.09 c -102.52,-48.18 -207.99,-91.02 -318.28,-119.12 -81.9,-114.572 -175.05,-220.56 -277.56,-316.919 159.27,9.097 336.46,17.937 462.27,129.82 89.4,75.758 111.88,197.279 133.57,306.219'
          />
          <path
            id='path18'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 2375.45,1034.02 c -106.26,40.15 -218.16,66.92 -318.8,121.78 21.69,-108.67 45.78,-228.589 134.64,-304.339 124.21,-114.02 304.35,-115.102 462.54,-128.742 -103.32,93.953 -191.64,202.351 -278.38,311.301'
          />
          <path
            id='path20'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 2667.22,1940.08 c 282.39,-3.22 568.53,45.23 829.78,153.91 41.22,110.82 67.72,227.25 67.45,346.38 -256.69,-146.16 -539.37,-240.91 -831.66,-282.41 -12.84,-74.94 -35.33,-147.75 -65.57,-217.88'
          />
          <path
            id='path22'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 2593.07,1787.5 c -39.62,-65.84 -82.45,-129.82 -124.2,-194.32 239.84,-52.73 489.57,-54.08 731.29,-12.04 88.05,79.49 145.06,188.43 204.76,290.15 -268.21,-46.31 -538.03,-100.64 -811.85,-83.79'
          />
          <path
            id='path24'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 2335.03,1443.02 c -60.22,-51.4 -119.64,-104.13 -179.34,-156.32 162.48,-84.58 350.38,-126.08 533.2,-108.42 120.46,47.65 220.04,134.12 328.72,202.64 -229.68,-12.32 -459.87,3.48 -682.58,62.1'
          />
          <path
            id='path26'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='M 2007.41,915.43 C 1980.1,762.059 1852.69,638.66 1698.24,617.25 c 158.73,-13.918 274.1,-144.27 311.31,-292.289 17.67,146.668 135.45,258.027 275.43,292.289 -138.38,42.289 -258.03,149.102 -277.57,298.18'
          />
          <path
            id='path28'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 1480.36,2315.63 c 82.96,11.43 175.89,19.02 241.68,76.32 0.44,0.37 0.94,0.65 1.37,1.03 -472.9,81.32 -877.914,367.1 -1226.242,682.94 -0.254,0.22 -0.52,0.46 -0.777,0.69 -43.621,-143.47 -64.774,-292.57 -71.196,-441.93 323.086,-176.66 676.675,-347.16 1055.165,-319.05'
          />
          <path
            id='path30'
            style={{ fill: '#ef9118', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 1959.44,2883.1 c 174.07,-3.83 247.96,-68.2 268.36,-188.19 h -0.07 c -20.46,119.8 -94.37,184.36 -268.29,188.19 z M 1736.25,1651.27 c 229.39,85.12 439.78,245.45 546.06,470.03 103.06,216.01 104.38,464.94 67.98,697.01 -14.72,67.72 15.26,133.04 31.05,197.54 -220.02,-2.67 -440.31,5.63 -660.08,-3.74 -147.75,-68.52 -191.12,-265 -110.01,-400.44 0.42,0.22 0.84,0.46 1.27,0.68 38.9,20.45 76.81,43.38 118.11,59 71.46,29.72 165.95,-12.31 189.78,-86.72 39.88,-102.78 -6.68,-218.15 -81.64,-291.77 -96.9,-90.19 -230.73,-126.6 -360.29,-131.95 -368.05,-19.27 -729.675,105.46 -1044.457,288.81 11.512,-116.16 28.379,-233.67 71.207,-342.89 44.426,-42.02 108.415,-54.07 165.157,-72.27 237.687,-56.47 481.273,-98.23 726.463,-91.27 204.5,5.62 418.63,52.47 578.43,187.64 163.28,135.18 261.35,357.86 252.52,563.7 1.65,-18.88 6.69,-36.71 7.93,-55.75 8.34,-128.56 13.23,-260.5 -36.67,-380.81 -80.83,-218.41 -289.09,-364.02 -508.05,-421.31 -358.94,-97.44 -736.354,-37.74 -1092.631,42.02 51.672,-104.66 120.719,-198.88 190.32,-292.3 313.971,-60.76 646.151,-46.03 947.551,64.79'
          />
          <path
            id='path32'
            style={{ fill: '#393536', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 4968.14,1399.79 c 0,111.54 -30.07,189.12 -90.21,232.72 -60.14,43.56 -164.33,65.37 -312.48,65.37 H 4152.3 v -622.34 h 413.15 c 146.43,0 250.13,23.95 311.17,71.91 61,47.92 91.52,132.05 91.52,252.34 z m -65.37,740.01 c 0,102.82 -24.84,174.28 -74.52,214.41 -49.69,40.08 -137.28,60.15 -262.8,60.15 H 4152.3 V 1841.7 h 413.15 c 118.53,0 204.37,23.54 257.57,70.6 53.15,47.07 79.75,122.9 79.75,227.5 z m 227.49,-747.85 c 0,-167.36 -44.45,-287.23 -133.36,-359.55 C 4908,960.039 4764.18,923.879 4565.45,923.879 h -580.5 V 2563.4 h 577.89 c 179.52,0 307.24,-30.07 383.08,-90.21 75.83,-60.15 113.74,-163.43 113.74,-309.87 0,-108.1 -20.06,-191.77 -60.14,-251.02 -40.12,-59.27 -106.35,-98.5 -198.73,-117.67 109.83,-12.22 192.19,-51.89 247.11,-118.98 54.91,-67.12 82.36,-161.71 82.36,-283.7'
          />
          <path
            id='path34'
            style={{ fill: '#393536', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='M 6918.82,2563.4 V 923.879 H 6759.31 V 2307.15 L 6236.34,1255.97 H 6084.67 L 5561.7,2309.76 V 923.879 H 5402.19 V 2563.4 h 214.43 l 546.5,-1106.08 546.51,1106.08 h 209.19'
          />
          <path
            id='path36'
            style={{ fill: '#393536', fillOpacity: 1, fillRule: 'nonzero', stroke: 'none' }}
            d='m 7969.97,1635.12 -415.76,99.37 c -108.11,26.15 -185.66,69.7 -232.72,130.75 -47.07,61 -70.6,146.42 -70.6,256.25 0,162.13 46.17,280.65 138.58,355.62 92.38,74.93 238.82,112.44 439.3,112.44 83.68,0 159.06,-3.92 226.19,-11.77 67.09,-7.84 127.68,-19.6 181.73,-35.3 v -154.27 c -57.53,15.68 -121.18,27.45 -190.88,35.29 -69.75,7.86 -142.96,11.78 -219.65,11.78 -149.95,0 -255.4,-22.24 -316.4,-66.69 -61.04,-44.45 -91.52,-122.48 -91.52,-234.03 0,-73.21 14.79,-128.12 44.45,-164.73 29.62,-36.6 78.44,-62.76 146.43,-78.45 l 415.77,-101.97 c 134.21,-31.38 223.98,-76.29 269.32,-134.68 45.32,-58.41 68,-147.73 68,-268.02 0,-176.09 -45.36,-300.3 -135.98,-372.62 -90.66,-72.352 -235.34,-108.508 -434.07,-108.508 -106.35,0 -209.19,6.988 -308.55,20.918 -99.37,13.93 -177.81,32.238 -235.34,54.91 v 159.51 c 87.15,-26.15 176.06,-45.77 266.72,-58.83 90.62,-13.08 183.04,-19.62 277.17,-19.62 146.43,0 249.27,22.23 308.56,66.68 59.24,44.45 88.91,129.44 88.91,254.94 0,78.45 -14.84,135.08 -44.46,169.98 -29.66,34.84 -88.05,61.85 -175.2,81.05'
          />
        </g>
      </g>
    </svg>
  )
}

export default LogoAlfa
