export function getUserAgent(): string {
  if (typeof navigator !== 'undefined' && navigator.userAgent) {
    return navigator.userAgent
  }
  return ''
}

export function isIE(userAgent: string): boolean {
  return (
    userAgent.indexOf('MSIE ') > 1 ||
    userAgent.indexOf('Trident/') > -1 ||
    userAgent.indexOf('Edg') > -1
  )
}
