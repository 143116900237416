import * as React from 'react'

function LogoPefisa(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='100%'
      height='100%'
      viewBox='0 0 149.024 101.168'
      {...props}
    >
      <g data-name='Grupo 2'>
        <path data-name='Caminho 1' d='M149.025 0L0 25.661v75.5h149.025z' fill='#fff' />
        <path data-name='Caminho 2' d='M4.617 28.172h139.79V6.344z' fill='#003176' />
        <path data-name='Ret\xE2ngulo 1' fill='#ffd400' d='M4.617 32.796h139.79v63.756H4.617z' />
        <g data-name='Grupo 1' fill='#003176'>
          <path
            data-name='Caminho 3'
            d='M24.409 49.836a9.375 9.375 0 014.163.833 7.608 7.608 0 012.723 2.183 8.55 8.55 0 011.485 3.083 14.042 14.042 0 01.45 3.532 11.508 11.508 0 01-.743 4.321 7.823 7.823 0 01-2.07 3.015 8.659 8.659 0 01-3.218 1.756 14.105 14.105 0 01-4.185.585h-3.465V81.97h-6.482V49.836zm-1.98 14.537a4.271 4.271 0 003.128-1.17 4.914 4.914 0 001.192-3.645 5.691 5.691 0 00-1.035-3.69 4.248 4.248 0 00-3.465-1.261h-2.7v9.766z'
          />
          <path
            data-name='Caminho 4'
            d='M54.383 55.147H42.906v7.561h10.8v5.311h-10.8v8.642h11.926v5.31H36.425V49.836h17.958z'
          />
          <path
            data-name='Caminho 5'
            d='M76.029 49.836v5.311H64.554v7.561h10.8v5.311h-10.8V81.97h-6.482V49.836z'
          />
          <path data-name='Caminho 6' d='M79.225 49.836h6.48V81.97h-6.48z' />
          <path
            data-name='Caminho 7'
            d='M95.741 72.879a10.726 10.726 0 00.18 2.048 3.57 3.57 0 00.676 1.553 3.3 3.3 0 001.327.989 5.276 5.276 0 002.093.36 4.139 4.139 0 002.677-.967 3.628 3.628 0 001.194-2.993 5.4 5.4 0 00-.293-1.868 3.716 3.716 0 00-.968-1.418 6.931 6.931 0 00-1.777-1.147 25.779 25.779 0 00-2.723-1.058 23.189 23.189 0 01-3.735-1.575 10 10 0 01-2.633-2 7.13 7.13 0 01-1.553-2.655 11.15 11.15 0 01-.495-3.487q0-4.77 2.655-7.112a10.649 10.649 0 017.291-2.34 15.848 15.848 0 013.983.473 8.352 8.352 0 013.15 1.53 7.16 7.16 0 012.07 2.7 9.489 9.489 0 01.743 3.937v.9h-6.21a6.449 6.449 0 00-.811-3.533 2.984 2.984 0 00-2.7-1.237 4.5 4.5 0 00-1.8.315 3.042 3.042 0 00-1.148.832 2.882 2.882 0 00-.585 1.193 6.071 6.071 0 00-.158 1.395 4.639 4.639 0 00.63 2.5 5.652 5.652 0 002.7 1.868l5 2.16a15.329 15.329 0 013.014 1.688 8.21 8.21 0 011.868 1.89 6.285 6.285 0 01.968 2.227 12.486 12.486 0 01.27 2.7q0 5.087-2.947 7.4t-8.214 2.317q-5.492 0-7.854-2.386t-2.362-6.84v-1.305h6.48z'
          />
          <path
            data-name='Caminho 8'
            d='M128.145 49.836l8.5 32.134h-6.75l-1.485-6.8h-8.551l-1.485 6.8h-6.751l8.506-32.134zm-.855 20.028l-3.107-14.447h-.09l-3.104 14.444z'
          />
        </g>
      </g>
    </svg>
  )
}

export default LogoPefisa
