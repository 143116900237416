import React, { createContext, useState } from 'react'
import { TSnackBar, TLayoutContext } from 'types'
import SnackBar from 'components/SnackBar'

export const LayoutContext = createContext<TLayoutContext>({} as TLayoutContext)

const Layout: React.FC = ({ children }) => {
  const [snackBar, setSnackBar] = useState<TSnackBar>({} as TSnackBar)
  const showSnackBar = (props: TSnackBar) =>
    setSnackBar({
      ...props,
      opened: true
    })

  return (
    <LayoutContext.Provider value={{ showSnackBar }}>
      <SnackBar
        open={Boolean(snackBar && snackBar.opened)}
        autoHideDuration={6000}
        onClose={() => {
          setSnackBar(
            prevState =>
              ({
                ...prevState,
                opened: false
              } as TSnackBar)
          )
        }}
        severity={snackBar?.type}
      >
        <>{snackBar?.message || ''}</>
      </SnackBar>
      {children}
    </LayoutContext.Provider>
  )
}

export default Layout
