import { IPermission } from 'interfaces'

export const hasSubmodulePermission = (subModuleName: string): IPermission => ({
  validate: function () {
    const { entity } = this

    const hasPermission = entity?.module?.subModule === subModuleName
    if (hasPermission) {
      return true
    }
    return false
  },
  action: function () {
    return null
  },
})

export default hasSubmodulePermission
