import React, { useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { RouteContext } from 'components/Provider/RouteProvider'

const RouteSpy: React.FC = () => {
  const match = useRouteMatch()
  const { setMatch, match: contextMatch } = useContext(RouteContext)
  useEffect(() => {
    setMatch(match)
  }, [contextMatch.path, match, setMatch])
  return null
}

export default RouteSpy
