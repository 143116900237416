import React from 'react'
import { ILayout } from 'interfaces'

import { Container as DefaultContainer } from './styles'

interface IContainer extends ILayout {
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
}
const Container: React.FC<IContainer> = ({ children, maxWidth }) => {
  if (!children) {
    return null
  }
  return <DefaultContainer maxWidth={maxWidth ? maxWidth : 'xl'}>{children}</DefaultContainer>
}

export default Container
