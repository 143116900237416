import styled from 'styled-components'
import { spacing, breakpoint } from 'helpers'

interface IFormWrapper {
  maxWidth?: string
}

export const FormWrapper = styled.div<IFormWrapper>`
  background: ${({ theme }) => theme.light};
  border: 1px dashed ${({ theme }) => theme.border};
  padding: ${spacing(5)};
  ${({ maxWidth }) =>
    maxWidth &&
    `
    max-width: ${maxWidth};
  `}

  input {
    text-align: center;
  }

  iframe {
    min-width: 700px;
    min-height: 400px;
  }

  @media (max-width: ${breakpoint('md')}) {
    max-width: unset;
    padding: ${spacing(3)} ${spacing(2)};
    iframe {
      min-width: 100%;
    }
  }
`
