import * as React from 'react'

function LogoMin(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='100%' height='100%' viewBox='0 0 113.978 120' {...props}>
      <g id='Grupo_6' data-name='Grupo 6' transform='translate(-1207.423 -344.759)'>
        <g id='Grupo_3' data-name='Grupo 3' transform='translate(1207.423 344.759)'>
          <path
            id='Caminho_27'
            data-name='Caminho 27'
            d='M165.084,104.888c.578.367,1.092.8,1.645,1.194l12.812-5.4h0q1.585-3.377,3.194-6.975c1.307-2.923,3.965-11.943,3.992-12.034h0L151.537,99.619A37.962,37.962,0,0,1,165.084,104.888Z'
            transform='translate(-80.479 -81.673)'
            fill='#1f60aa'
          />
          <path
            id='Caminho_28'
            data-name='Caminho 28'
            d='M116.329,168.615c-.578-.367-1.092-.8-1.646-1.194l-12.811,5.4h0q-1.583,3.378-3.194,6.975c-1.306,2.922-3.964,11.943-3.991,12.034h0l35.187-17.944A37.983,37.983,0,0,1,116.329,168.615Z'
            transform='translate(-86.986 -71.859)'
            fill='#1f60aa'
          />
          <path
            id='Caminho_29'
            data-name='Caminho 29'
            d='M140.063,114.9a11.33,11.33,0,0,0-11.33,11.33v23.245a11.33,11.33,0,1,0,22.659,0V126.233A11.33,11.33,0,0,0,140.063,114.9Z'
            transform='translate(-83.089 -77.87)'
            fill='none'
          />
          <path
            id='Caminho_30'
            data-name='Caminho 30'
            d='M121.193,141.161v0l-18.655-.22a8.269,8.269,0,0,0-2.72.419,5.345,5.345,0,0,0-3.626,3.377L88.7,171.415c-2.084,6.807-.6,13.972,4.148,20.016l2.717,3.459h0S118.256,145.737,121.193,141.161Z'
            transform='translate(-87.777 -74.89)'
            fill='#2db4dd'
          />
          <path
            id='Caminho_31'
            data-name='Caminho 31'
            d='M159.961,135.32v0l18.654.28a8.246,8.246,0,0,0,2.721-.411,5.343,5.343,0,0,0,3.638-3.365l7.578-26.657c2.105-6.8.643-13.969-4.084-20.028l-2.706-3.468h0S162.912,130.754,159.961,135.32Z'
            transform='translate(-79.515 -81.673)'
            fill='#2db4dd'
          />
          <path
            id='Caminho_32'
            data-name='Caminho 32'
            d='M185.328,136.562l-18.656-.281-5.5-.084,2.988-4.623c1.133-1.753,5.69-11.122,10.723-21.683a35.307,35.307,0,0,0-5.636-4.517,34.815,34.815,0,0,0-16.786-5.359c-.648-.036-1.289-.1-1.946-.1H135.98a35.062,35.062,0,0,0-35.062,35.062v7.605h.15l18.657.222,5.5.065-2.973,4.633c-1.133,1.764-5.66,11.151-10.661,21.727a35.316,35.316,0,0,0,5.654,4.534,34.826,34.826,0,0,0,16.786,5.359c.648.036,1.288.1,1.945.1h14.532a35.062,35.062,0,0,0,35.062-35.061v-7.635c-.086,0-.145.036-.232.036Zm-30.752,14.63a11.33,11.33,0,1,1-22.659,0V127.948a11.33,11.33,0,1,1,22.659,0Z'
            transform='translate(-86.273 -79.585)'
            fill='#122754'
          />
        </g>
      </g>
    </svg>
  )
}

const MemoLogoMin = React.memo(LogoMin)
export default MemoLogoMin
