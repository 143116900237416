import styled from 'styled-components'
import { breakpoint, spacing } from 'helpers'

interface IisContainerDivider {
  isContainerDivider?: boolean
}

export const Container = styled.div<IisContainerDivider>`
  flex-grow: 1;
  margin-left: ${(props) => (!props.isContainerDivider ? spacing(3) : '0px')};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  :first-child {
    margin-left: 0;
  }

  @media (max-width: ${breakpoint('sm')}) {
    margin-left: 0;
    align-items: center;

    &:not(:first-child) {
      margin-right: ${spacing(1)};
    }
  }
`
