import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { IRouteCodes, IEntity } from 'interfaces'
import { getClientSysName } from 'theme/builder'
import MetaTags from 'components/MetaTags'
import Permissions from 'components/Permissions'
import RouteSpy from 'components/RouteSpy'

interface IRoutesBuilder {
  entity: IEntity
  routes: IRouteCodes
  roothPath?: string
}

interface IBuilder {
  routes: IRouteCodes
  roothPath?: string
}

const metaTitleSuffix = getClientSysName()

const buildRoutes = ({ routes, roothPath }: IBuilder): JSX.Element[] => {
  return Object.keys(routes || {})
    .reverse()
    .map((key) => {
      const {
        path,
        permissions,
        routes: currentRoute,
        name,
        componentProps,
        component: Component,
      } = routes[key] || {}
      const newPath = `${roothPath || ''}${path}`
      const params: IBuilder = {
        routes: currentRoute as IRouteCodes,
        roothPath: `${roothPath || ''}${path}`,
      }
      if (routes instanceof Object) {
        const buildedRoutes = buildRoutes(params)
        return (
          <Route
            path={newPath}
            key={newPath}
            render={(props) => (
              <>
                <MetaTags metaTitle={name} metaTitleSuffix={metaTitleSuffix} />
                <Permissions permissions={permissions} />
                <RouteSpy />
                <Component {...(componentProps || {})} {...props}>
                  <Switch>{buildedRoutes}</Switch>
                </Component>
              </>
            )}
          />
        )
      }
      return (
        <Route
          path={newPath}
          key={newPath}
          render={(props) => (
            <>
              <MetaTags metaTitle={name} metaTitleSuffix={metaTitleSuffix} />
              <Permissions permissions={permissions} />
              <RouteSpy />
              <Component {...(componentProps || {})} {...props} />
            </>
          )}
        />
      )
    })
}

const entityBuilder = ({ entity, routes }: IRoutesBuilder): JSX.Element => {
  const moduleRoutes = buildRoutes({ routes, roothPath: entity.path })
  const Layout = entity.component

  return (
    <Route path={entity.path} key={entity.path}>
      <Permissions permissions={entity.permissions} />
      <Layout>
        <Switch>{moduleRoutes}</Switch>
      </Layout>
    </Route>
  )
}

const buildModules = (entities: IEntity[]): JSX.Element => {
  const entitiesRoutes = entities.map((entity) => entityBuilder({ entity, routes: entity.route }))
  return <Switch>{entitiesRoutes}</Switch>
}

export default buildModules
