import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={396} height={120} viewBox='0 0 396 120' {...props}>
      <g data-name='Grupo 836'>
        <g opacity={0.3}>
          <g data-name='Grupo 833'>
            <path data-name='Ret\xE2ngulo 1057' fill='none' d='M0 0h120v120H0z' />
          </g>
          <g data-name='Grupo 835'>
            <g data-name='Grupo 834'>
              <path
                data-name='Caminho 846'
                d='M53.667 34.667v31.666l26.917 15.96 4.877-8.107L63.167 60.95V34.667zM117 47.333V3l-16.72 16.72A56.965 56.965 0 10117 60h-12.667A44.328 44.328 0 1191.35 28.65L72.667 47.333z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
        <g data-name='update-24px' opacity={0.3}>
          <g data-name='Grupo 833'>
            <path data-name='Ret\xE2ngulo 1057' fill='none' d='M276 0h120v120H276z' />
          </g>
          <g data-name='Grupo 835'>
            <g data-name='Grupo 834'>
              <path
                data-name='Caminho 846'
                d='M329.667 34.667v31.666l26.917 15.96 4.877-8.107-22.294-13.236V34.667zM393 47.333V3l-16.72 16.72A56.965 56.965 0 10393 60h-12.667a44.328 44.328 0 11-12.983-31.35l-18.683 18.683z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
        <g data-name='update-24px'>
          <g data-name='Grupo 833'>
            <path data-name='Ret\xE2ngulo 1057' fill='none' d='M138 0h120v120H138z' />
          </g>
          <g data-name='Grupo 835'>
            <g data-name='Grupo 834'>
              <path
                data-name='Caminho 846'
                d='M191.667 34.667v31.666l26.917 15.96 4.877-8.107-22.294-13.236V34.667zM255 47.333V3l-16.72 16.72A56.965 56.965 0 10255 60h-12.667a44.328 44.328 0 11-12.983-31.35l-18.683 18.683z'
                fill='#fff'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
