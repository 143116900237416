import React from 'react'
import { Container } from './styles'
import { ArrowForward } from '@material-ui/icons'
const ButtonWrapper: React.FC = ({ children }) => {
  return (
    <Container>
      {children}
      <ArrowForward />
    </Container>
  )
}

export default ButtonWrapper
