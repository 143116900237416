import styled from 'styled-components'
import { Box } from '@material-ui/core'
import { breakpoint } from 'helpers'

export const ModuleWrapper = styled.div`
  min-width: 400px;
  min-height: 50px;
  background: white;
  border-radius: 5px;

  @media (max-width: ${breakpoint('sm')}) {
    min-width: unset;
    width: 100%;
  }
`

export const ModuleHeader = styled(Box)`
  background: ${props => props.theme.gray};
  border: 1px solid ${props => props.theme.border};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`
