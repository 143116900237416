import { useMediaQuery, Divider, Grid, Tooltip, Typography, useTheme, Box } from '@material-ui/core'
import { InfoOutlined } from '@material-ui/icons'
import React from 'react'

interface IBlockItem {
  label: string
  tooltip?: string
  flexItem?: boolean
  xl?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xs?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}

const BlockItem: React.FC<IBlockItem> = ({
  label,
  children,
  flexItem,
  xs = 12,
  sm = 12,
  md,
  lg,
  xl,
  tooltip,
}) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery('md')
  return (
    <Grid
      item
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      style={{ gap: flexItem ? 2 : 0, display: flexItem ? 'flex' : '' }}
    >
      {flexItem && !smallScreen && <Divider orientation='vertical' variant='middle' flexItem />}
      <Box display='flex' flexDirection='column'>
        <Box display='flex' style={{ gap: '8px' }} alignItems='center'>
          <Typography style={{ opacity: 0.7, color: theme.palette.grey[800], fontSize: '14px' }}>
            {label}
          </Typography>
          {tooltip && (
            <Tooltip title={tooltip} placement='top' arrow>
              <InfoOutlined fontSize='small' />
            </Tooltip>
          )}
        </Box>
        <Typography style={{ color: theme.palette.grey[800], fontSize: '16px' }}>
          {children}
        </Typography>
      </Box>
    </Grid>
  )
}

export default BlockItem
