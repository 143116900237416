import styled from 'styled-components'
import { spacing } from 'helpers'

export const Container = styled.div`
  border: 1px solid ${({ theme }) => theme.gray_100};
  border-radius: 3px;
  width: 100%;
  position: relative;

  & > a,
  & > div,
  & > span {
    width: 100%;
    padding: ${spacing(2)};
    display: block;
    z-index: 1;
    position: relative;
  }

  & > svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    z-index: 0;
  }
`
