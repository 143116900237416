import React, { useEffect } from 'react'

import { CircularProgressBar, Wrapper } from './styles'

const Spinner: React.FC = () => {
  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.style.overflow = 'hidden'
    }
    return () => {
      if (body) {
        body.style.overflow = 'auto'
      }
    }
  }, [])

  return (
    <Wrapper>
      <CircularProgressBar color='inherit' size='50' />
    </Wrapper>
  )
}

export default React.memo(Spinner)
