import * as React from 'react'

export function ProofOfAddress(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 180 100'
      width='100%'
      height='80%'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path d='M0 0h180v100H0z' />
        </clipPath>
      </defs>
      <g data-name='Grupo 4' clipPath='url(#prefix__a)'>
        <path
          data-name='Caminho 914'
          d='M64.424 12.921h51.181l-2.889 4.011v62.416l-56.395-.562V18.221l2-4.091z'
          fill='#fff'
        />
        <g data-name='Grupo 1253'>
          <path
            data-name='Caminho 850'
            d='M93.929 62.393h9.9l-5.973 9h5.973L90.411 88l4.664-12.763h-4.664z'
            fill='#fedb01'
          />
          <g data-name='deed of sale' fill='#cacaca'>
            <g data-name='Grupo 965'>
              <g data-name='Grupo 964'>
                <path
                  data-name='Caminho 853'
                  d='M76.389 36.522H67.26a.849.849 0 01-.849-.849v-2.282a.85.85 0 111.7 0v1.433h7.431V24.261a.85.85 0 111.7 0v11.412a.849.849 0 01-.853.849z'
                />
                <path
                  data-name='Caminho 854'
                  d='M78.672 30.812a.845.845 0 01-.543-.2l-6.3-5.253-6.3 5.253a.849.849 0 01-1.087-1.3l6.847-5.706a.849.849 0 011.087 0l6.847 5.706a.849.849 0 01-.544 1.5z'
                />
              </g>
              <path
                data-name='Linha 116'
                d='M104.244 37.059H85.565a.849.849 0 01-.849-.851.849.849 0 01.849-.849h18.679a.849.849 0 01.849.849.849.849 0 01-.849.851z'
              />
              <path
                data-name='Linha 117'
                d='M104.244 43.852H64.339a.849.849 0 01-.849-.851.849.849 0 01.849-.849h39.905a.849.849 0 01.849.849.849.849 0 01-.849.851z'
              />
              <path
                data-name='Linha 118'
                d='M85.565 51.493H64.339a.849.849 0 01-.849-.851.849.849 0 01.849-.849h21.226a.849.849 0 01.849.849.849.849 0 01-.849.851z'
              />
              <path
                data-name='Caminho 855'
                d='M82.152 69.98a5.029 5.029 0 01-2.969-.716 2.689 2.689 0 01-1.072-1.962 6.361 6.361 0 00-1.626.537 3.555 3.555 0 00-1.551 1.147q-.022.059-.047.116a.849.849 0 01-1.585-.607q.026-.076.058-.15c.155-.445.28-1.454-.3-3.57a19.517 19.517 0 00-.329-1.073 12.776 12.776 0 01-1.961 1.407 9.65 9.65 0 00-2.092 1.578 2.239 2.239 0 00-.577 1.954.849.849 0 01-1.679.252 3.954 3.954 0 01.979-3.325 10.843 10.843 0 012.466-1.9c1.038-.652 2.019-1.267 2.3-2.043a.849.849 0 011.576-.045 23.041 23.041 0 01.95 2.742 13.817 13.817 0 01.45 2.3 8.969 8.969 0 01.852-.427c.619-.271 2.146-.859 3-.493a1.459 1.459 0 01.782 1.3c.06.419.111.685.393.888.41.3 1.629.694 5.191.046a.849.849 0 11.3 1.671 20.131 20.131 0 01-3.509.373z'
              />
            </g>
            <g data-name='Grupo 966'>
              <path
                data-name='Caminho 856'
                d='M88.651 79.887h-32.8a.849.849 0 01-.849-.849V19.7a7.7 7.7 0 017.7-7.7h55.626v1.7H62.7a6 6 0 00-6 6v58.492h31.953z'
              />
              <path
                data-name='Caminho 857'
                d='M112.908 79.887h-3.131v-1.7h2.282V18.555a6.555 6.555 0 0113.11 0v3.424a.849.849 0 01-.849.849h-5.414v-1.7h4.565v-2.574a4.857 4.857 0 00-9.714 0v60.483a.849.849 0 01-.849.85z'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ProofOfAddress
