import * as React from 'react'

export function IdentityCardFront(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 180 100'
      width='100%'
      height='80%'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path d='M0 0h180v100H0z' />
        </clipPath>
      </defs>
      <g data-name='Grupo 1257' clipPath='url(#prefix__a)'>
        <g data-name='Grupo 1'>
          <g data-name='Grupo 968'>
            <path data-name='Ret\xE2ngulo 1062' fill='#fff' d='M24 18h131v64H24z' />
            <path
              data-name='Ret\xE2ngulo 1062 - Contorno'
              d='M29 23v54h121V23H29m-5-5h131v64H24z'
              fill='#40b258'
            />
            <path
              data-name='Uni\xE3o 1'
              d='M153 88v-2h6v-5h2v7zM28 88v-2h123v2zm-10 0v-7h2v5h6v2zm141-9V21h2v58zM18 79V21h2v58zm141-60v-5h-6v-2h8v7zM18 19v-7h8v2h-6v5zm10-5v-2h123v2z'
              fill='#cacaca'
            />
          </g>
          <path
            data-name='Ret\xE2ngulo 1063'
            d='M105 36v26h31V36h-31m-1-1h33v28h-33z'
            fill='#cacaca'
          />
          <g data-name='Grupo 969' fill='#cacaca'>
            <path
              data-name='Caminho 864'
              d='M109.619 59.122v-2h1.8c.909 0 1.9-1.071 2.711-2.94a14.519 14.519 0 001.203-5.009 14.519 14.519 0 00-1.2-5.011c-.817-1.869-1.805-2.94-2.714-2.94h-1.8v-2h1.8c1.25 0 3.054.718 4.544 4.141a16.466 16.466 0 011.37 5.81 16.466 16.466 0 01-1.37 5.811c-1.49 3.422-3.294 4.138-4.544 4.138z'
            />
            <path
              data-name='Caminho 865'
              d='M130.76 49.174a5.472 5.472 0 01-5.456 5.475h-1.164a5.476 5.476 0 010-10.951h1.164a5.472 5.472 0 015.456 5.476zm-10.077 0a3.47 3.47 0 003.457 3.475h1.164a3.476 3.476 0 000-6.951h-1.164a3.47 3.47 0 00-3.457 3.476z'
            />
          </g>
          <path data-name='Caminho 915' d='M62 37v24H38V37z' fill='none' />
          <path
            data-name='Caminho 916'
            d='M57.53 54.81a.465.465 0 01.06-.23A11.442 11.442 0 0059 49.01a11.441 11.441 0 00-1.41-5.57.501.501 0 01.88-.48 12.724 12.724 0 01.01 12.08.506.506 0 01-.95-.23zM52.28 40.5a.521.521 0 01.09-.29.5.5 0 01.7-.12 10.339 10.339 0 013.27 3.75 11.543 11.543 0 01.01 10.31 10.378 10.378 0 01-3.25 3.75.502.502 0 01-.58-.82 9.388 9.388 0 002.94-3.39 10.537 10.537 0 00-.01-9.4 9.487 9.487 0 00-2.96-3.4.436.436 0 01-.21-.39zm-12.07 6.25a.469.469 0 01.15-.35A10.109 10.109 0 0143 44.39a8.877 8.877 0 014.34-1.05A5.538 5.538 0 0152.73 49a5.538 5.538 0 01-5.39 5.66.5.5 0 110-1A4.536 4.536 0 0051.73 49a4.536 4.536 0 00-4.39-4.66 7.834 7.834 0 00-3.85.93 9.465 9.465 0 00-2.42 1.85.513.513 0 01-.71 0 .548.548 0 01-.15-.37zm1.85 7.17a5.385 5.385 0 01.89-3.1 5.324 5.324 0 014.39-2.38.5.5 0 010 1 4.3 4.3 0 00-3.56 1.94 4.385 4.385 0 00-.71 2.54 6.609 6.609 0 00.1 1.04.502.502 0 01-.99.17 6.829 6.829 0 01-.12-1.21zM40 51.91a.585.585 0 01.02-.13 7.8 7.8 0 012.1-3.72 7.3 7.3 0 015.22-2.17 3.016 3.016 0 012.94 3.08 3.016 3.016 0 01-2.94 3.08 2.085 2.085 0 000 4.16 7.053 7.053 0 006.83-7.25 7.3 7.3 0 00-4.03-6.61 6.433 6.433 0 00-2.8-.59 9.937 9.937 0 00-3.61.67.488.488 0 01-.64-.29.5.5 0 01.29-.64 11.141 11.141 0 013.96-.73 7.513 7.513 0 013.24.68 8.3 8.3 0 014.6 7.51 8.055 8.055 0 01-7.83 8.25 3.083 3.083 0 010-6.16 2.014 2.014 0 001.94-2.08 2.014 2.014 0 00-1.94-2.08 6.3 6.3 0 00-4.51 1.87 6.8 6.8 0 00-1.85 3.27.5.5 0 01-.61.35.488.488 0 01-.38-.47z'
            fill='#828282'
          />
        </g>
      </g>
    </svg>
  )
}

export default IdentityCardFront
