import React from 'react'
import ErrorProvider from './ErrorProvider'
import ApiProvider from './ApiProvider'
import CoreProvider from './CoreProvider'
import RouteProvider from './RouteProvider'
import LayoutProvider from './LayoutProvider'

interface ICoreProvider {
  children?: React.ReactNode
}

const Core: React.FC<ICoreProvider> = ({ children }) => {
  return (
    <ErrorProvider>
      <CoreProvider>
        <ApiProvider>
          <RouteProvider>
            <LayoutProvider>{children}</LayoutProvider>
          </RouteProvider>
        </ApiProvider>
      </CoreProvider>
    </ErrorProvider>
  )
}

export default Core
