import styled from 'styled-components'
import { Box, Button } from '@material-ui/core'
import { KeyboardArrowDown } from '@material-ui/icons'
import { breakpoint, spacing } from 'helpers'
import { IOpened } from 'interfaces'
import breakpoints from 'constants/breakpoints'
import { Height, ZIndex } from 'constants/css'
import { Dropdown as DefaultDropdown } from 'components/Dropdown'

interface IMobileInfos extends IOpened {
  border?: boolean
}

interface IFilter {
  show?: boolean
}

export const Container = styled(Box)`
  width: 100%;
  display: flex;

  @media (max-width: ${breakpoint('md')}) {
    padding: 0 !important;
  }
`

export const Filter = styled.div<IFilter>`
  @media (max-width: ${breakpoint('md')}) {
    opacity: 0;
    visibility: hidden;
    background: white;
    border-radius: ${spacing(0.5)};

    ${({ show }) =>
      show &&
      `
        transition: .2s all cubic-bezier(0.25, 0.1, 0.4, 0.75);
        opacity: 1;
        visibility: visible;
      `}
  }
`

export const Mobile = styled.div<IMobileInfos>`
  display: none;

  @media (max-width: ${breakpoint('md')}) {
    display: flex;
    align-items: center;
    svg {
      min-width: 50px;
      color: white;
      ${({ border }) =>
        border &&
        `
        border-left: 1px solid white;
      `}
    }
  }
`

export const MobileLogo = styled.div<IOpened>`
  background: white;
  border-radius: 5px;
  height: 35px;
  width: 35px;
  overflow: hidden;
  transition: 0.2s all cubic-bezier(0.25, 0.1, 0.29, 0.76);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: unset;
  }
`

export const Navbar = styled.header`
  width: 100%;
  height: ${Height.NAVBAR};
  background: white;
  border-bottom: 1px solid #d2d4da;
  display: flex;
  align-items: center;
  transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: ${ZIndex.NAVBAR};

  @media (max-width: ${breakpoint('md')}) {
    background: ${({ theme }) => theme.secondary};
    position: initial;
    padding-left: 0;
    color: white;
    min-width: 90vw;
  }
`
export const Desktop = styled.div`
  display: none;

  @media (min-width: ${breakpoints.md + 1}px) {
    display: flex;
    align-items: center;
  }
`
export const SidePanelButton = styled(Button)`
  padding: 0;
  min-width: unset;

  svg {
    padding: 0;
    margin-left: -14px;
  }
`

export const ArrowDown = styled(KeyboardArrowDown)`
  margin-left: ${spacing(1)};
  cursor: pointer;
`

export const Dropdown = styled(DefaultDropdown)`
  .MuiPaper-root {
    min-width: 120px;
    svg {
      margin-right: ${spacing(1)};
    }
  }
`
