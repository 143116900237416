import { useContext } from 'react'
import { LayoutContext } from 'components/Provider/LayoutProvider'

export function useLayout() {
  const layout = useContext(LayoutContext)

  return layout
}

export default useLayout
