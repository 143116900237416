import React from 'react'
import { useHistory } from 'react-router-dom'
import { ArrowBack } from '@material-ui/icons'
import { Box } from '@material-ui/core'

interface IGoBack {
  onClick?: () => void
}
const GoBack: React.FC<IGoBack> = ({ onClick }) => {
  const history = useHistory()
  const defaultOnClick = () => history.goBack()
  return (
    <Box display='flex' alignItems='center' mb={1}>
      <ArrowBack
        color='secondary'
        style={{ fontSize: '12px' }}
        onClick={onClick || defaultOnClick}
        className='cursor-pointer'
      />
      <Box
        color='gray'
        component='span'
        fontSize='12px'
        onClick={onClick || defaultOnClick}
        className='cursor-pointer'
      >
        VOLTAR
      </Box>
    </Box>
  )
}

export default GoBack
