import React from 'react'
import Layout from 'templates/ModuleTemplate'
import ModuleGuard from 'components/ModuleGuard'

const Employee: React.FC = ({ children }) => {
  return (
    <Layout>
      <ModuleGuard>{children}</ModuleGuard>
    </Layout>
  )
}

export default Employee
