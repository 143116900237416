import React from 'react'
import { Radio, FormControlLabel, FormControl } from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'

interface IRadio extends FieldRenderProps<any, HTMLElement> {
  label: string
  size: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'default'
}
const UncontrolledRadio = React.forwardRef(
  (
    { id, name, checked, onChange, onClick, disabled, label, size, error, color, value }: IRadio,
    ref
  ) => {
    return (
      <FormControl error={error}>
        <FormControlLabel
          control={
            <Radio
              id={id}
              name={name}
              checked={checked}
              value={value}
              onChange={onChange}
              onClick={onClick}
              disabled={disabled}
              size={size || 'small'}
              color={color || 'primary'}
            />
          }
          label={label}
          ref={ref}
        />
      </FormControl>
    )
  }
)

export default UncontrolledRadio
