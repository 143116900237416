import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import { ZIndex } from 'constants/css'
import { spacing } from 'helpers'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: inherit;
  padding: ${spacing(4)};
`
export const CircularProgressBar = styled(CircularProgress)`
  height: 2em;
  width: 2em;
  box-sizing: border-box;
  z-index: ${ZIndex.CIRCULAR_SPINNER};
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  svg {
    color: ${(props) => props.theme.gray};
  }
`
