import React from 'react'
import { Box, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import { useHistory } from 'react-router-dom'

interface IGoBack {
  text?: string
}
const GoBack: React.FC<IGoBack> = ({ text }) => {
  const history = useHistory()
  return (
    <Box display='flex' alignItems='center' mb={1}>
      <ArrowBack
        fontSize='small'
        fontVariant='subtitle2'
        onClick={() => history.goBack()}
        className='cursor-pointer'
      />
      <Typography variant='subtitle2' onClick={() => history.goBack()} className='cursor-pointer'>
        {text || 'VOLTAR'}
      </Typography>
    </Box>
  )
}

export default GoBack
