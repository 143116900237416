import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={361.43} height={253.32} viewBox='0 0 361.43 253.32' {...props}>
      <g data-name='Grupo 1020'>
        <path
          data-name='Caminho 956'
          d='M.191 162.252c6.77-75.865 15.278-98.687 51.046-107.02S173.968 7.367 242.209 44.919s141.876 71.231 112.636 131.192-34 79.977-110.24 76.909C168.396 249.974-6.579 238.117.191 162.252z'
          fill='#f0f9fc'
        />
        <g data-name='Grupo 1018'>
          <g data-name='Grupo 1005'>
            <g data-name='Grupo 1004'>
              <g data-name='Grupo 1002'>
                <g data-name='Grupo 995'>
                  <path
                    data-name='Caminho 895'
                    d='M291.749 142.622s-22.761-7.371-20.981-22.047c.752-6.2 5.262-8.2 3.757-11.868s-9.271-3.642-9.4-11.106 8.03-13.888 10.145-4.828c1.129 4.828.311 7.886 3.163 9.137s9.376-4.361 12.621 1.217.695 39.495.695 39.495z'
                    fill='#3c8a4d'
                  />
                  <g data-name='Grupo 994'>
                    <g data-name='Grupo 991'>
                      <path
                        data-name='Caminho 896'
                        d='M288.862 136.102a.063.063 0 01-.062-.058c-.618-9.661-5.569-19.692-9.611-26.406a103.434 103.434 0 00-8.717-12.379.064.064 0 11.1-.081 103.409 103.409 0 018.729 12.4c4.052 6.726 9.012 16.776 9.63 26.461a.064.064 0 01-.058.067z'
                        fill='#0d2b13'
                      />
                    </g>
                    <g data-name='Grupo 992'>
                      <path
                        data-name='Caminho 897'
                        d='M288.618 133.655a.087.087 0 01-.024 0c-.1-.041-9.886-4.177-13.363-8.149a.066.066 0 010-.089.064.064 0 01.089 0c3.458 3.951 13.22 8.075 13.318 8.118a.062.062 0 01-.024.12z'
                        fill='#0d2b13'
                      />
                    </g>
                    <g data-name='Grupo 993'>
                      <path
                        data-name='Caminho 898'
                        d='M280.305 111.486a.052.052 0 01-.019 0 .061.061 0 01-.041-.079l2.142-7.006a.063.063 0 01.12.038l-2.142 7.006a.064.064 0 01-.06.041z'
                        fill='#0d2b13'
                      />
                    </g>
                  </g>
                </g>
                <g data-name='Grupo 1001'>
                  <path
                    data-name='Caminho 899'
                    d='M289.681 143.349s-17.722-14.928-8.736-29.413c3.072-4.953 12.7-5.957 13.915-10.809s-11.387-7.275-5.555-16.249c2.442-3.757 7.016.252 9.4-2.379s-1.838-6.64 2.339-7.517 12.988 5.226 1.512 19.292c-3.1 3.8 11.806 10.9 11.432 20.169s-20.442 17.037-19.678 25.68-4.629 1.226-4.629 1.226z'
                    fill='#40b258'
                  />
                  <g data-name='Grupo 1000'>
                    <g data-name='Grupo 996'>
                      <path
                        data-name='Caminho 900'
                        d='M292.312 142.685a.067.067 0 01-.058-.036c-.017-.036-1.634-3.6-1.5-10.174.053-2.631 2.454-4.984 5-7.474 3.915-3.836 8.355-8.185 5.42-14.715-2.519-5.6-1.776-12.7-.707-17.665a56.12 56.12 0 012.948-9.525.064.064 0 01.081-.034.065.065 0 01.034.081 56.354 56.354 0 00-2.94 9.5c-1.064 4.946-1.8 12.012.7 17.586 2.971 6.611-1.5 10.991-5.447 14.856-2.523 2.47-4.9 4.807-4.958 7.387-.134 6.549 1.471 10.083 1.488 10.119a.064.064 0 01-.031.084.083.083 0 01-.03.01z'
                        fill='#020e04'
                      />
                    </g>
                    <g data-name='Grupo 997'>
                      <path
                        data-name='Caminho 901'
                        d='M299.587 99.371a.062.062 0 01-.05-.026c-.142-.194-3.467-4.749-3.685-6.848a.065.065 0 01.055-.069.064.064 0 01.069.055c.213 2.063 3.628 6.738 3.661 6.786a.062.062 0 01-.014.089.049.049 0 01-.036.013z'
                        fill='#020e04'
                      />
                    </g>
                    <g data-name='Grupo 998'>
                      <path
                        data-name='Caminho 902'
                        d='M290.82 132.537a.06.06 0 01-.053-.029c-.043-.067-4.265-6.781-2.648-12.913a.063.063 0 11.122.031c-1.6 6.079 2.59 12.748 2.633 12.815a.062.062 0 01-.019.086.067.067 0 01-.035.01z'
                        fill='#020e04'
                      />
                    </g>
                    <g data-name='Grupo 999'>
                      <path
                        data-name='Caminho 903'
                        d='M299.624 121.007a.064.064 0 01-.06-.046.062.062 0 01.043-.077c.062-.017 6.264-1.737 8.188-4.335a.064.064 0 01.089-.012.062.062 0 01.012.089c-1.95 2.633-8.192 4.363-8.255 4.38z'
                        fill='#020e04'
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g data-name='Grupo 1003'>
                <path
                  data-name='Caminho 904'
                  d='M300.455 147.506h-17.413L281 141.717a.989.989 0 01.932-1.318h19.627a.989.989 0 01.932 1.318z'
                  fill='#ffcc46'
                />
                <path
                  data-name='Caminho 905'
                  d='M301.562 140.401h-1.488a.3.3 0 01-.014.048l-2.042 5.789h-15.424l.448 1.27h17.413l2.042-5.789a.99.99 0 00-.935-1.318z'
                  fill='#ffa42f'
                />
              </g>
            </g>
          </g>
          <g data-name='Grupo 1007'>
            <path
              data-name='Caminho 906'
              d='M119.362 114.352c-3.022-.623-6.079-1.169-9.113-1.831l-8.276.208a3.066 3.066 0 00-2.705 4.486c6.47 12.077 16.251 32.578 23.073 47.116a68.658 68.658 0 005.48-21.015c1.056-10.569-3.173-20.295-8.459-28.964z'
              fill='#e5649a'
            />
            <path
              data-name='Caminho 907'
              d='M161.266 181.193l-25.222-69.326-25.795.652c3.034.661 6.091 1.208 9.113 1.831 5.286 8.669 9.515 18.4 8.459 28.967a68.662 68.662 0 01-5.48 21.015 2416.496 2416.496 0 017.838 16.859h31.088z'
              fill='#db4a87'
            />
            <path
              data-name='Ret\xE2ngulo 1072'
              fill='#db4a87'
              d='M154.561 231.985h-3.479V203.94h3.479z'
            />
            <path
              data-name='Caminho 908'
              d='M147.519 206.37h10.608a1.8 1.8 0 001.8-1.8v-23.377h-14.2v23.38a1.8 1.8 0 001.792 1.797z'
              fill='#e5649a'
            />
            <g data-name='Grupo 1006'>
              <path
                data-name='Caminho 909'
                d='M129.174 182.693h48.1a1.122 1.122 0 100-2.243h-48.1a1.122 1.122 0 100 2.243z'
                fill='#db4a87'
              />
            </g>
            <path
              data-name='Caminho 910'
              d='M152.822 230.104c10.28 0 18.616 3.307 18.616 7.385h-37.232c0-4.081 8.334-7.385 18.616-7.385z'
              fill='#db4a87'
            />
          </g>
          <g data-name='Grupo 1011' transform='translate(65.498 34.014)'>
            <path
              data-name='Caminho 911'
              d='M101.856 126.26s30.523-4.543 39.242-1.454l21.983 75.4s-29.25-22.891-40.7-60.681z'
              fill='#7860dc'
            />
            <path
              data-name='Caminho 912'
              d='M77.027 57.387a36.472 36.472 0 01-.424-5.705c-2.389-1.634-5.542 4.723-5.542 4.723l.12 1.138a12.013 12.013 0 005.846-.156z'
              fill='#ed7e94'
            />
            <path
              data-name='Caminho 913'
              d='M71.88 49.41a2.887 2.887 0 002 4.361 10.556 10.556 0 004.018.074c2.921-1.342 5.53-3.014 4.883-4.524-.894-2.085-3.467-4.325-5.133-5.621-1.941 1.838-4.153 3.357-5.768 5.71z'
              fill='#ff96a9'
            />
            <path
              data-name='Caminho 914'
              d='M77.897 53.844a10.559 10.559 0 01-4.018-.074 2.887 2.887 0 01-2-4.361c1.617-2.351 3.829-3.872 5.768-5.713-.875-.681-1.5-1.1-1.5-1.1-6.268-.273-11.082 5.451-10.809 8.358s5.722 5.451 5.722 5.451a60.392 60.392 0 006.837-2.561z'
              fill='#ed7e94'
            />
            <path
              data-name='Caminho 915'
              d='M69.699 50.5s-2.454 0-1.907-2.09 3.089-.182 3.089-.182 10.718-7.268 14.262-16.352c0 0-17.442 7.541-22.073 18.441a8.844 8.844 0 007.994 6.086z'
              fill='#322666'
            />
            <path
              data-name='Caminho 916'
              d='M70.545 63.684l12.333-.829s18.915 21.439 12.5 61.891c0 0-12.475 8.72-18.29 5.935s-6.543-66.997-6.543-66.997z'
              fill='#f2f0ff'
            />
            <path
              data-name='Caminho 917'
              d='M71.183 57.543l.515 6.065 7.148 4.879.98-5.427c-1.431-1.881-2.406-3.161-2.8-5.674a11.984 11.984 0 01-5.843.157z'
              fill='#ff96a9'
            />
            <path
              data-name='Caminho 918'
              d='M78.994 68.759l-1.3 7.222-7.857-7.5 6.949 4.586z'
              fill='#e0ddf5'
            />
            <path
              data-name='Caminho 919'
              d='M78.994 68.763l7.327 5-.407-9-.774 6.276z'
              fill='#e0ddf5'
            />
            <path
              data-name='Caminho 920'
              d='M80.158 71.038l-.014-.024c1.79 19.3 3.069 33.343-2.25 44.073l9.767 6.915c.616-17.199-2.993-34.363-7.503-50.964z'
              fill='#db4a87'
            />
            <path
              data-name='Caminho 921'
              d='M97.133 112.056s-9.98-29.025-16.972-41.015c4.51 16.6 8.118 33.765 7.5 50.962l.113.079z'
              fill='#e5649a'
            />
            <path
              data-name='Caminho 922'
              d='M116.754 10.894a4.656 4.656 0 01-6.177-1.816 10.945 10.945 0 01-.687-2.724c-.628 3.244.177 6.9 2.93 8.78 6.9 4.723 6.055-2.907 6.055-2.907a13.2 13.2 0 011.689 4.5 16.18 16.18 0 001.047-1.189 39.625 39.625 0 00-2.676-7.006c-.727.725-1.273 1.815-2.181 2.362z'
              fill='#ed7e94'
            />
            <path
              data-name='Caminho 923'
              d='M120.81 1.93c-5.756-4.443-9.975-.474-10.924 4.423a11.09 11.09 0 00.688 2.724 4.657 4.657 0 006.177 1.816c.908-.544 1.454-1.634 2.18-2.363a39.452 39.452 0 012.676 7.006 20.706 20.706 0 003.805-7.967 19.7 19.7 0 00-4.602-5.639z'
              fill='#ff96a9'
            />
            <path
              data-name='Caminho 924'
              d='M120.563 16.727c.2.882.386 1.843.551 2.863a14.985 14.985 0 001.232-1.076q-.32-1.5-.733-2.978a15.59 15.59 0 01-1.05 1.191z'
              fill='#e0ddf5'
            />
            <path
              data-name='Caminho 925'
              d='M127.189 10.951a42.107 42.107 0 00-1.771-3.383 20.708 20.708 0 01-3.805 7.967q.413 1.477.733 2.978a21.883 21.883 0 004.843-7.562z'
              fill='#f2f0ff'
            />
            <path
              data-name='Caminho 926'
              d='M122.344 18.516a15.456 15.456 0 01-1.232 1.076c1.725 10.651 1.514 27.76 1.514 27.76-4.765 4.418-14.113 8.164-22.545 10.864 8.569-1.967 17.075-4.553 24.484-9.537-.808-9.822-.12-20.346-2.221-30.163z'
              fill='#7860dc'
            />
            <path
              data-name='Caminho 927'
              d='M127.189 10.952a21.909 21.909 0 01-4.843 7.562c2.1 9.817 1.414 20.341 2.221 30.168-7.409 4.984-15.915 7.57-24.484 9.537-9.139 2.931-17.2 4.634-17.2 4.634 14.149 28.948 8.137 79.333 8.137 79.333s2.3 6.055 8.842-16.229-2.665-51.719-2.665-51.719c17.2-3.391 36.82-20.955 36.82-20.955 2.287-9.707-.967-29.88-6.828-42.331z'
              fill='#8b77e8'
            />
            <path
              data-name='Caminho 928'
              d='M81.508 142.066c4.414-5.231 5.3-12.228 5.164-18.872a152.272 152.272 0 00-14.794 4.157s-15.443 14.9.908 19.831 34.7-8.931 34.7-8.931c-13.989 17.8-12.355 61.953-12.355 61.953.208-.24.419-.489.628-.743 3.2-23.924 5.586-49.093 18.266-70.112-9.806 6.54-20.709 11.627-32.517 12.717z'
              fill='#7860dc'
            />
            <path
              data-name='Caminho 929'
              d='M86.672 123.193c.132 6.645-.75 13.641-5.164 18.872 11.808-1.09 22.711-6.177 32.521-12.716-12.681 21.019-15.065 46.189-18.266 70.112 16.483-19.852 38.988-78.832 38.988-78.832-16.469-3.318-35.341-.398-48.079 2.564z'
              fill='#8b77e8'
            />
            <path
              data-name='Caminho 930'
              d='M8.757 24.886a22.639 22.639 0 01-.465-5.272s1.816 3.271 6.542.908c4.268-2.133 1.565-17.756-7.567-15a6.92 6.92 0 00-2.425 2.825 30.728 30.728 0 00-2.121 15.575 14.971 14.971 0 006.036.964z'
              fill='#ff96a9'
            />
            <path
              data-name='Caminho 931'
              d='M4.84 8.349a6.9 6.9 0 012.425-2.825 11.822 11.822 0 00-3.151 1.553c-3.5 2.351-4.431 8.2-4.026 15.24a9.192 9.192 0 002.631 1.608A30.732 30.732 0 014.84 8.349z'
              fill='#ed7e94'
            />
            <path
              data-name='Caminho 932'
              d='M9.668 28.612a42.584 42.584 0 01-.913-3.724 15.013 15.013 0 01-6.036-.961c.115 1.248.28 2.5.484 3.752a15.858 15.858 0 006.465.933z'
              fill='#f2f0ff'
            />
            <path
              data-name='Caminho 933'
              d='M.088 22.319c.074 1.28.192 2.6.347 3.939a12.948 12.948 0 002.768 1.419c-.2-1.251-.367-2.5-.484-3.752a9.13 9.13 0 01-2.631-1.606z'
              fill='#e0ddf5'
            />
            <path
              data-name='Caminho 934'
              d='M10.47 59.402c15.079 7.994 32.157 11.991 49.055 13.627 2 5.451 5.451 10.356 7.085 15.987 2.724 9.266 4.723 18.532 3.633 27.978a65 65 0 01-10.538 27.434 49.629 49.629 0 0014.068-1.258 22.577 22.577 0 004.826-3.647c8.54-8.54 4.855-49.321-8.056-75.839 0 0-25.919.994-50.447-12.273 0 0-7.306-12.036-10.433-22.8a15.862 15.862 0 01-6.465-.932c.846 5.238 2.348 10.486 3.635 15.556 1.457 5.449 2.185 10.898 3.637 16.167z'
              fill='#8b77e8'
            />
            <path
              data-name='Caminho 935'
              d='M59.707 144.428a65 65 0 0010.538-27.434c1.09-9.448-.908-18.714-3.633-27.978-1.634-5.631-5.087-10.538-7.085-15.987-16.9-1.634-33.973-5.631-49.055-13.627-1.454-5.269-2.181-10.718-3.633-16.169-1.287-5.07-2.789-10.318-3.635-15.556a12.948 12.948 0 01-2.768-1.419C2.259 42.085 9.201 61.4 9.201 61.4s32.7 16.713 47.782 15.805c0 0 14.171 25.253 11.626 40.15s-12.355 29.8-12.355 29.8 9.427.621 17.521-3.982a49.651 49.651 0 01-14.068 1.255z'
              fill='#7860dc'
            />
            <path data-name='Caminho 936' d='M95.076 195.843l-6.3 7.632h32.157z' fill='#322666' />
            <path data-name='Caminho 937' d='M158.89 196.555l-5.073 6.92h33.609z' fill='#322666' />
            <path
              data-name='Caminho 938'
              d='M76.421 49.497s3.148-.268 3.877-2.569c0 0 1.332 2.6 0 3.391s-3.877-.822-3.877-.822z'
              fill='#f2f0ff'
            />
            <path
              data-name='Caminho 939'
              d='M82.804 110.354c-1.275-15.022-5.5-32.8-12.256-46.67 0 0-.985.038-2.748 0l-.642 11.161 4.026.364-5.854 5.542a74.048 74.048 0 0117.475 29.6z'
              fill='#7860dc'
            />
            <path
              data-name='Caminho 940'
              d='M96.044 73.573l-6.724-1.454 2.454-2.272-5.954-7.665c-1.843.439-2.94.671-2.94.671 6.563 13.428 8.789 31.471 9.288 46.958 4.209-10.543 3.876-36.238 3.876-36.238z'
              fill='#7860dc'
            />
            <g data-name='Grupo 1008'>
              <path
                data-name='Caminho 941'
                d='M67.337 127.26a.728.728 0 01-.268-1.4l10.56-4.179a.727.727 0 01.537 1.351l-10.56 4.179a.713.713 0 01-.269.049z'
                fill='#7860dc'
              />
            </g>
            <circle
              data-name='Elipse 62'
              cx={1.203}
              cy={1.203}
              r={1.203}
              transform='translate(79.239 111.181)'
              fill='#7860dc'
            />
            <g data-name='Grupo 1009'>
              <path
                data-name='Caminho 942'
                d='M93.59 74.846a.165.165 0 01-.048-.007l-2.633-.726a.182.182 0 11.1-.35l2.633.726a.182.182 0 01-.048.357z'
                fill='#8b77e8'
              />
            </g>
            <g data-name='Grupo 1010'>
              <path
                data-name='Caminho 943'
                d='M95.044 112.566a.2.2 0 01-.053-.007l-2.18-.659a.182.182 0 11.105-.347l2.18.659a.181.181 0 01.122.225.186.186 0 01-.174.129z'
                fill='#7860dc'
              />
            </g>
            <path
              data-name='Caminho 944'
              d='M78.994 68.759l-.484 2.756 2.6 1.756.666-2.847z'
              fill='#db4a87'
            />
          </g>
          <g data-name='Grupo 1012' transform='translate(169.679 109.996)'>
            <path
              data-name='Caminho 945'
              d='M0 38.317h70.654v-3.088a.7.7 0 00-.7-.7H.7a.7.7 0 00-.7.7z'
              fill='#bcbcbc'
            />
            <path data-name='Ret\xE2ngulo 1073' fill='#747474' d='M0 36.82h70.654v1.495H0z' />
            <path
              data-name='Caminho 946'
              d='M68.816 34.529L86.416 0h-49.23l-17 34.529z'
              fill='#bcbcbc'
            />
            <path
              data-name='Caminho 947'
              d='M37.186 0h.812L21.515 33.48H69.35l-.534 1.047H20.185z'
              fill='#747474'
            />
            <ellipse
              data-name='Elipse 63'
              cx={5.401}
              cy={4.344}
              rx={5.401}
              ry={4.344}
              transform='rotate(-62.61 41.04 -27.126)'
              fill='#747474'
            />
          </g>
          <g data-name='Grupo 1014'>
            <g data-name='Grupo 1013'>
              <path
                data-name='Caminho 948'
                d='M302.034 150.597H56.981c-1.05 0-1.9-.58-1.9-1.3s.851-1.3 1.9-1.3h245.053c1.05 0 1.9.58 1.9 1.3s-.851 1.3-1.9 1.3z'
                fill='#ffd4cd'
              />
            </g>
            <path
              data-name='Caminho 949'
              d='M102.374 150.597l-32.993 86.892h7.258l37.172-86.892z'
              fill='#ffd4cd'
            />
            <path
              data-name='Caminho 950'
              d='M111.173 150.597l-37.172 86.892h2.638l37.172-86.892z'
              fill='#edc0b9'
            />
            <path
              data-name='Caminho 951'
              d='M260.651 150.597l32.993 86.893h-7.258l-37.174-86.893z'
              fill='#ffd4cd'
            />
            <path
              data-name='Caminho 952'
              d='M249.212 150.597l37.174 86.893h2.473l-37.172-86.893z'
              fill='#edc0b9'
            />
            <path
              data-name='Caminho 953'
              d='M302.034 149.303H56.981a2.159 2.159 0 01-1.637-.647 1 1 0 00-.264.647c0 .716.851 1.3 1.9 1.3h245.054c1.05 0 1.9-.58 1.9-1.3a.994.994 0 00-.264-.647 2.168 2.168 0 01-1.636.647z'
              fill='#edc0b9'
            />
          </g>
          <g data-name='Grupo 1015'>
            <path
              data-name='Caminho 954'
              d='M324.635 238.16H39.552c-.149 0-.271-.16-.271-.359a.325.325 0 01.271-.359h285.083a.325.325 0 01.271.359c0 .198-.125.359-.271.359z'
              fill='#717171'
            />
          </g>
          <g data-name='Grupo 1017' transform='translate(110.277)'>
            <circle
              data-name='Elipse 64'
              cx={20.538}
              cy={20.538}
              r={20.538}
              transform='rotate(-26.1 38.98 9.036)'
              fill='#fe9736'
            />
            <circle
              data-name='Elipse 65'
              cx={18.582}
              cy={18.582}
              r={18.582}
              transform='matrix(1 -.027 .027 1 8.396 9.407)'
              fill='#ffcc46'
            />
            <g data-name='Grupo 1016'>
              <path
                data-name='Caminho 955'
                d='M36.061 24.294l-7.56.012v-.611a2.1 2.1 0 00-.264-1.277 1.506 1.506 0 00-1.124-.3 1.718 1.718 0 00-1.038.261.918.918 0 00-.345.781 1.233 1.233 0 00.714 1.212 37.407 37.407 0 004.083 1.344 41.23 41.23 0 013.932 1.294 4.848 4.848 0 011.771 1.275 3.017 3.017 0 01.724 2.056 3.434 3.434 0 01-1.941 3.093 13.9 13.9 0 01-5.837 1.395v1.385h-3.482v-1.433a14.957 14.957 0 01-5.3-1.126 3.492 3.492 0 01-2.269-3.448v-.721l7.566-.011v.9a4.289 4.289 0 00.237 1.847q.234.359 1.133.359a2.063 2.063 0 001.141-.252.837.837 0 00.374-.743 3.289 3.289 0 00-.359-1.773 5.156 5.156 0 00-2.435-1.16q-3.465-1.053-4.708-1.548a6.347 6.347 0 01-2.154-1.4 2.808 2.808 0 01-.911-2.042 2.868 2.868 0 011.915-2.593 15.71 15.71 0 015.753-1.167v-1.177h3.482v1.177a13.372 13.372 0 015.243 1.136 2.826 2.826 0 011.744 2.554c-.008.16-.034.392-.085.701z'
                fill='#fe9736'
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
