import { ICustomTheme } from 'interfaces'

export const RbPallete: ICustomTheme = {
  PRIMARY: '#FE511C',
  SECONDARY: '#455a64',
  PRIMARY_COMPANY: '#FE511C',
  SECONDARY_COMPANY: '#455a64',
}

export default RbPallete
