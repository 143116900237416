import React from 'react'
import { Grid } from '@material-ui/core'

interface IFormField {
  xl?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  xs: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  md?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  lg?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  sm?: boolean | 'auto' | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
}
const FormField: React.FC<IFormField> = ({ children, xl, xs, md, lg, sm }) => {
  return (
    <Grid item xs={xs} sm={sm} lg={lg} xl={xl} md={md}>
      {children}
    </Grid>
  )
}

export default FormField
