import React, { SyntheticEvent } from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useErrors } from 'hooks'
import Radio from '../uncontrolled/Radio'

interface IRadio extends FieldRenderProps<any, HTMLElement> {
  label?: string
  size?: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'default'
}
const ControlledRadio = ({
  id,
  input,
  meta,
  disabled,
  inputRef,
  onChange: onChangeInput,
  label,
  size,
  error,
  color,
  onCheck,
}: IRadio) => {
  const { name, checked, onClick, onChange, value } = input
  const { error: metaError, submitFailed, submitSucceeded } = meta
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || fieldError || ''
  return (
    <Radio
      error={Boolean((submitSucceeded || submitFailed) && (error || metaError || formError))}
      id={id}
      name={name}
      checked={onCheck ? onCheck : checked}
      value={value}
      onChange={(e: SyntheticEvent) => {
        onChange(e)
        onChangeInput && onChangeInput(e)
      }}
      onClick={onClick}
      disabled={disabled}
      size={size || 'small'}
      color={color || 'primary'}
      label={label}
      ref={inputRef}
    />
  )
}

export default ControlledRadio
