import React, { createContext, useState, Dispatch, SetStateAction } from 'react'
import { match } from 'react-router-dom'

interface IRouteContext {
  match: match
  setMatch: Dispatch<SetStateAction<match>>
}

export const RouteContext = createContext<IRouteContext>({} as IRouteContext)

const RouteProvider: React.FC = ({ children }) => {
  const [match, setMatch] = useState<match>({} as match)
  const values: IRouteContext = {
    match,
    setMatch
  }
  return <RouteContext.Provider value={values}>{children}</RouteContext.Provider>
}

export default RouteProvider
