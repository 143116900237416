import { IThemePallete } from 'interfaces'

export const OnidataPallete: IThemePallete = {
  PRIMARY: '#40b258',
  SECONDARY: '#111224',
  SUCCESS: '#019310',
  INFO: '#0747a6',
  WARNING: '#ff9800',
  DANGER: '#f44336',
  LIGHT: '#f0f1f4',
  DARK: '#455a64',
  PRIMARY_COMPANY: '#107fe8',
  SECONDARY_COMPANY: '#455a64',
  SIDEBAR_LOGO_COMPANY: '#FFF',
  SIDEBAR_LOGO_EMPLOYEE: '#FFF',
  LOW_DARK: '#78909c',
  CONTAINER: '#e8e8e8',
  GRAY: '#cbd1d4',
  GRAY_100: '#dddddd',
  GRAY_200: '#0000008a',
  GRAY_200_LIGHT: '#D2D4DA',
  BORDER: 'rgba(0, 0, 0, 0.125)',
  BUTTON_TEXT_COLOR: '#FFF',
}

export default OnidataPallete
