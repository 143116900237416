import axios, { AxiosRequestConfig } from 'axios'
import { CONTENT_TYPES, RESPONSE_TYPES } from 'constants/services'
import { IHeader, IError } from 'interfaces'
import { TRequest } from 'types'
import { getToken, getUserFunction } from 'components/Provider/CoreProvider'
import { getError } from 'components/Provider/ErrorProvider'

let cancelToken = axios.CancelToken.source()

export const cancelRequests = () => {
  const previousToken = cancelToken
  cancelToken = axios.CancelToken.source()
  previousToken.cancel('Requisição foi cancelada.')
}

async function apiRequest({
  path,
  method,
  external_url,
  body: data,
  cType,
  rType,
  filters,
  queryParams,
  isV2,
  setError,
  pathParams,
  disableErrors,
  disableStatusCode,
  clearErrorsOnSuccess = true,
  externalPath,
  returnError,
  headers: propHeaders,
}: TRequest) {
  const contentType = cType || CONTENT_TYPES.JSON
  const responseType = rType || RESPONSE_TYPES.JSON
  const { token } = getToken
  const { userFunction } = getUserFunction
  const headers: IHeader = (propHeaders || {}) as IHeader
  const error: IError = getError()
  const bindPathParams = () => {
    if (!pathParams) {
      return path
    }

    return Object.keys(pathParams).reduce((result, key) => {
      return result.replace(`:${key}`, pathParams[key])
    }, path)
  }
  const bindedPath = bindPathParams()
  let url
  if (isV2 && !external_url) {
    url = `${process.env.REACT_APP_CREDITOR_BASE_URL}/v2${bindedPath}`
  }
  if (!isV2 && !external_url) {
    url = `${process.env.REACT_APP_CREDITOR_BASE_URL}/v3${bindedPath}`
  }
  if (external_url) {
    url = `${process.env.REACT_APP_EXT_BASE_URL}/v3${bindedPath}`
  }
  let params = {}
  let query = ''
  headers['Content-Type'] = contentType

  if (!externalPath) {
    if (token) {
      headers['Authorization'] = `Bearer ${token}`
    }
    if (userFunction) {
      headers['User-Funcao'] = userFunction
    }
  }

  if (filters && Object.keys(filters).length < 1) {
    return null
  }

  if (queryParams && Object.keys(queryParams).length > 0) {
    query = Object.keys(queryParams).reduce((result, key) => {
      if (queryParams[key] === undefined || queryParams[key] === null) {
        return result
      }
      if (!result) {
        return `?${key}=${queryParams[key]}`
      }
      return `${result}&${key}=${queryParams[key]}`
    }, '')
  }

  if (method.toUpperCase() === 'GET' && filters) {
    params = {
      ...filters,
    }
  }

  if (query && url?.endsWith('/')) {
    url = `${url}${query}`
  } else if (query) {
    url = `${url}/${query}`
  } else if (!url?.endsWith('/')) {
    url = `${url}/`
  }
  try {
    const response = await axios({
      method,
      headers,
      url: externalPath ? path : url,
      data,
      contentType:
        cType === CONTENT_TYPES.MULTIPART ? `${cType} boundary=${data?._boundary}` : cType,
      responseType,
      params,
      cancelToken: cancelToken.token,
    } as AxiosRequestConfig)
    const { data: responseData } = response

    if (clearErrorsOnSuccess && error.code && error.code !== 500) {
      setError({} as IError)
    }

    return responseData || {}
  } catch (error) {
    if (/Network Error/i.test(error.message)) {
      if (!returnError) {
        setError({
          code: 503,
          critical: true,
        } as IError)
      }

      if (returnError) {
        return { code: 504, critical: true }
      }

      return null
    }

    if (!error.response) {
      return null
    }

    if (disableErrors) {
      return error.response
    }

    const status = error.response.status
    const rData = error.response.data
    const rHeaders = error.response.headers

    if (status === 500) {
      cancelToken.cancel('Requisição foi cancelada.')
      cancelToken = axios.CancelToken.source()
    }

    if (disableStatusCode && disableStatusCode.includes(status)) {
      return null
    }

    setError({
      code: status,
      data: rData,
      rHeaders: rHeaders,
      critical: false,
      body: data,
    })

    if (returnError) {
      return {
        code: status,
        data: rData,
        isError: true,
      }
    }

    return null
  }
}

export default apiRequest
