import { useCallback, useContext } from 'react'
import { match } from 'react-router-dom'
import { IRouteCodes, IRoute } from 'interfaces'
import useCore from './useCore'
import { RouteContext } from 'components/Provider/RouteProvider'

interface IRoutesHook {
  mainRoute: IRoute
  route: IRoute
  subRoutes: IRouteCodes
  defaultRoutePath: string
  mainRoutePath: string
  match: match
}
export function useRoutes() {
  const { entity } = useCore()
  const { match } = useContext(RouteContext)
  const currentPath = match.path || ''

  const getMainRoute = useCallback(
    (routes: IRouteCodes): IRoute => {
      const paths = (currentPath.split('/') || []).filter(
        obj => !!obj && !entity.path.includes(obj)
      )
      const routeKey = Object.keys(routes).find(key => routes[key].path.endsWith(paths[0])) || ''
      const children = routes[routeKey]

      if (!children || !children.routes) {
        return {} as IRoute
      }

      return children
    },
    [entity.path, currentPath]
  )

  const getRoute = useCallback(
    (routes: IRouteCodes, currentPath: string): IRoute => {
      const paths = (currentPath.split('/') || []).filter(
        obj => !!obj && !entity.path.includes(obj)
      )
      const routeKey = Object.keys(routes).find(
        key => routes[key].path.endsWith(paths[0]) || routes[key].path.endsWith(paths.join('/'))
      )
      const children = routes[routeKey || '']

      if (paths.length > 1 && children && !children.routes) {
        return children
      }

      if (paths.length > 1 && children && children.routes) {
        paths.shift()
        const subRoutes = getRoute(children.routes, paths.join('/'))
        return subRoutes
      }

      if (children && children.routes) {
        const currentRouteKey = Object.keys(children.routes).find(
          key => children.routes && children.routes[key].path === ''
        )
        if (currentRouteKey) return children.routes[currentRouteKey]
      }
      return children
    },
    [entity.path]
  )

  const getSubRoutes = useCallback(
    (routes: IRouteCodes, currentPath: string): IRouteCodes => {
      const paths = (currentPath.split('/') || []).filter(
        obj => !!obj && !entity.path.includes(obj)
      )
      const routeKey = Object.keys(routes).find(key => routes[key].path.endsWith(paths[0])) || ''
      const children = routes[routeKey]

      if (!children || !children.routes) {
        return {} as IRouteCodes
      }

      if (paths.length > 1) {
        paths.shift()
        const subRoutes = getSubRoutes(children.routes, paths.join('/'))
        return subRoutes
      }

      return children.routes
    },
    [entity.path]
  )

  const getDefaultRoute = useCallback(
    (routes: IRouteCodes) => {
      const defaultRouteKey = Object.keys(routes).find(key => routes[key].defaultRoute)
      if (!defaultRouteKey) {
        return {} as IRoute
      }
      return `${entity.path}${routes[defaultRouteKey].path}`
    },
    [entity.path]
  )

  const getMainRoutePath = useCallback(
    (routes: IRouteCodes, mainRoute: IRoute) => {
      return `${entity.path}${mainRoute.path}`
    },
    [entity.path]
  )
  const mainRoute = getMainRoute(entity.route)
  return {
    mainRoute,
    route: getRoute(entity.route, currentPath),
    subRoutes: getSubRoutes(entity.route, currentPath),
    defaultRoutePath: getDefaultRoute(entity.route),
    mainRoutePath: getMainRoutePath(entity.route, mainRoute),
    match
  } as IRoutesHook
}

export default useRoutes
