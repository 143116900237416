import React, { useEffect } from 'react'

import { Loading, CircularProgressBar, LinearProgressBar, LoadOverlay } from './styles'

interface ISpinner {
  noOverlay?: boolean
  absolute?: boolean
  noLoadOverlay?: boolean
}

const Spinner: React.FC<ISpinner> = ({ noOverlay, absolute, noLoadOverlay }) => {
  useEffect(() => {
    const body = document.querySelector('body')
    if (body) {
      body.style.overflow = 'hidden'
    }
    return () => {
      if (body) {
        body.style.overflow = 'auto'
      }
    }
  }, [])

  return (
    <Loading noOverlay={noOverlay}>
      <LoadOverlay noLoadOverlay={noLoadOverlay} />
      <LinearProgressBar absolute={absolute ? 1 : 0} />
      <CircularProgressBar color='inherit' size='50' />
    </Loading>
  )
}

export default React.memo(Spinner)
