import styled from 'styled-components'
import { Width, Height } from 'constants/css'
import { breakpoint, spacing } from 'helpers'
import { TLayout } from 'types'
import { SideBar, SidebarItem, Logo, Dehaze, SidebarOverlay } from './SideBar/styles'
import { SidePanel } from './SidePanel/styles'
import { Mobile } from './Navbar/styles'

export const Layout = styled.div<TLayout>`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.light};

  ${SideBar} {
    ${Logo} {
      ${({ isCollapsed, isSidePanelVisible }) =>
        isCollapsed &&
        isSidePanelVisible &&
        `
        padding: ${spacing(1)};
        justify-content: center;
      `}
    }
  
    ${Dehaze} {
      ${({ isCollapsed }) =>
        isCollapsed &&
        `
        position: initial;
        top: 0;
        transform: initial;
      `}
    }
  }

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    ${SidePanel} {
      box-shadow: none;
    }
  `}

  ${({ isOpened, isCollapsed, isSidePanelVisible }) =>
    isCollapsed &&
    isOpened &&
    !isSidePanelVisible &&
    `
    ${Content} {
      padding-left: ${Width.SIDEBAR_COLLAPSED} !important;
    }
  `}

  @media (min-width: ${breakpoint('md')}) {
    ${SideBar} {
      ${({ isCollapsed }) =>
        isCollapsed &&
        `
        width: ${Width.SIDEBAR_COLLAPSED} !important;
        visible: visible;
  
        ${Dehaze} {
          position: initial;
          top: 0;
          transform: initial;
          transition: initial;
        }
      `}
    }
  }


  @media (max-width: ${breakpoint('md')}) {
    padding-left: 0;

    ${({ isCollapsed, isOpened }) =>
      isCollapsed &&
      isOpened &&
      `
      ${SideBar} {
        width: ${Width.SIDEBAR_COLLAPSED} !important;
      }

      ${SidebarItem} {
        color: transparent;

        svg {
          color: white;
        }
      }
    `}
    ${({ isOpened }) =>
      isOpened &&
      `
      ${SideBar} {
        width: ${Width.SIDEBAR};
      }
      ${Content} {
        margin-left: ${Width.SIDEBAR};
      }
      ${SidebarOverlay} {
        visibility: visible;
        opacity: 0.4;
        height: 100%;
        width: 100%;
        z-index: 1;
      }

      ${Mobile} {
        ${Logo} {
          opacity: 0;
        }
      }
    `}
    ${({ isOpened }) =>
      !isOpened &&
      `
      ${SidePanel} {
        width: 0;
        padding: 0;

      }
    `}
  }
`

export const Content = styled.div`
  padding-left: ${Width.SIDEBAR};
  transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);
  overflow: hidden;

  @media (max-width: ${breakpoint('md')}) {
    padding-left: 0;
  }
`
export const Main = styled.main`
  position: relative;
  height: 100%;
  min-height: calc(100vh - ${Height.NAVBAR});

  @media (max-width: ${breakpoint('md')}) {
    padding-top: unset;
    min-width: 90vw;
    width: 100%;
  }
`
