import React from 'react'
import { Container } from './styles'

import Header from './Header'
import Content from './Content'
import ButtonWrapper from './ButtonWrapper'

export { Header, Content, ButtonWrapper }

const FeedbackLayout: React.FC = ({ children }) => {
  return <Container>{children}</Container>
}

export default FeedbackLayout
