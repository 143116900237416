import React from 'react'
import Loadable from '@loadable/component'
import Spinner from 'components/Spinner/LazyLoadingSpinner'

const LazyLoading = (loader: () => any) => {
  const loadFn = (attemptsLeft: number) => async (): Promise<any> => {
    return await new Promise(async (resolve, reject) => {
      try {
        const loaded = await loader()
        resolve(loaded)
      } catch (e) {
        if (attemptsLeft > 0) {
          return setTimeout(async () => {
            const response = await loadFn(attemptsLeft - 1)()
            if (response) {
              resolve(response)
            }
          }, 1500)
        }
        reject(e)
      }
    })
  }

  return Loadable(loadFn(5), {
    fallback: <Spinner />,
  })
}

export default LazyLoading
