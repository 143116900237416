import { IRouteCodes } from 'interfaces'
import LazyLoading from 'components/LazyLoading'
import { routeCodes } from 'constants/routes'

const WithoutPermission = LazyLoading(() => import('./pages/WithoutPermission'))
const NotFoundPage = LazyLoading(() => import('./pages/NotFoundPage'))
const ErrorComponent = LazyLoading(() => import('./pages/Error'))
const Demission = LazyLoading(() => import('./pages/Demission'))
const ApiNotFound = LazyLoading(() => import('./pages/ApiNotFound'))
const ProposalSent = LazyLoading(() => import('./pages/ProposalSent'))
const ApprovedProposal = LazyLoading(() => import('./pages/ApprovedProposal'))
const DeniedProposal = LazyLoading(() => import('./pages/DeniedProposal'))
const PortabilitySent = LazyLoading(() => import('./pages/PortabilitySent'))
const PortabilityDeny = LazyLoading(() => import('./pages/PortabilityDeny'))
const Unavailable = LazyLoading(() => import('./pages/Unavailable'))
const SCProposalSent = LazyLoading(() => import('./pages/SalesChannel/ProposalSent'))
const PortabilityRequest = LazyLoading(() => import('./pages/Corban/PortabilityRequest'))

export const DefaultRoutes: IRouteCodes = {
  DEFAULT: {
    path: '',
    name: 'Página não encontrada',
    component: NotFoundPage,
  },
  API_NOT_FOUND: {
    path: '/404',
    name: 'Não encontrado',
    component: ApiNotFound,
  },
  NOT_FOUND: {
    path: '/403',
    name: 'Sem permissão',
    component: WithoutPermission,
  },
  ERROR: {
    path: '/500',
    name: 'Ocorreu um problema',
    component: ErrorComponent,
  },
  DEMISSION: {
    path: '/demission',
    name: 'Demissão concluida',
    component: Demission,
  },
  PROPOSAL_SENT: {
    path: '/proposal-sent',
    name: 'Proposta enviada',
    component: ProposalSent,
  },
  APPROVED_PROPOSAL: {
    path: '/approved-proposal',
    name: 'Proposta aceita',
    component: ApprovedProposal,
  },
  DENIED_PROPOSAL: {
    path: '/denied-proposal',
    name: 'Proposta negada',
    component: DeniedProposal,
  },
  UNAVAILABLE: {
    path: '/service-unavailable',
    name: 'Serviço Indisponível',
    component: Unavailable,
  },
  PORTABILITY_SENT: {
    path: '/portability-sent',
    name: 'Portabilidade Enviada',
    component: PortabilitySent,
  },
  PORTABILITY_DENY: {
    path: '/portability-deny',
    name: 'Portabilidade Negada',
    component: PortabilityDeny,
  },
  SC_PROPOSAL_SENT: {
    path: '/sc-proposal-sent',
    name: 'Proposta enviada',
    component: SCProposalSent,
  },
  PORTABILITY_REQUEST: {
    path: routeCodes.FEEDBACK.PORTABILITY_REQUEST,
    name: 'Portabilidade solicitada',
    component: PortabilityRequest,
  },
}

export default DefaultRoutes
