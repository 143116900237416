import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={145.452}
      height={218.185}
      viewBox='0 0 145.452 218.185'
      {...props}
    >
      <g data-name='Grupo 1'>
        <path
          data-name='Caminho 2'
          d='M52.363 33.555a74.181 74.181 0 00-16.785 1.935v65.934h15.73c21.018 0 38.348-12.269 38.348-36.727S72.893 33.643 52.072 33.643zm51.192 184.546l-39.41-72.21a100.213 100.213 0 00-5.593-9.236c-4.436-6.9-17.985-5.513-22.872-5.513v86.646H0V11.643A110.747 110.747 0 0150.654.007c38.152 0 76.5 13.491 76.5 65.621 0 28.269-18.749 53.381-41.818 57.788v.655c6.225 0 13.091 11.927 15.738 16.931l44.378 77.184z'
          fill='#008a98'
        />
      </g>
    </svg>
  )
}

export default SvgComponent
