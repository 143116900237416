import * as React from 'react'

function WomanLogo() {
  return (
    <svg
      width='345'
      height='254'
      viewBox='0 0 345 254'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clip-path='url(#clip0_22_6638)'>
        <path
          d='M344.971 202.365C345.54 185.362 337.774 168.467 322.668 162.674C326.268 180.051 323.212 199.599 314.214 216.761C310.709 223.445 306.16 230.609 307.409 237.427C308.186 241.67 311.196 244.957 314.807 247.041C318.419 249.126 322.628 250.162 326.784 251.178L327.52 251.902C336.796 236.384 344.402 219.368 344.971 202.365Z'
          fill='#F2F2F2'
        />
        <path
          d='M322.459 162.992C330.032 173.594 333.896 187.188 333.058 201.169C332.936 204.264 332.348 207.323 331.314 210.243C330.174 213.171 328.363 215.79 326.027 217.887C323.867 219.904 321.457 221.747 319.756 224.191C318.915 225.383 318.324 226.734 318.016 228.161C317.709 229.587 317.691 231.062 317.966 232.496C318.6 235.893 320.461 238.686 322.385 241.339C324.521 244.285 326.792 247.304 327.292 251.17C327.352 251.638 328.095 251.414 328.035 250.946C327.165 244.22 321.347 240.309 319.231 234.228C318.244 231.391 318.155 228.114 319.957 225.168C321.532 222.591 324.016 220.688 326.218 218.662C328.527 216.639 330.376 214.143 331.641 211.343C332.815 208.468 333.517 205.421 333.721 202.322C334.267 195.634 333.724 188.902 332.115 182.389C330.386 175.219 327.306 168.446 323.04 162.435C322.778 162.069 322.2 162.628 322.459 162.992Z'
          fill='white'
        />
        <path
          d='M333.57 196.605C336.004 196.042 338.202 194.732 339.858 192.858C341.515 190.985 342.547 188.641 342.812 186.152C342.85 185.668 342.101 185.692 342.063 186.177C341.821 188.494 340.859 190.677 339.314 192.419C337.769 194.16 335.717 195.373 333.449 195.886C333.401 195.893 333.355 195.91 333.314 195.935C333.273 195.96 333.237 195.994 333.209 196.033C333.181 196.072 333.16 196.116 333.149 196.164C333.138 196.211 333.137 196.259 333.145 196.307C333.153 196.355 333.17 196.4 333.196 196.441C333.222 196.482 333.256 196.518 333.295 196.545C333.335 196.573 333.379 196.593 333.427 196.603C333.474 196.613 333.523 196.614 333.57 196.605Z'
          fill='white'
        />
        <path
          d='M327.237 216.991C325.363 215.688 323.786 214.002 322.609 212.044C321.432 210.086 320.682 207.9 320.409 205.631C320.346 205.163 319.603 205.388 319.665 205.855C319.955 208.216 320.74 210.488 321.969 212.523C323.198 214.557 324.844 216.308 326.798 217.658C327.171 217.913 327.609 217.245 327.237 216.991Z'
          fill='white'
        />
        <path
          d='M329.531 175.805C328.689 176.202 327.76 176.376 326.832 176.312C325.903 176.248 325.007 175.948 324.227 175.439C323.857 175.179 323.42 175.848 323.787 176.106C324.651 176.664 325.641 176.995 326.666 177.068C327.692 177.141 328.719 176.954 329.653 176.524C329.75 176.493 329.834 176.428 329.89 176.343C329.947 176.257 329.973 176.155 329.964 176.053C329.955 176.006 329.937 175.961 329.909 175.923C329.882 175.884 329.846 175.852 329.805 175.828C329.764 175.804 329.718 175.79 329.671 175.786C329.624 175.782 329.576 175.789 329.531 175.805Z'
          fill='white'
        />
        <path
          d='M269.257 192.152C269.483 192.321 269.708 192.491 269.932 192.667C272.941 194.961 275.729 197.532 278.26 200.346C278.459 200.56 278.658 200.781 278.85 201.001C284.912 207.942 289.453 216.082 292.178 224.893C293.295 228.486 294.117 232.164 294.638 235.89C295.368 241.087 295.734 246.839 298.118 251.121C298.36 251.569 298.633 252 298.936 252.41L326.445 252.878C326.513 252.848 326.579 252.823 326.647 252.792L327.738 252.862C327.724 252.664 327.706 252.46 327.693 252.262C327.686 252.147 327.672 252.032 327.665 251.917C327.658 251.84 327.651 251.764 327.65 251.693C327.647 251.668 327.645 251.642 327.642 251.623C327.64 251.553 327.631 251.489 327.628 251.425C327.53 250.282 327.426 249.139 327.309 247.995C327.31 247.989 327.31 247.989 327.304 247.983C326.405 239.275 324.754 230.67 321.803 222.776C321.714 222.538 321.626 222.295 321.525 222.057C320.186 218.534 318.527 215.142 316.57 211.922C315.501 210.18 314.332 208.502 313.07 206.895C309.801 202.757 305.813 199.245 301.297 196.53C292.256 191.083 281.149 188.866 270.1 191.911C269.818 191.989 269.541 192.067 269.257 192.152Z'
          fill='#F2F2F2'
        />
        <path
          d='M269.264 192.507C282.394 195.461 294.064 202.809 301.816 213.294C303.575 215.55 304.919 218.102 305.787 220.829C306.559 223.669 306.549 226.666 305.76 229.501C305.077 232.255 304.068 235.037 304.049 237.816C304.033 239.176 304.338 240.52 304.94 241.739C305.542 242.959 306.423 244.018 307.512 244.83C310.136 246.868 313.476 247.728 316.787 248.45C320.464 249.251 324.304 250.023 327.094 252.477C327.432 252.775 327.952 252.162 327.614 251.865C322.761 247.595 315.245 248.346 309.682 245.296C307.087 243.872 305.018 241.589 304.82 238.39C304.647 235.592 305.685 232.723 306.4 229.938C307.207 227.168 307.326 224.242 306.745 221.415C306.03 218.639 304.794 216.025 303.103 213.712C299.428 208.517 294.845 204.03 289.576 200.469C283.476 196.312 276.642 193.355 269.438 191.756C268.985 191.654 268.813 192.405 269.264 192.507Z'
          fill='white'
        />
        <path
          d='M299.497 209.726C303.191 205.981 303.958 200.38 301.312 196.643C301.051 196.274 300.404 196.748 300.665 197.116C303.145 200.618 302.406 205.786 298.952 209.287C298.589 209.655 299.135 210.092 299.497 209.726Z'
          fill='white'
        />
        <path
          d='M306.285 228.125C303.975 228.379 301.637 228.16 299.414 227.481C297.191 226.802 295.128 225.678 293.352 224.176C293.013 223.88 292.493 224.493 292.832 224.789C294.684 226.347 296.833 227.513 299.149 228.214C301.464 228.916 303.898 229.138 306.302 228.869C306.787 228.823 306.767 228.08 306.285 228.125Z'
          fill='white'
        />
        <path
          d='M283.294 197.344C282.744 198.17 282.019 198.864 281.171 199.377C280.322 199.89 279.371 200.209 278.386 200.312C277.901 200.351 277.921 201.094 278.403 201.055C279.491 200.937 280.541 200.584 281.48 200.019C282.419 199.454 283.223 198.691 283.839 197.783C283.905 197.711 283.941 197.616 283.939 197.518C283.936 197.42 283.896 197.327 283.827 197.258C283.744 197.201 283.642 197.179 283.543 197.195C283.444 197.211 283.355 197.264 283.294 197.344Z'
          fill='white'
        />
        <path
          d='M61.9116 240.333C61.4226 243.942 58.9737 247.2 55.824 249.327C55.0735 249.836 54.2816 250.279 53.4565 250.654C52.5495 251.064 51.6139 251.408 50.6572 251.683C46.4603 252.912 41.9251 252.965 37.5122 252.989C33.0504 253.018 28.59 253.047 24.1309 253.075C20.6144 253.096 16.9838 253.096 13.7363 251.744C13.6877 251.726 13.6401 251.706 13.5937 251.683C10.4032 250.303 7.68536 247.27 7.76278 243.799C7.79391 242.577 8.13915 241.383 8.76516 240.333C9.17955 239.628 9.67756 238.975 10.2484 238.39C12.0168 236.553 14.4697 235.112 16.7679 233.952C20.5574 232.046 24.8399 229.571 25.9482 225.652C26.0652 225.24 26.1443 224.817 26.1845 224.391C26.4453 221.696 25.1373 219.218 23.4708 217.001C23.3078 216.78 23.1367 216.56 22.9614 216.34C22.5866 215.87 22.1995 215.409 21.8164 214.968C21.6535 214.78 21.4905 214.588 21.3234 214.396C19.7832 212.608 18.2307 210.697 17.4198 208.562C17.0708 207.668 16.8765 206.721 16.8453 205.761C16.7557 202.295 20.6226 198.805 23.5482 200.442C24.9825 200.303 26.4168 200.16 27.8511 200.013C29.6114 199.838 31.3635 199.662 33.1197 199.482H33.1238C33.519 199.45 33.9061 199.409 34.2932 199.368C37.9482 199.005 41.6005 198.639 45.2501 198.27C46.8718 198.111 48.7788 198.049 49.8341 199.299C50.9832 200.662 50.3883 202.818 49.2515 204.194C48.1146 205.565 46.5377 206.537 45.4701 207.958C44.0888 209.795 43.7669 212.118 44.1621 214.396C44.2766 215.058 44.4484 215.708 44.6756 216.34C45.0745 217.468 45.6271 218.535 46.3177 219.512C48.7136 222.876 52.2627 225.195 55.4124 227.861C58.5662 230.531 61.5163 233.919 61.9483 238.027C61.9605 238.145 61.9727 238.267 61.9768 238.39C62.0225 239.038 62.0007 239.69 61.9116 240.333Z'
          fill='#F2F2F2'
        />
        <path
          d='M36.2865 202.95C33.107 199.803 30.3988 196.212 28.2461 192.287C26.0998 188.383 24.5145 184.195 23.5372 179.846C22.5464 175.433 22.2183 170.897 22.5636 166.387C23.2797 157.201 26.6672 148.428 32.3072 141.151C32.6516 140.708 33.0043 140.272 33.3652 139.843C33.7675 139.363 34.4512 140.054 34.0517 140.531C28.2962 147.445 24.6905 155.899 23.6806 164.846C22.9501 171.682 23.7657 178.596 26.0669 185.073C28.368 191.551 32.0956 197.426 36.973 202.262C37.4186 202.703 36.732 203.39 36.2865 202.95Z'
          fill='#F2F2F2'
        />
        <path
          d='M39.411 133.397C39.2521 134.288 38.9653 135.151 38.5594 135.96C37.4387 138.215 35.6536 140.071 33.4457 141.276C33.3234 141.341 33.1971 141.411 33.0708 141.472C32.92 141.235 32.7652 140.998 32.6185 140.762C31.5785 139.21 30.8096 137.493 30.3448 135.683C29.8721 133.556 30.1288 131.167 31.4898 129.465C32.8467 127.763 35.4382 126.999 37.3166 128.101C37.859 128.426 38.3192 128.872 38.6613 129.404C38.4738 129.636 38.2864 129.865 38.099 130.098C38.0825 130.119 38.0648 130.14 38.046 130.159C37.883 130.363 37.7159 130.563 37.553 130.767C36.8725 131.604 36.1961 132.445 35.5156 133.278C36.4747 133.455 37.4507 133.522 38.4249 133.478C38.5024 133.474 38.5839 133.47 38.6613 133.466C38.9098 133.45 39.1625 133.429 39.411 133.397Z'
          fill='#F2F2F2'
        />
        <path
          d='M25.6671 150.139L25.4063 153.544L25.337 154.467C25.2637 154.43 25.1863 154.394 25.1129 154.357C22.5784 153.096 20.3416 151.308 18.5519 149.112C16.7621 146.917 15.4607 144.363 14.7346 141.623C14.4919 140.673 14.3216 139.705 14.2253 138.729C15.5455 138.524 16.8698 138.316 18.19 138.112C17.1211 136.719 15.7823 135.557 14.2538 134.695C14.6024 131.288 15.8312 128.03 17.8192 125.244C18.1615 130.951 24.1268 134.581 26.3109 139.859C26.9809 141.511 27.271 143.293 27.1595 145.073C27.0481 146.853 26.538 148.584 25.6671 150.139Z'
          fill='#F2F2F2'
        />
        <path
          d='M24.5343 180.045C24.2164 180.061 23.8986 180.069 23.5849 180.065C20.8732 180.048 18.1937 179.476 15.7105 178.384C13.2273 177.293 10.9929 175.704 9.14412 173.717C7.64756 172.075 6.44425 170.187 5.5869 168.136C5.61542 168.132 5.63987 168.128 5.66839 168.124C6.9886 167.919 8.31288 167.711 9.63309 167.507C8.23094 165.676 6.36738 164.253 4.2341 163.384C3.94559 161.539 3.92363 159.662 4.1689 157.811C7.15159 162.681 14.1234 163.073 18.5282 166.699C20.6288 168.453 22.0609 170.88 22.5825 173.57C22.7125 174.234 22.7821 174.909 22.7903 175.587C22.7944 175.75 22.7944 175.909 22.7903 176.068L22.8881 176.289L24.5343 180.045Z'
          fill='#F2F2F2'
        />
        <path
          d='M37.6385 185.683C36.7951 186.72 35.9516 187.765 35.1081 188.802C36.1451 188.993 37.2015 189.056 38.2538 188.99C37.3194 192.343 35.5255 195.393 33.0504 197.837C32.8182 198.066 32.5778 198.29 32.3333 198.503C32.2844 198.547 32.2314 198.592 32.1825 198.637L31.9625 198.013L31.9584 198.008L30.7115 194.55C29.1296 192.957 28.0341 190.944 27.5536 188.749V188.741C27.2614 187.456 27.1938 186.13 27.354 184.822C28.0508 179.151 32.8345 174.052 31.6446 168.462C34.2663 170.59 36.3004 173.354 37.553 176.493C36.8725 177.33 36.1961 178.171 35.5156 179.004C36.4747 179.181 37.4507 179.248 38.425 179.204C38.7662 180.586 38.9521 182.002 38.9791 183.425C38.9832 183.629 38.9832 183.829 38.9791 184.034C38.5513 184.564 38.1194 185.091 37.6915 185.622C37.675 185.643 37.6573 185.664 37.6385 185.683Z'
          fill='#F2F2F2'
        />
        <path
          d='M161.387 201.07L166.837 198.626L160.011 175.886L151.966 179.492L161.387 201.07Z'
          fill='#FFB6B6'
        />
        <path
          d='M157.483 194.896L165.636 189.128L167.886 192.762L179.384 193.329C179.952 193.357 180.496 193.566 180.937 193.926C181.378 194.285 181.692 194.777 181.835 195.328C181.977 195.88 181.94 196.463 181.728 196.991C181.517 197.52 181.142 197.967 180.659 198.268L170.579 204.533L166.616 202.018L168.163 206.034L164.363 208.397L157.483 194.896Z'
          fill='#2F2E41'
        />
        <path
          d='M301.389 158.028C301.389 155.741 300.622 153.885 299.681 153.88H43.1068C42.1614 153.885 41.3991 155.741 41.3948 158.028V252.173C41.3949 252.385 41.316 252.59 41.1736 252.747C41.0312 252.905 40.8355 253.003 40.6246 253.024C39.9359 253.09 39.2473 253.152 38.5586 253.209C38.5416 253.209 38.5246 253.213 38.5075 253.213C38.1328 253.247 37.758 253.273 37.3833 253.303H37.379C37.1862 252.945 36.9957 252.585 36.8074 252.222C36.7437 252.1 36.7104 251.964 36.7104 251.825V152.647C36.7104 152.016 36.736 151.38 36.7785 150.753C36.8393 149.891 36.9459 149.034 37.0979 148.184C37.8176 144.22 39.4444 141.536 41.2926 141.528H301.491C301.761 141.53 302.027 141.586 302.275 141.694C302.638 141.855 302.966 142.086 303.241 142.373C303.615 142.762 303.934 143.199 304.191 143.674C305.102 145.283 305.754 147.681 305.984 150.471C305.984 150.497 305.988 150.522 305.988 150.548C306.048 151.239 306.078 151.943 306.073 152.647L306.133 161.791L306.171 167.244L306.286 184.354L306.329 190.43L306.516 252.812C306.516 253.038 306.427 253.255 306.267 253.415C306.107 253.575 305.89 253.665 305.665 253.665H302.241C302.015 253.665 301.798 253.575 301.638 253.415C301.479 253.255 301.389 253.038 301.389 252.812V158.028Z'
          fill='#E6E6E6'
        />
        <path
          d='M231.669 98.7416H204.023C203.632 98.7422 203.257 98.8984 202.981 99.1758C202.705 99.4533 202.55 99.8292 202.55 100.221V143.979H233.146V100.221C233.146 100.027 233.108 99.8343 233.034 99.6548C232.96 99.4752 232.851 99.3121 232.714 99.1747C232.577 99.0373 232.414 98.9283 232.235 98.854C232.055 98.7797 231.863 98.7415 231.669 98.7416ZM217.939 123.604C217.075 123.6 216.247 123.254 215.635 122.642C215.024 122.029 214.679 121.2 214.675 120.334V115.286C214.676 114.42 215.02 113.589 215.632 112.976C216.244 112.363 217.074 112.019 217.939 112.019C218.804 112.019 219.634 112.363 220.246 112.976C220.858 113.589 221.203 114.42 221.204 115.286V120.334C221.2 121.2 220.855 122.029 220.243 122.642C219.632 123.254 218.804 123.6 217.939 123.604Z'
          fill='#2F2E41'
        />
        <path
          d='M202.455 142.58V146.801C202.456 147.02 202.543 147.23 202.697 147.384C202.851 147.539 203.061 147.626 203.279 147.627H232.417C232.635 147.626 232.844 147.538 232.998 147.384C233.153 147.229 233.24 147.02 233.241 146.801V142.58H202.455Z'
          fill='#3F3D56'
        />
        <path
          d='M166.935 36.8021C166.617 37.6047 166.127 38.3276 165.499 38.9199C164.872 39.5122 164.123 39.9595 163.305 40.2304C162.486 40.5012 161.618 40.5889 160.762 40.4874C159.906 40.3858 159.083 40.0975 158.35 39.6426L119.326 66.8819L118.248 56.0267L156.619 31.1185C157.463 29.9359 158.711 29.1046 160.126 28.7822C161.541 28.4599 163.025 28.6687 164.296 29.3693C165.568 30.0699 166.539 31.2135 167.025 32.5834C167.511 33.9533 167.479 35.4543 166.935 36.8021Z'
          fill='#FFB6B6'
        />
        <path
          d='M79.561 54.9788C79.561 54.9788 75.3354 61.2347 81.9663 66.7708C88.5971 72.307 115.473 71.2056 119.81 71.158C124.147 71.1104 144.047 50.4849 144.047 50.4849L143.917 38.5729L119.891 52.8566L79.561 54.9788Z'
          fill='#D19801'
        />
        <path
          d='M77.1685 96.5132L77.5944 104.62C77.5944 104.62 76.3374 111.802 77.3712 113.351C78.3972 114.893 106.572 117.187 106.572 117.187C106.572 117.187 105.688 105.097 107.282 96.3511L77.1685 96.5132Z'
          fill='#FFB6B6'
        />
        <path
          d='M163.636 179.057L152.791 188.569L131.535 145.012C110.457 148.644 90.5721 149.808 73.3723 145.414C67.5488 143.947 63.9891 137.922 65.4215 131.957C65.4884 131.679 65.5658 131.403 65.6537 131.13C67.7122 124.837 73.1006 120.385 77.346 113.463L108.356 113.935L104.892 125.909L135.016 122.003C141.074 121.218 146.606 125.611 147.373 131.816C147.413 132.144 147.44 132.473 147.452 132.803L163.636 179.057Z'
          fill='#2F2E41'
        />
        <path
          d='M89.2766 41.9561C96.2307 41.9561 101.868 36.3076 101.868 29.3399C101.868 22.3722 96.2307 16.7238 89.2766 16.7238C82.3224 16.7238 76.6849 22.3722 76.6849 29.3399C76.6849 36.3076 82.3224 41.9561 89.2766 41.9561Z'
          fill='#FFB6B6'
        />
        <path
          d='M81.8947 46.5105L93.4401 47.8714C93.927 47.9288 94.3839 48.137 94.7471 48.4668C95.1103 48.7967 95.3618 49.2319 95.4664 49.7118L97.3025 58.1299L108.748 71.6348C108.748 71.6348 112.858 79.3561 111.317 88.1069C110.389 93.3763 108.785 98.5033 106.543 103.36C106.543 103.36 78.3957 114.894 77.3682 113.35C76.3407 111.805 76.0072 104.791 76.0072 104.791L72.386 64.4256C72.1787 62.2988 72.4437 60.1524 73.162 58.1403C73.8803 56.1282 75.034 54.3003 76.5405 52.7876C77.4542 51.8872 78.3558 51.2569 79.0898 51.2569C80.7476 51.2009 80.5349 47.4624 81.8947 46.5105Z'
          fill='#D19801'
        />
        <path
          d='M106.668 217.983L112.026 220.612L124.924 200.696L117.016 196.815L106.668 217.983Z'
          fill='#FFB6B6'
        />
        <path
          d='M119.503 213.482L108.496 209.295L123.602 163.833C103.079 157.804 87.5979 148.093 74.081 136.566C69.4956 132.681 66.0567 127.693 69.962 122.967C70.1446 122.747 70.3352 122.533 70.5339 122.327C75.1455 117.583 86.8492 118.496 93.7006 114.151L109.024 130.543L108.063 134.934L136.83 144.704C142.615 146.669 145.655 153.053 143.619 158.964C143.512 159.276 143.391 159.583 143.257 159.885L119.503 213.482Z'
          fill='#2F2E41'
        />
        <path
          d='M106.485 214.81L115.695 218.666L113.817 222.507L119.445 232.569C119.723 233.066 119.835 233.639 119.766 234.204C119.696 234.769 119.447 235.298 119.056 235.711C118.666 236.125 118.153 236.403 117.593 236.504C117.034 236.606 116.457 236.525 115.946 236.274L105.294 231.046L105.315 226.346L102.741 229.793L98.7246 227.822L106.485 214.81Z'
          fill='#2F2E41'
        />
        <path
          d='M88.4773 145.202C87.7552 144.73 87.145 144.106 86.6897 143.373C86.2344 142.64 85.9452 141.815 85.8423 140.958C85.7394 140.1 85.8254 139.231 86.0943 138.41C86.3631 137.59 86.8082 136.838 87.3983 136.209L68.4779 92.4884L79.3108 93.5798L96.0803 136.196C97.07 137.259 97.6362 138.649 97.6713 140.103C97.7065 141.556 97.2083 142.972 96.2712 144.083C95.334 145.193 94.023 145.92 92.5865 146.126C91.1501 146.332 89.688 146.004 88.4773 145.202Z'
          fill='#FFB6B6'
        />
        <path
          d='M88.977 55.9905C88.977 55.9905 83.6936 50.6017 76.9647 56.0177C70.2357 61.4336 65.9912 88.0468 65.179 92.3158C64.3667 96.5847 80.604 120.212 80.604 120.212L92.2832 122.443L83.0672 96.0188L88.977 55.9905Z'
          fill='#D19801'
        />
        <path
          d='M277.449 30.4521H159.272C158.257 30.4538 157.283 30.8586 156.565 31.5779C155.847 32.2973 155.443 33.2725 155.442 34.2898V114.192C155.443 115.209 155.847 116.185 156.565 116.904C157.283 117.624 158.256 118.028 159.272 118.029H277.449C278.465 118.028 279.438 117.624 280.157 116.904C280.875 116.185 281.279 115.209 281.279 114.192V34.2898C281.278 33.2725 280.874 32.2973 280.156 31.5779C279.438 30.8586 278.465 30.4538 277.449 30.4521Z'
          fill='#3F3D56'
        />
        <path
          d='M276.463 33.224H160.259C159.716 33.2252 159.195 33.4424 158.811 33.8279C158.427 34.2135 158.211 34.7359 158.211 35.2806V113.202C158.212 113.746 158.428 114.268 158.812 114.652C159.196 115.037 159.716 115.254 160.259 115.254H276.463C277.006 115.254 277.526 115.037 277.91 114.652C278.294 114.268 278.51 113.746 278.511 113.202V35.2806C278.511 34.7359 278.295 34.2135 277.911 33.8279C277.527 33.4424 277.007 33.2252 276.463 33.224Z'
          fill='white'
        />
        <path
          d='M253.828 149.096H184.694C184.504 149.096 184.317 149.054 184.147 148.972C183.976 148.89 183.825 148.772 183.706 148.625C183.587 148.477 183.502 148.305 183.457 148.121C183.413 147.937 183.41 147.745 183.449 147.559L184.522 142.454C184.583 142.169 184.74 141.913 184.966 141.729C185.192 141.545 185.475 141.444 185.766 141.443H252.756C253.047 141.444 253.33 141.545 253.556 141.729C253.782 141.913 253.939 142.169 254 142.454L255.073 147.559C255.112 147.745 255.109 147.937 255.065 148.121C255.02 148.305 254.935 148.477 254.816 148.625C254.697 148.772 254.546 148.89 254.375 148.972C254.204 149.054 254.018 149.096 253.828 149.096Z'
          fill='#2F2E41'
        />
        <path
          d='M188.178 142.362H186.465C186.35 142.362 186.257 142.455 186.257 142.57V143.434C186.257 143.549 186.35 143.642 186.465 143.642H188.178C188.293 143.642 188.386 143.549 188.386 143.434V142.57C188.386 142.455 188.293 142.362 188.178 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M192.437 142.362H190.724C190.609 142.362 190.516 142.455 190.516 142.57V143.434C190.516 143.549 190.609 143.642 190.724 143.642H192.437C192.552 143.642 192.645 143.549 192.645 143.434V142.57C192.645 142.455 192.552 142.362 192.437 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M196.696 142.362H194.982C194.867 142.362 194.774 142.455 194.774 142.57V143.434C194.774 143.549 194.867 143.642 194.982 143.642H196.696C196.81 143.642 196.903 143.549 196.903 143.434V142.57C196.903 142.455 196.81 142.362 196.696 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M200.954 142.362H199.241C199.126 142.362 199.033 142.455 199.033 142.57V143.434C199.033 143.549 199.126 143.642 199.241 143.642H200.954C201.069 143.642 201.162 143.549 201.162 143.434V142.57C201.162 142.455 201.069 142.362 200.954 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M205.213 142.362H203.499C203.384 142.362 203.291 142.455 203.291 142.57V143.434C203.291 143.549 203.384 143.642 203.499 143.642H205.213C205.328 143.642 205.421 143.549 205.421 143.434V142.57C205.421 142.455 205.328 142.362 205.213 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M209.471 142.362H207.758C207.643 142.362 207.55 142.455 207.55 142.57V143.434C207.55 143.549 207.643 143.642 207.758 143.642H209.471C209.586 143.642 209.679 143.549 209.679 143.434V142.57C209.679 142.455 209.586 142.362 209.471 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M213.73 142.362H212.016C211.902 142.362 211.808 142.455 211.808 142.57V143.434C211.808 143.549 211.902 143.642 212.016 143.642H213.73C213.845 143.642 213.938 143.549 213.938 143.434V142.57C213.938 142.455 213.845 142.362 213.73 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M217.988 142.362H216.275C216.16 142.362 216.067 142.455 216.067 142.57V143.434C216.067 143.549 216.16 143.642 216.275 143.642H217.988C218.103 143.642 218.196 143.549 218.196 143.434V142.57C218.196 142.455 218.103 142.362 217.988 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M222.247 142.362H220.534C220.419 142.362 220.326 142.455 220.326 142.57V143.434C220.326 143.549 220.419 143.642 220.534 143.642H222.247C222.362 143.642 222.455 143.549 222.455 143.434V142.57C222.455 142.455 222.362 142.362 222.247 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M226.506 142.362H224.792C224.677 142.362 224.584 142.455 224.584 142.57V143.434C224.584 143.549 224.677 143.642 224.792 143.642H226.506C226.62 143.642 226.713 143.549 226.713 143.434V142.57C226.713 142.455 226.62 142.362 226.506 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M230.764 142.362H229.051C228.936 142.362 228.843 142.455 228.843 142.57V143.434C228.843 143.549 228.936 143.642 229.051 143.642H230.764C230.879 143.642 230.972 143.549 230.972 143.434V142.57C230.972 142.455 230.879 142.362 230.764 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M235.023 142.362H233.309C233.194 142.362 233.101 142.455 233.101 142.57V143.434C233.101 143.549 233.194 143.642 233.309 143.642H235.023C235.138 143.642 235.231 143.549 235.231 143.434V142.57C235.231 142.455 235.138 142.362 235.023 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M239.281 142.362H237.568C237.453 142.362 237.36 142.455 237.36 142.57V143.434C237.36 143.549 237.453 143.642 237.568 143.642H239.281C239.396 143.642 239.489 143.549 239.489 143.434V142.57C239.489 142.455 239.396 142.362 239.281 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M243.54 142.362H241.826C241.712 142.362 241.618 142.455 241.618 142.57V143.434C241.618 143.549 241.712 143.642 241.826 143.642H243.54C243.655 143.642 243.748 143.549 243.748 143.434V142.57C243.748 142.455 243.655 142.362 243.54 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M247.798 142.362H246.085C245.97 142.362 245.877 142.455 245.877 142.57V143.434C245.877 143.549 245.97 143.642 246.085 143.642H247.798C247.913 143.642 248.006 143.549 248.006 143.434V142.57C248.006 142.455 247.913 142.362 247.798 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M252.057 142.362H250.344C250.229 142.362 250.136 142.455 250.136 142.57V143.434C250.136 143.549 250.229 143.642 250.344 143.642H252.057C252.172 143.642 252.265 143.549 252.265 143.434V142.57C252.265 142.455 252.172 142.362 252.057 142.362Z'
          fill='#3F3D56'
        />
        <path
          d='M188.128 144.495H186.415C186.3 144.495 186.207 144.589 186.207 144.704V145.567C186.207 145.682 186.3 145.775 186.415 145.775H188.128C188.243 145.775 188.336 145.682 188.336 145.567V144.704C188.336 144.589 188.243 144.495 188.128 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M192.387 144.495H190.673C190.558 144.495 190.465 144.589 190.465 144.704V145.567C190.465 145.682 190.558 145.775 190.673 145.775H192.387C192.502 145.775 192.595 145.682 192.595 145.567V144.704C192.595 144.589 192.502 144.495 192.387 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M196.645 144.495H194.932C194.817 144.495 194.724 144.589 194.724 144.704V145.567C194.724 145.682 194.817 145.775 194.932 145.775H196.645C196.76 145.775 196.853 145.682 196.853 145.567V144.704C196.853 144.589 196.76 144.495 196.645 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M200.904 144.495H199.19C199.076 144.495 198.982 144.589 198.982 144.704V145.567C198.982 145.682 199.076 145.775 199.19 145.775H200.904C201.019 145.775 201.112 145.682 201.112 145.567V144.704C201.112 144.589 201.019 144.495 200.904 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M205.162 144.495H203.449C203.334 144.495 203.241 144.589 203.241 144.704V145.567C203.241 145.682 203.334 145.775 203.449 145.775H205.162C205.277 145.775 205.37 145.682 205.37 145.567V144.704C205.37 144.589 205.277 144.495 205.162 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M209.421 144.495H207.707C207.593 144.495 207.5 144.589 207.5 144.704V145.567C207.5 145.682 207.593 145.775 207.707 145.775H209.421C209.536 145.775 209.629 145.682 209.629 145.567V144.704C209.629 144.589 209.536 144.495 209.421 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M213.68 144.495H211.966C211.851 144.495 211.758 144.589 211.758 144.704V145.567C211.758 145.682 211.851 145.775 211.966 145.775H213.68C213.794 145.775 213.887 145.682 213.887 145.567V144.704C213.887 144.589 213.794 144.495 213.68 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M217.938 144.495H216.225C216.11 144.495 216.017 144.589 216.017 144.704V145.567C216.017 145.682 216.11 145.775 216.225 145.775H217.938C218.053 145.775 218.146 145.682 218.146 145.567V144.704C218.146 144.589 218.053 144.495 217.938 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M222.197 144.495H220.483C220.368 144.495 220.275 144.589 220.275 144.704V145.567C220.275 145.682 220.368 145.775 220.483 145.775H222.197C222.311 145.775 222.405 145.682 222.405 145.567V144.704C222.405 144.589 222.311 144.495 222.197 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M226.455 144.495H224.742C224.627 144.495 224.534 144.589 224.534 144.704V145.567C224.534 145.682 224.627 145.775 224.742 145.775H226.455C226.57 145.775 226.663 145.682 226.663 145.567V144.704C226.663 144.589 226.57 144.495 226.455 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M230.714 144.495H229C228.885 144.495 228.792 144.589 228.792 144.704V145.567C228.792 145.682 228.885 145.775 229 145.775H230.714C230.829 145.775 230.922 145.682 230.922 145.567V144.704C230.922 144.589 230.829 144.495 230.714 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M234.972 144.495H233.259C233.144 144.495 233.051 144.589 233.051 144.704V145.567C233.051 145.682 233.144 145.775 233.259 145.775H234.972C235.087 145.775 235.18 145.682 235.18 145.567V144.704C235.18 144.589 235.087 144.495 234.972 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M239.231 144.495H237.517C237.403 144.495 237.31 144.589 237.31 144.704V145.567C237.31 145.682 237.403 145.775 237.517 145.775H239.231C239.346 145.775 239.439 145.682 239.439 145.567V144.704C239.439 144.589 239.346 144.495 239.231 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M243.49 144.495H241.776C241.661 144.495 241.568 144.589 241.568 144.704V145.567C241.568 145.682 241.661 145.775 241.776 145.775H243.49C243.604 145.775 243.697 145.682 243.697 145.567V144.704C243.697 144.589 243.604 144.495 243.49 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M247.748 144.495H246.035C245.92 144.495 245.827 144.589 245.827 144.704V145.567C245.827 145.682 245.92 145.775 246.035 145.775H247.748C247.863 145.775 247.956 145.682 247.956 145.567V144.704C247.956 144.589 247.863 144.495 247.748 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M252.007 144.495H250.293C250.178 144.495 250.085 144.589 250.085 144.704V145.567C250.085 145.682 250.178 145.775 250.293 145.775H252.007C252.121 145.775 252.215 145.682 252.215 145.567V144.704C252.215 144.589 252.121 144.495 252.007 144.495Z'
          fill='#3F3D56'
        />
        <path
          d='M223.474 147.055H206.856C206.741 147.055 206.648 147.149 206.648 147.264V148.127C206.648 148.242 206.741 148.335 206.856 148.335H223.474C223.589 148.335 223.682 148.242 223.682 148.127V147.264C223.682 147.149 223.589 147.055 223.474 147.055Z'
          fill='#3F3D56'
        />
        <path
          d='M338.567 253.869L6.50559 254C6.37137 254 6.24278 253.946 6.148 253.851C6.05322 253.755 6 253.626 6 253.492C6 253.357 6.05322 253.228 6.148 253.133C6.24278 253.038 6.37137 252.984 6.50559 252.984L338.567 252.853C338.702 252.853 338.83 252.907 338.925 253.002C339.02 253.097 339.073 253.226 339.073 253.361C339.073 253.495 339.02 253.624 338.925 253.72C338.83 253.815 338.702 253.869 338.567 253.869Z'
          fill='#D19801'
        />
        <path
          d='M75.9609 18.0937C73.9296 18.6996 72.0345 16.9971 70.6207 15.3842L71.1232 15.9816C67.7972 18.3283 65.8085 22.1514 64.5181 26.0215C63.2278 29.8873 62.5081 33.9365 61.0517 37.7468C59.5952 41.5529 57.2445 45.2352 53.646 47.1339C48.8253 49.677 42.9187 48.5207 37.7914 46.6859C37.7232 46.6603 37.6593 46.639 37.5912 46.6134C39.6679 42.7268 40.9396 38.4599 41.3302 34.0688C39.221 38.1948 36.1791 41.7701 32.4469 44.5098C29.0966 42.9839 25.8751 41.1888 22.814 39.1421C20.8337 37.8151 18.747 36.0998 18.5171 33.7232C18.2829 31.3636 20.0416 29.2088 22.1156 28.0739C24.1895 26.9389 26.5743 26.5548 28.8398 25.8721C31.1011 25.1937 33.4093 24.0801 34.5974 22.032C37.3357 17.3214 32.7279 10.6779 35.6663 6.08671C37.1142 3.82954 39.9249 2.92496 42.5311 2.29773C46.5092 1.34436 50.547 0.661529 54.617 0.253904C58.1899 -0.104513 61.8821 -0.236784 65.2804 0.915269C68.6788 2.06732 71.762 4.74264 72.4902 8.26707C74.4747 9.31246 76.5741 10.4688 77.6388 12.4529C78.7034 14.4327 78.1115 17.4536 75.9609 18.0937Z'
          fill='#2F2E41'
        />
        <path
          d='M72.9286 17.2999C70.9834 19.7414 69.9246 22.3816 69.9246 23.9628C65.8838 31.3625 71.6622 37.7184 79.0476 41.767C81.9933 43.3903 85.3947 43.9844 88.7148 43.4556L89.0238 42.1341L89.4424 43.3245C89.4424 43.3245 94.2508 59.2369 95.8369 60.1704C95.235 57.3385 94.5456 44.2266 94.2105 41.4628C93.7467 37.7013 95.3637 32.1012 96.5301 28.6957C97.2533 26.5968 97.6245 24.3924 97.6285 22.172V22.0749L98.7643 25.7301L102.899 23.9628C102.13 19.5419 102.899 14.5232 93.6767 14.9584C88.6887 11.5213 84.2963 10.9978 80.6881 11.9726C80.2225 11.2513 77.5374 12.6023 76.7615 12.2225'
          fill='#2F2E41'
        />
        <path
          d='M218.465 44.3754C210.718 44.3784 203.276 47.4015 197.715 52.8049C192.153 58.2083 188.909 65.5679 188.667 73.326C188.659 73.629 188.655 73.9362 188.655 74.2434C188.655 80.1508 190.403 85.9254 193.678 90.8372C196.954 95.749 201.61 99.5772 207.057 101.838C212.504 104.099 218.498 104.69 224.28 103.538C230.063 102.385 235.374 99.5404 239.543 95.3633C243.712 91.1862 246.552 85.8642 247.702 80.0704C248.852 74.2765 248.262 68.2711 246.005 62.8134C243.749 57.3557 239.928 52.691 235.026 49.409C230.124 46.1271 224.36 44.3754 218.465 44.3754ZM218.465 103.258C210.787 103.25 203.426 100.191 197.997 94.751C192.568 89.3115 189.514 81.9361 189.506 74.2434C189.506 73.8466 189.515 73.454 189.532 73.0658C189.762 67.3822 191.655 61.892 194.975 57.2777C198.295 52.6634 202.896 49.1287 208.204 47.1131C213.513 45.0974 219.296 44.6898 224.834 45.9409C230.372 47.192 235.421 50.0466 239.353 54.1497C243.285 58.2528 245.927 63.4235 246.949 69.0187C247.972 74.6139 247.33 80.3868 245.104 85.6196C242.879 90.8525 239.167 95.3143 234.43 98.4503C229.694 101.586 224.142 103.258 218.465 103.258Z'
          fill='#3F3D56'
        />
        <path
          d='M243.59 74.2435C243.59 80.9202 240.943 87.3234 236.231 92.0445C231.519 96.7657 225.128 99.418 218.465 99.418C211.801 99.418 205.41 96.7657 200.698 92.0445C195.986 87.3234 193.339 80.9202 193.339 74.2435C193.339 73.4456 193.377 72.6605 193.45 71.8839C194.055 65.4402 197.114 59.4777 201.992 55.2345C206.87 50.9912 213.191 48.7932 219.643 49.0968C226.096 49.4005 232.184 52.1825 236.643 56.8651C241.102 61.5478 243.59 67.7713 243.59 74.2435Z'
          fill='#E6E6E6'
        />
        <path
          d='M225.947 75.9161C225.338 75.1138 224.537 74.4784 223.617 74.0686C222.484 73.5899 221.313 73.2046 220.117 72.9165V66.0639C221.956 66.3417 223.68 67.1295 225.095 68.3382L226.483 65.3727C225.685 64.66 224.768 64.0925 223.775 63.6958C222.674 63.2491 221.518 62.9521 220.338 62.8126V59.3394H217.123V62.8467C215.882 62.9864 214.684 63.3894 213.61 64.0286C212.64 64.6041 211.823 65.4063 211.229 66.3669C210.663 67.3043 210.369 68.3817 210.382 69.4774C210.326 70.6648 210.67 71.8366 211.357 72.8056C211.99 73.6445 212.825 74.3083 213.784 74.7342C214.936 75.227 216.127 75.6224 217.345 75.9161V82.4828C215.175 82.3188 213.107 81.4923 211.421 80.1147L210.033 83.0845C210.976 83.8656 212.048 84.475 213.201 84.8851C214.464 85.3381 215.784 85.6138 217.123 85.7043V89.1477H220.338V85.6403C222.124 85.4448 223.799 84.6754 225.112 83.4471C225.69 82.8914 226.147 82.2214 226.454 81.4796C226.76 80.7378 226.91 79.9403 226.892 79.1376C226.946 77.988 226.613 76.8537 225.947 75.9161ZM217.345 72.1911C216.561 71.9532 215.833 71.5616 215.202 71.0391C214.953 70.8163 214.755 70.5408 214.624 70.2325C214.493 69.9241 214.432 69.5905 214.445 69.2556C214.421 68.4956 214.7 67.7574 215.219 67.2032C215.795 66.6217 216.539 66.2353 217.345 66.0981V72.1911ZM222.136 81.271C221.592 81.8293 220.885 82.1986 220.117 82.3249V76.6756C220.851 76.8729 221.538 77.2169 222.136 77.6869C222.362 77.8808 222.542 78.1235 222.662 78.3968C222.783 78.6702 222.84 78.9671 222.83 79.2656C222.859 79.9979 222.611 80.7143 222.136 81.271Z'
          fill='#D19801'
        />
      </g>
      <defs>
        <clipPath id='clip0_22_6638'>
          <rect width='345' height='254' fill='white' />
        </clipPath>
      </defs>
    </svg>
  )
}

export default WomanLogo
