import { IThemeBuilder, IClientThemeBuilder, ENTITIES } from 'interfaces'
import { createMuiTheme } from '@material-ui/core'
import { OnidataPallete, AlfaPallete, ArbiPallete, RbPallete } from 'constants/themes'
import {
  LogoFull,
  LogoAlfa,
  LogoMin,
  LogoAlfaMin,
  LogoRb,
  LogoRodobens,
  LogoRodobensMin,
  LogoPefisa,
  LogoAegro,
  LogoAfinz,
  LogoSerEducacional,
  LogoBamaq,
  LogoCondor,
  LogoTorra,
  LogoCora,
  LogoBMS,
  LogoEstBank,
} from 'components/Svg'

import { theme } from '.'

const client = (process.env.REACT_APP_CLIENT || '').toLowerCase()

export const getClientSysName = (): string => {
  switch (client) {
    case 'onidata':
      return 'Onidata'
    case 'alfa':
      return 'Alfa'
    case 'arbi':
      return 'ADD18'
    case 'rodobens':
      return 'Rodobens'
    case 'serfinancas':
      return 'Ser Educacional'
    case 'afinz':
      return 'Afinz'
    case 'bamaq':
      return 'Bamaq'
    case 'estbank':
      return 'EstBank'
    case 'bms':
      return 'BMS'
    default:
      return 'Onidata'
  }
}

export const getClientThemePallete = (): IClientThemeBuilder => {
  switch (client) {
    case 'onidata':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoFull,
          logoMin: LogoMin,
        },
      }
    case 'alfa':
      return {
        pallete: {
          ...OnidataPallete,
          ...AlfaPallete,
        },
        logo: {
          default: LogoAlfa,
          logoMin: LogoAlfaMin,
        },
      }
    case 'arbi':
      return {
        pallete: {
          ...OnidataPallete,
          ...ArbiPallete,
        },
        logo: {
          default: null,
          logoMin: null,
        },
      }
    case 'rb':
      return {
        pallete: {
          ...OnidataPallete,
          ...RbPallete,
        },
        logo: {
          default: LogoRb,
          logoMin: LogoMin,
        },
      }
    case 'rodobens':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoRodobens,
          logoMin: LogoRodobensMin,
        },
      }
    case 'pefisa':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoPefisa,
          logoMin: LogoPefisa,
        },
      }
    case 'afinz':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoAfinz,
          logoMin: LogoAfinz,
        },
      }
    case 'serfinancas':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoSerEducacional,
          logoMin: LogoSerEducacional,
        },
      }
    case 'aegro':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoAegro,
          logoMin: LogoMin,
        },
      }
    case 'bamaq':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoBamaq,
          logoMin: LogoBamaq,
        },
      }
    case 'condor':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoCondor,
          logoMin: LogoCondor,
        },
      }
    case 'torra':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoTorra,
          logoMin: LogoTorra,
        },
      }
    case 'cora':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoCora,
          logoMin: LogoCora,
        },
      }
    case 'bms':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoBMS,
          logoMin: LogoBMS,
        },
      }
    case 'estbank':
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoEstBank,
          logoMin: LogoEstBank,
        },
      }
    default:
      return {
        pallete: OnidataPallete,
        logo: {
          default: LogoFull,
          logoMin: LogoMin,
        },
      }
  }
}

export const buildTheme = (Module: ENTITIES): IThemeBuilder => {
  const { pallete, logo } = getClientThemePallete()
  const isEmployee = Module === ENTITIES.EMPLOYEE || Module === ENTITIES.DEFAULT
  const builded: IThemeBuilder = {
    logo,
    theme: {
      primary: isEmployee ? pallete.PRIMARY : pallete.PRIMARY_COMPANY,
      secondary: isEmployee ? pallete.SECONDARY : pallete.SECONDARY_COMPANY,
      success: pallete.SUCCESS,
      info: pallete.INFO,
      warning: pallete.WARNING,
      error: pallete.DANGER,
      light: pallete.LIGHT,
      dark: pallete.DARK,
      low_dark: pallete.LOW_DARK,
      container: pallete.CONTAINER,
      gray: pallete.GRAY,
      border: pallete.BORDER,
      gray_100: pallete.GRAY_100,
      gray_200: pallete.GRAY_200_LIGHT,
      sidebarLogo: isEmployee ? pallete.SIDEBAR_LOGO_EMPLOYEE : pallete.SIDEBAR_LOGO_COMPANY,
      buttonText: pallete.BUTTON_TEXT_COLOR,
    },
    materialTheme: createMuiTheme({
      ...theme,
      palette: {
        primary: {
          main: isEmployee ? pallete.PRIMARY : pallete.PRIMARY_COMPANY,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        secondary: {
          main: pallete.SECONDARY,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        success: {
          main: pallete.SUCCESS,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        info: {
          main: pallete.INFO,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        warning: {
          main: pallete.WARNING,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        error: {
          main: pallete.DANGER,
          contrastText: pallete.BUTTON_TEXT_COLOR,
        },
        grey: {
          100: pallete.LIGHT,
          200: pallete.GRAY_200,
        },
      },
    }),
  }
  return builded
}

export default buildTheme
