import React from 'react'
import { Grid, GridSpacing } from '@material-ui/core'

interface IFormWrapper {
  spacing?: GridSpacing
}

const FormWrapper: React.FC<IFormWrapper> = ({ children, spacing }) => {
  return (
    <Grid container spacing={spacing === undefined ? 2 : spacing}>
      {children}
    </Grid>
  )
}

export default FormWrapper
