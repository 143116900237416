import React from 'react'

import { Container } from './styles'

export interface ICard {
  active?: boolean
  color?: 'primary' | 'secondary' | 'error' | 'warning'
}

const Card: React.FC<ICard> = ({ children, active, color }) => {
  return (
    <Container active={active} color={color}>
      {children}
    </Container>
  )
}

export default Card
