import React from 'react'
import { ContentTitle } from '..'
import { Box } from '@material-ui/core'

interface IContentBlock {
  title?: string
  noMarging?: boolean
  gap?: string
  hasBoldTitle?: boolean
}

const ContentBlock: React.FC<IContentBlock> = ({
  title,
  children,
  noMarging,
  gap,
  hasBoldTitle,
}) => {
  return (
    <Box
      width='100%'
      mt={noMarging ? '0' : '32px'}
      display='flex'
      flexDirection='column'
      style={{ gap: gap ? gap : 2 }}
    >
      {title && (
        <ContentTitle noMarging isBold={hasBoldTitle}>
          {title}
        </ContentTitle>
      )}
      {children}
    </Box>
  )
}

export default ContentBlock
