import styled from 'styled-components'
import { spacing } from 'helpers'

export const ListItemIcon = styled.div`
  margin-right: ${spacing(2)};
  height: 40px;

  svg {
    height: 40px;
  }
`
