import styled from 'styled-components'
import { Menu, MenuItem } from '@material-ui/core'

export const Dropdown = styled(Menu)`
  .MuiPaper-root {
    background-color: white;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border: 1px solid ${({ theme }) => theme.gray_100};
    border-radius: 5px;
  }
`

export const DropdownItem = styled(MenuItem)`
  border-bottom: 1px solid ${({ theme }) => theme.gray_100};
  display: flex;
  align-items: center;
`
