import React, { createRef } from 'react'
import { InputAdornment, TextField } from '@material-ui/core'
import { FieldRenderProps } from 'react-final-form'
import { capitalize } from 'helpers'
import { useErrors } from 'hooks'

import { Container } from './styles'
import { Attachment } from '@material-ui/icons'

interface IInput extends FieldRenderProps<any, HTMLElement> {
  label: string
  errorText: string
  size: 'small' | 'medium'
  withError?: boolean
  accept: string
}
const Input = ({
  id,
  input,
  meta,
  disabled,
  className,
  inputMode,
  tabIndex,
  inputRef,
  onChange: onChangeInput,
  onKeyDown,
  onKeyPress,
  onKeyUp,
  onPaste,
  onBlur: onBlurInput,
  autoComplete,
  label,
  size,
  variant,
  error,
  helperText,
  disableHelperText,
  placeholder,
  InputProps,
  inputProps,
  withError,
  accept,
}: IInput) => {
  const { name, value, onChange, onFocus, onBlur } = input
  const { error: metaError, touched, submitError, submitFailed, submitSucceeded } = meta
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || fieldError || submitError || ''
  const fileInput = createRef<HTMLInputElement>()

  return (
    <Container>
      <input
        ref={fileInput}
        type='file'
        accept={accept}
        onChange={({ target }) => {
          if (target?.files?.length) {
            onChange(target.files[0])
            return
          }
          onChange(undefined)
        }}
      />
      <TextField
        type='text'
        id={id}
        disabled={disabled}
        name={name}
        onClick={() => fileInput && fileInput.current?.click()}
        onBlur={onBlur || onBlurInput}
        onFocus={onFocus}
        onKeyPress={onKeyPress}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        onPaste={onPaste}
        value={value ? value.name : ''}
        label={label}
        className={className}
        inputMode={inputMode}
        tabIndex={tabIndex}
        ref={inputRef}
        autoComplete={autoComplete}
        helperText={
          !disableHelperText &&
          (((touched || submitSucceeded || submitFailed) && capitalize(formError)) ||
            helperText ||
            error)
        }
        error={
          Boolean((touched || submitFailed || submitSucceeded) && formError) ||
          Boolean(error) ||
          Boolean(withError)
        }
        size={size || 'small'}
        placeholder={placeholder}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={inputProps}
        variant={variant || 'standard'}
        InputProps={{
          ...InputProps,
          endAdornment: (
            <InputAdornment position='end'>
              <Attachment
                color='secondary'
                className='cursor-pointer'
                onClick={(e) => {
                  e.stopPropagation()
                  if (fileInput) fileInput.current?.click()
                }}
              />
            </InputAdornment>
          ),
        }}
        fullWidth
      />
    </Container>
  )
}

export default Input
