import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { useErrors } from 'hooks'
import Checkbox from '../uncontrolled/Checkbox'

interface ICheckbox extends FieldRenderProps<any, HTMLElement> {
  label: string
  size: 'small' | 'medium'
  color?: 'primary' | 'secondary' | 'default'
}
const ControlledCheckbox = ({
  id,
  input,
  meta,
  disabled,
  inputRef,
  label,
  size,
  error,
  color
}: ICheckbox) => {
  const { name, checked, onClick, onChange } = input
  const { error: metaError, submitFailed, submitSucceeded } = meta
  const { getFieldError } = useErrors()
  const fieldError = getFieldError(name)
  const formError = metaError || fieldError || ''
  return (
    <Checkbox
      error={Boolean((submitSucceeded || submitFailed) && (error || metaError || formError))}
      id={id}
      name={name}
      checked={checked}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      size={size || 'small'}
      color={color || 'primary'}
      label={label}
      ref={inputRef}
    />
  )
}

export default ControlledCheckbox
