import React from 'react'

import { Container } from './styles'

export interface IHeader {
  color?: 'error' | 'default'
}
const Header: React.FC<IHeader> = ({ children, color }) => {
  return <Container color={color}>{children}</Container>
}

export default Header
