import { ICustomTheme } from 'interfaces'

export const AlfaPallete: ICustomTheme = {
  PRIMARY: '#CBA639',
  SECONDARY: '#070F3D',
  PRIMARY_COMPANY: '#CBA639',
  SECONDARY_COMPANY: '#000',
}

export default AlfaPallete
