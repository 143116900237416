import React, { useEffect } from 'react'
import ILayout from 'interfaces/layout'
import { Layout } from 'templates/DefaultTemplate'
import { useErrors } from 'hooks'
import DefaultEntity from 'app/Default'
import { useHistory } from 'react-router-dom'

const Default: React.FC<ILayout> = ({ children }) => {
  const { error } = useErrors()
  const history = useHistory()

  useEffect(() => {
    if (Number(error.code) === 503) {
      history.push(`${DefaultEntity.route.UNAVAILABLE.path}`)
    }
  }, [error, history])

  return <Layout>{children}</Layout>
}

export default Default
