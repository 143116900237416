import { ICustomTheme } from 'interfaces'

export const ArbiPallete: ICustomTheme = {
  PRIMARY: '#00A494',
  SECONDARY: '#455a64',
  PRIMARY_COMPANY: '#00A494',
  SECONDARY_COMPANY: '#455a64',
}

export default ArbiPallete
