import React from 'react'
import { Box } from '@material-ui/core'

const Subtitle: React.FC = ({ children }) => {
  return (
    <Box color='secondary' component='span'>
      {children}
    </Box>
  )
}

export default Subtitle
