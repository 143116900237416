import styled from 'styled-components'
import { breakpoint } from 'helpers'

export const Container = styled.div`
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  width: 70%;
  position: relative;
  min-height: 350px;

  @media (max-width: ${breakpoint('sm')}) {
    min-height: 100vh;
    width: 100%;
  }
`
