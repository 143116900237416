import styled from 'styled-components'
import { spacing } from 'helpers'
import { IHeader } from '.'

export const Container = styled.div<IHeader>`
  background: ${({ theme, color }) => (color === 'error' ? theme.error : theme.low_dark)};
  padding: ${spacing(4)};
  min-height: 250px;
  display: flex;
  align-items: center;
  color: white;

  h1,
  h2,
  h3 {
    width: 50%;
    padding: 0 ${spacing(2)};
  }
`
