import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={362.558} height={123} viewBox='0 0 362.558 123' {...props}>
      <g data-name='Grupo 917'>
        <g data-name='Grupo 913'>
          <g data-name='Grupo 225' fill='none' stroke='#fff' strokeMiterlimit={10} strokeWidth={3}>
            <path
              data-name='Caminho 612'
              d='M223.237 92.804v23.478a5.233 5.233 0 01-5.217 5.217h-83.478a5.233 5.233 0 01-5.217-5.217V19.76a5.233 5.233 0 015.217-5.217h10.435'
            />
            <path
              data-name='Caminho 613'
              d='M207.585 14.543h10.435a5.233 5.233 0 015.217 5.217v39.13'
            />
            <path data-name='Caminho 614' d='M212.803 95.413v15.652H139.76V24.978h5.217' />
            <path data-name='Caminho 615' d='M207.585 24.978h5.217v31.3' />
            <path
              data-name='Caminho 616'
              d='M194.541 11.935h-7.826a10.435 10.435 0 10-20.869 0h-7.826a7.816 7.816 0 00-7.826 7.826v7.826h52.174v-7.826a7.816 7.816 0 00-7.827-7.826z'
            />
            <path data-name='Linha 21' d='M176.281 9.326v5.217' />
            <path data-name='Linha 22' strokeLinejoin='round' d='M233.672 61.5l-28.695 28.696' />
            <path data-name='Linha 23' strokeLinejoin='round' d='M204.977 61.5l28.695 28.696' />
            <path data-name='Linha 24' strokeLinejoin='round' d='M155.412 45.848h5.217' />
            <path data-name='Linha 25' strokeLinejoin='round' d='M165.846 45.848h31.304' />
            <path data-name='Linha 26' strokeLinejoin='round' d='M155.412 56.283h5.217' />
            <path data-name='Linha 27' strokeLinejoin='round' d='M165.846 56.283h31.304' />
            <path data-name='Linha 28' strokeLinejoin='round' d='M155.412 66.717h5.217' />
            <path data-name='Linha 29' strokeLinejoin='round' d='M165.846 66.717h31.304' />
          </g>
        </g>
        <g data-name='Grupo 916'>
          <g data-name='Grupo 914'>
            <g
              data-name='Grupo 225'
              fill='none'
              stroke='#fff'
              strokeMiterlimit={10}
              strokeWidth={3}
              opacity={0.3}
            >
              <path
                data-name='Caminho 612'
                d='M95.412 92.804v23.478a5.233 5.233 0 01-5.217 5.217H6.717a5.233 5.233 0 01-5.217-5.217V19.76a5.233 5.233 0 015.217-5.217h10.435'
              />
              <path
                data-name='Caminho 613'
                d='M79.76 14.543h10.435a5.233 5.233 0 015.217 5.217v39.13'
              />
              <path data-name='Caminho 614' d='M84.978 95.413v15.652H11.935V24.978h5.217' />
              <path data-name='Caminho 615' d='M79.76 24.978h5.217v31.3' />
              <path
                data-name='Caminho 616'
                d='M66.716 11.935H58.89a10.435 10.435 0 10-20.869 0h-7.826a7.816 7.816 0 00-7.826 7.826v7.826h52.174v-7.826a7.816 7.816 0 00-7.827-7.826z'
              />
              <path data-name='Linha 21' d='M48.456 9.326v5.217' />
              <path data-name='Linha 22' strokeLinejoin='round' d='M105.847 61.5L77.152 90.196' />
              <path data-name='Linha 23' strokeLinejoin='round' d='M77.152 61.5l28.695 28.696' />
              <path data-name='Linha 24' strokeLinejoin='round' d='M27.587 45.848h5.217' />
              <path data-name='Linha 25' strokeLinejoin='round' d='M38.021 45.848h31.304' />
              <path data-name='Linha 26' strokeLinejoin='round' d='M27.587 56.283h5.217' />
              <path data-name='Linha 27' strokeLinejoin='round' d='M38.021 56.283h31.304' />
              <path data-name='Linha 28' strokeLinejoin='round' d='M27.587 66.717h5.217' />
              <path data-name='Linha 29' strokeLinejoin='round' d='M38.021 66.717h31.304' />
            </g>
          </g>
          <g data-name='Grupo 915'>
            <g
              data-name='Grupo 225'
              fill='none'
              stroke='#fff'
              strokeMiterlimit={10}
              strokeWidth={3}
              opacity={0.3}
            >
              <path
                data-name='Caminho 612'
                d='M351.062 92.804v23.478a5.233 5.233 0 01-5.217 5.217h-83.478a5.233 5.233 0 01-5.217-5.217V19.76a5.233 5.233 0 015.217-5.217h10.435'
              />
              <path
                data-name='Caminho 613'
                d='M335.41 14.543h10.435a5.233 5.233 0 015.217 5.217v39.13'
              />
              <path data-name='Caminho 614' d='M340.628 95.413v15.652h-73.043V24.978h5.217' />
              <path data-name='Caminho 615' d='M335.41 24.978h5.217v31.3' />
              <path
                data-name='Caminho 616'
                d='M322.366 11.935h-7.826a10.435 10.435 0 10-20.869 0h-7.826a7.816 7.816 0 00-7.826 7.826v7.826h52.174v-7.826a7.816 7.816 0 00-7.827-7.826z'
              />
              <path data-name='Linha 21' d='M304.106 9.326v5.217' />
              <path data-name='Linha 22' strokeLinejoin='round' d='M361.497 61.5l-28.695 28.696' />
              <path data-name='Linha 23' strokeLinejoin='round' d='M332.802 61.5l28.695 28.696' />
              <path data-name='Linha 24' strokeLinejoin='round' d='M283.237 45.848h5.217' />
              <path data-name='Linha 25' strokeLinejoin='round' d='M293.671 45.848h31.304' />
              <path data-name='Linha 26' strokeLinejoin='round' d='M283.237 56.283h5.217' />
              <path data-name='Linha 27' strokeLinejoin='round' d='M293.671 56.283h31.304' />
              <path data-name='Linha 28' strokeLinejoin='round' d='M283.237 66.717h5.217' />
              <path data-name='Linha 29' strokeLinejoin='round' d='M293.671 66.717h31.304' />
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
