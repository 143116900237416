import React, { SyntheticEvent } from 'react'
import { Snackbar, SnackbarProps } from '@material-ui/core'
import { Alert, AlertProps } from '@material-ui/lab'

export type TSnack = AlertProps & SnackbarProps

const SnackBar: React.FC<TSnack> = props => {
  const { children, onClose, severity } = props
  return (
    <Snackbar
      autoHideDuration={6000}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      {...props}
    >
      <Alert
        onClose={(event: SyntheticEvent<Element, Event>) => onClose && setTimeout(onClose(event))}
        severity={severity}
      >
        {children}
      </Alert>
    </Snackbar>
  )
}

export default SnackBar
