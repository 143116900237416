import React from 'react'
import { Box } from '@material-ui/core'
import Spinner from 'components/Spinner'

import { ContentWrapper } from './styles'

const Content: React.FC = ({ children }) => {
  const childrenList = React.Children.map(children, child => Boolean(child)) || []
  const hasChildren = childrenList.find(child => child)

  return (
    <>
      {hasChildren && <ContentWrapper>{children}</ContentWrapper>}
      {!hasChildren && (
        <Box position='relative'>
          <Spinner noOverlay absolute={true} />
        </Box>
      )}
    </>
  )
}

export default Content
