import React from 'react'

import { Container } from './styles'
import { useCore } from 'hooks'

export const Header: React.FC = () => {
  const { entity } = useCore()
  const LogoSvg = entity.logo.default

  return <Container>{LogoSvg && <LogoSvg />}</Container>
}

export default Header
