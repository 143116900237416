import * as React from 'react'

function Error403(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={355.029} height={120.001} viewBox='0 0 355.029 120.001' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#fff}.prefix__b{opacity:.3}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M175.014 120a2.445 2.445 0 01-.645-.085c-2.646-.708-49.368-25.427-49.368-49.9V22.506a2.5 2.5 0 012.5-2.5c11.388 0 41.509-13.284 45.495-18.945A2.505 2.505 0 01175.041 0a2.5 2.5 0 012.043 1.06c3.869 5.494 32.681 18.945 45.442 18.945a2.5 2.5 0 012.5 2.5v50.014h-5V24.88c-13.346-1.265-36.953-11.711-44.992-18.773C166.789 13.249 142.367 23.5 130 24.855V70.02c0 18.738 37.53 41.472 44.97 44.78 1.3-.66 4.366-2.373 8.52-4.986l2.666 4.231c-4.076 2.566-9.1 5.5-10.5 5.872a2.477 2.477 0 01-.642.083z'
      />
      <path
        className='prefix__a'
        d='M140.003 70.02h-5V32.509a2.5 2.5 0 012.5-2.5c9.055 0 32.511-9.783 35.472-13.951l4.076 2.9c-3.848 5.416-25.691 14.569-37.048 15.892zM220.025 117.533a2.494 2.494 0 01-1.768-.733l-8.235-8.235-8.235 8.235a2.5 2.5 0 01-3.536 0l-7.5-7.5a2.5 2.5 0 010-3.536l8.235-8.235-8.235-8.235a2.5 2.5 0 010-3.536l7.5-7.5a2.5 2.5 0 013.536 0l8.235 8.235 8.235-8.235a2.5 2.5 0 013.536 0l7.5 7.5a2.5 2.5 0 010 3.536l-8.235 8.235 8.235 8.235a2.5 2.5 0 010 3.536l-7.5 7.5a2.494 2.494 0 01-1.768.733zm-10-15a2.494 2.494 0 011.768.733l8.235 8.235 3.966-3.966-8.235-8.235a2.5 2.5 0 010-3.536l8.233-8.243-3.966-3.966-8.235 8.235a2.5 2.5 0 01-3.536 0l-8.238-8.232-3.963 3.963 8.235 8.235a2.5 2.5 0 010 3.536l-8.235 8.235 3.963 3.969 8.235-8.235a2.494 2.494 0 011.771-.733z'
      />
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M50.014 120a2.445 2.445 0 01-.645-.085C46.723 119.207.001 94.488.001 70.015V22.506a2.5 2.5 0 012.5-2.5c11.388 0 41.509-13.284 45.495-18.945A2.505 2.505 0 0150.041 0a2.5 2.5 0 012.043 1.06c3.869 5.494 32.681 18.945 45.442 18.945a2.5 2.5 0 012.5 2.5v50.014h-5V24.88C81.68 23.615 58.073 13.169 50.034 6.107 41.789 13.249 17.367 23.5 5 24.855V70.02c0 18.738 37.53 41.472 44.97 44.78 1.3-.66 4.366-2.373 8.52-4.986l2.666 4.231c-4.076 2.566-9.1 5.5-10.5 5.872a2.477 2.477 0 01-.642.083z'
        />
        <path
          className='prefix__a'
          d='M15.003 70.02h-5V32.509a2.5 2.5 0 012.5-2.5c9.055 0 32.511-9.783 35.472-13.951l4.076 2.9C48.203 24.374 26.36 33.527 15.003 34.85zM95.025 117.533a2.494 2.494 0 01-1.768-.733l-8.235-8.235-8.235 8.235a2.5 2.5 0 01-3.536 0l-7.5-7.5a2.5 2.5 0 010-3.536l8.235-8.235-8.235-8.235a2.5 2.5 0 010-3.536l7.5-7.5a2.5 2.5 0 013.536 0l8.235 8.235 8.235-8.235a2.5 2.5 0 013.536 0l7.5 7.5a2.5 2.5 0 010 3.536l-8.235 8.235 8.235 8.235a2.5 2.5 0 010 3.536l-7.5 7.5a2.494 2.494 0 01-1.768.733zm-10-15a2.494 2.494 0 011.768.733l8.235 8.235 3.966-3.966-8.235-8.235a2.5 2.5 0 010-3.536l8.233-8.243-3.966-3.966-8.235 8.235a2.5 2.5 0 01-3.536 0l-8.238-8.232-3.963 3.963 8.235 8.235a2.5 2.5 0 010 3.536l-8.235 8.235 3.963 3.969 8.235-8.235a2.494 2.494 0 011.771-.733z'
        />
      </g>
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M300.014 120a2.445 2.445 0 01-.645-.085c-2.646-.708-49.368-25.427-49.368-49.9V22.506a2.5 2.5 0 012.5-2.5c11.388 0 41.509-13.284 45.495-18.945A2.505 2.505 0 01300.041 0a2.5 2.5 0 012.043 1.06c3.869 5.494 32.681 18.945 45.442 18.945a2.5 2.5 0 012.5 2.5v50.014h-5V24.88c-13.346-1.265-36.953-11.711-44.992-18.773C291.789 13.249 267.367 23.5 255 24.855V70.02c0 18.738 37.53 41.472 44.97 44.78 1.3-.66 4.366-2.373 8.52-4.986l2.666 4.231c-4.076 2.566-9.1 5.5-10.5 5.872a2.477 2.477 0 01-.642.083z'
        />
        <path
          className='prefix__a'
          d='M265.003 70.02h-5V32.509a2.5 2.5 0 012.5-2.5c9.055 0 32.511-9.783 35.472-13.951l4.076 2.9c-3.848 5.416-25.691 14.569-37.048 15.892zM345.025 117.533a2.494 2.494 0 01-1.768-.733l-8.235-8.235-8.235 8.235a2.5 2.5 0 01-3.536 0l-7.5-7.5a2.5 2.5 0 010-3.536l8.235-8.235-8.235-8.235a2.5 2.5 0 010-3.536l7.5-7.5a2.5 2.5 0 013.536 0l8.235 8.235 8.235-8.235a2.5 2.5 0 013.536 0l7.5 7.5a2.5 2.5 0 010 3.536l-8.235 8.235 8.235 8.235a2.5 2.5 0 010 3.536l-7.5 7.5a2.494 2.494 0 01-1.768.733zm-10-15a2.494 2.494 0 011.768.733l8.235 8.235 3.966-3.966-8.235-8.235a2.5 2.5 0 010-3.536l8.233-8.243-3.966-3.966-8.235 8.235a2.5 2.5 0 01-3.536 0l-8.238-8.232-3.963 3.963 8.235 8.235a2.5 2.5 0 010 3.536l-8.235 8.235 3.963 3.969 8.235-8.235a2.494 2.494 0 011.771-.733z'
        />
      </g>
    </svg>
  )
}

export default Error403
