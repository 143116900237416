import { authRequired } from 'permissions'
import LazyLoading from 'components/LazyLoading'
import { routeCodes, subRouteCodes } from 'constants/routes'

const Login = LazyLoading(() => import('./pages/Login/Password'))
const Token = LazyLoading(() => import('./pages/Login/Token'))
const TokenOptions = LazyLoading(() => import('./pages/Login/Token/Options'))
const TokenEmail = LazyLoading(() => import('./pages/Login/Token/Email'))
const TokenValidate = LazyLoading(() => import('./pages/Login/Token/Validate'))
const TokenSMS = LazyLoading(() => import('./pages/Login/Token/SMS'))
const FirstAccess = LazyLoading(() => import('./pages/Register/FirstAccess'))
const FirstAccessOptions = LazyLoading(() => import('./pages/Register/FirstAccess/Options'))
const FirstAccessEmail = LazyLoading(() => import('./pages/Register/FirstAccess/Email'))
const FirstAccessEmailForm = LazyLoading(() => import('./pages/Register/FirstAccess/Email/Form'))
const FirstAccessValidate = LazyLoading(() => import('./pages/Register/FirstAccess/Email/Validate'))
const FirstAccessInvite = LazyLoading(() => import('./pages/Register/FirstAccess/Invite'))
const FirstAccessTelephone = LazyLoading(() => import('./pages/Register/FirstAccess/Telephone'))
const FirstAccessValidatePhone = LazyLoading(() =>
  import('./pages/Register/FirstAccess/ValidatePhone')
)
const LGPDLayout = LazyLoading(() => import('./pages/Register/LGPD'))
const LGPDRegister = LazyLoading(() => import('./pages/Register/LGPD/Register'))
const LGPDValidate = LazyLoading(() => import('./pages/Register/LGPD/Validate'))
const FirstAccessUserTerms = LazyLoading(() => import('./pages/Register/FirstAccess/UserTerms'))
const VerifyStatus = LazyLoading(() => import('./pages/Login/VerifyStatus'))
const PendingApprove = LazyLoading(() => import('./pages/Login/PendingApprove'))
const Invite = LazyLoading(() => import('./pages/Invite'))
const InviteTelephone = LazyLoading(() => import('./pages/Invite/Telephone'))
const InviteToken = LazyLoading(() => import('./pages/Invite/Token'))
const InvitePassword = LazyLoading(() => import('./pages/Invite/Password'))
const Profiles = LazyLoading(() => import('./pages/Profiles'))
const ProfilesList = LazyLoading(() => import('./pages/Profiles/List'))
const ProfilesLogin = LazyLoading(() => import('./pages/Profiles/Login'))
const ProfilesUserTerm = LazyLoading(() => import('./pages/Profiles/UserTerm'))
const Default = LazyLoading(() => import('./pages/Login'))
const RecoveryPassword = LazyLoading(() => import('./pages/RecoveryPassword'))
const RecoveryPasswordSendToken = LazyLoading(() => import('./pages/RecoveryPassword/SendToken'))
const RecoveryPasswordConfirmToken = LazyLoading(() =>
  import('./pages/RecoveryPassword/ConfirmToken')
)
const RecoveryPasswordChange = LazyLoading(() => import('./pages/RecoveryPassword/Password'))
const SendDocuments = LazyLoading(() => import('app/SalesChannel/pages/SendDocuments'))
const SignContract = LazyLoading(() => import('app/SalesChannel/pages/SignContract'))
const SignPortabilityCEP = LazyLoading(() => import('app/Corban/CEP/pages/SignPortability'))
const Location = LazyLoading(() => import('app/SalesChannel/pages/Location'))
const FeedbackLayout = LazyLoading(() => import('app/Feedback/pages'))
const Unavailable = LazyLoading(() => import('app/Feedback/pages/Unavailable'))
const SignFailed = LazyLoading(() => import('app/Feedback/pages/SignFailed'))
const WithoutPermission = LazyLoading(() => import('app/Feedback/pages/WithoutPermission'))
const LGPDMailSent = LazyLoading(() => import('app/Feedback/pages/LGPD/Mail'))
const LGPDFinishRegister = LazyLoading(() => import('app/Feedback/pages/LGPD/FinishRegister'))

//Novos LazyLoading do autoatendimento FGTS

const DefaultFGTS = LazyLoading(() => import('./pages/Register/FGTS'))
const RegisterFGTS = LazyLoading(() => import('./pages/Register/FGTS/Form/'))
const TokenSendFGTS = LazyLoading(() => import('./pages/Register/FGTS/ConfirmSend'))
const TokenValidateFGTS = LazyLoading(() => import('./pages/Register/FGTS/Token'))

export const DefaultRoutes = {
  DEFAULT: {
    path: routeCodes.NULL_ROUTE,
    name: 'Acessar sistema',
    component: process.env.REACT_APP_CLIENT?.includes('PEFISA') ? Login : Default,
    defaultRoute: true,
  },
  PROFILES: {
    path: routeCodes.DEFAULT.PROFILES,
    name: 'Selecionar Módulo',
    component: Profiles,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Selecionar Módulo',
        component: ProfilesList,
      },
      USER_TERM: {
        path: subRouteCodes.DEFAULT.PROFILES.USER_TERM,
        name: 'Selecionar Módulo',
        permissions: [authRequired],
        component: ProfilesUserTerm,
      },
      LOGIN: {
        path: subRouteCodes.DEFAULT.PROFILES.LOGIN,
        name: 'Entrar por Código',
        permissions: [authRequired],
        component: ProfilesLogin,
      },
    },
  },
  LOGIN: {
    path: routeCodes.DEFAULT.LOGIN,
    name: 'Acessar sistema',
    component: Login,
  },
  PENDING_APPROVE: {
    path: routeCodes.DEFAULT.PENDING_APPROVE,
    name: 'Pendente aprovação',
    component: PendingApprove,
  },
  TOKEN: {
    path: routeCodes.DEFAULT.TOKEN,
    name: 'Acessar via token',
    component: Token,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Escolha uma opção',
        component: TokenOptions,
      },
      EMAIL: {
        path: subRouteCodes.DEFAULT.TOKEN.EMAIL,
        name: 'Código',
        component: TokenEmail,
      },
      SMS: {
        path: subRouteCodes.DEFAULT.TOKEN.SMS,
        name: 'SMS',
        component: TokenSMS,
      },
      VALIDATE: {
        path: subRouteCodes.DEFAULT.TOKEN.VALIDATE,
        name: 'Validar',
        component: TokenValidate,
      },
    },
  },
  VERIFY_STATUS: {
    path: routeCodes.DEFAULT.VERIFY_STATUS,
    name: 'Verificar vínculo com empresa',
    component: VerifyStatus,
  },
  SEND_DOCUMENTS: {
    path: routeCodes.DEFAULT.SEND_DOCUMENTS,
    name: 'Envio de documentos',
    component: SendDocuments,
  },
  SIGN_CONTRACT: {
    path: routeCodes.DEFAULT.SIGN_CONTRACT,
    name: 'Assinar contrato',
    component: SignContract,
  },
  SIGN_CEP_CONTRACT: {
    path: `${routeCodes.DEFAULT.SIGN_CONTRACT}${routeCodes.MODULES.CEP}`,
    name: 'Assinar contrato',
    component: SignContract,
  },
  SIGN_CDC_CONTRACT: {
    path: `${routeCodes.DEFAULT.SIGN_CONTRACT}${routeCodes.MODULES.CDC}`,
    name: 'Assinar contrato',
    component: SignContract,
  },
  SIGN_CEP_PORTABILITY: {
    path: `${routeCodes.DEFAULT.SIGN_PORTABILITY}${routeCodes.MODULES.CEP}`,
    name: 'Assinar portabilidade',
    component: SignPortabilityCEP,
  },
  LOCATION: {
    path: routeCodes.DEFAULT.LOCATION,
    name: 'Localizaçao',
    component: Location,
  },
  LGPD: {
    path: routeCodes.DEFAULT.LGPD,
    name: 'LGPD - Lei Geral de Proteção de Dados Pessoais',
    component: LGPDLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'LGPD - Lei Geral de Proteção de Dados Pessoais',
        component: LGPDRegister,
      },
      VALIDATE: {
        path: subRouteCodes.DEFAULT.LGPD.VALIDATE,
        name: 'Cadastro',
        component: LGPDValidate,
      },
    },
  },
  FIRST_ACCESS: {
    path: routeCodes.DEFAULT.FIRST_ACCESS,
    name: 'Primeiro acesso',
    component: FirstAccess,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Escolha uma opção',
        component: FirstAccessOptions,
      },
      EMAIL: {
        path: subRouteCodes.DEFAULT.FIRST_ACCESS.EMAIL.DEFAULT,
        name: 'Cadastrar',
        component: FirstAccessEmail,
        routes: {
          DEFAULT: {
            path: routeCodes.NULL_ROUTE,
            name: 'Cadastrar',
            component: FirstAccessEmailForm,
          },
          VALIDATE: {
            path: subRouteCodes.DEFAULT.FIRST_ACCESS.EMAIL.VALIDATE,
            name: 'Confirmar email',
            component: FirstAccessValidate,
          },
        },
      },
      USER_TERMS: {
        path: subRouteCodes.DEFAULT.FIRST_ACCESS.USER_TERMS,
        name: 'Termos de uso',
        component: FirstAccessUserTerms,
      },
      TELEPHONE: {
        path: subRouteCodes.DEFAULT.FIRST_ACCESS.TELEPHONE,
        name: 'Confirmar telefone',
        component: FirstAccessTelephone,
      },
      VALIDATE_PHONE: {
        path: subRouteCodes.DEFAULT.FIRST_ACCESS.VALIDATE_PHONE,
        name: 'Confirmar código',
        component: FirstAccessValidatePhone,
      },
      INVITE: {
        path: subRouteCodes.DEFAULT.FIRST_ACCESS.INVITE,
        name: 'Carta de acesso',
        component: FirstAccessInvite,
      },
    },
  },
  VALIDATE_INVITE: {
    path: routeCodes.DEFAULT.VALIDATE_INVITE,
    name: 'Acessar sistema',
    component: Invite,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Validar acesso',
        component: InviteTelephone,
      },
      TOKEN: {
        path: subRouteCodes.DEFAULT.VALIDATE_INVITE.TOKEN,
        name: 'Validar código',
        component: InviteToken,
      },
      PASSWORD: {
        path: subRouteCodes.DEFAULT.VALIDATE_INVITE.PASSWORD,
        name: 'Definir Senha',
        component: InvitePassword,
      },
    },
  },
  RECOVERY_PASSWORD: {
    path: routeCodes.DEFAULT.RECOVERY_PASSWORD,
    name: 'Recuperar Senha',
    component: RecoveryPassword,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Recuperar Senha',
        component: RecoveryPasswordSendToken,
      },
      CONFIRM_TOKEN: {
        path: subRouteCodes.DEFAULT.RECOVERY_PASSWORD.CONFIRM_TOKEN,
        name: 'Confirmar código',
        component: RecoveryPasswordConfirmToken,
      },
      PASSWORD: {
        path: subRouteCodes.DEFAULT.RECOVERY_PASSWORD.PASSWORD,
        name: 'Alterar Senha',
        component: RecoveryPasswordChange,
      },
    },
  },
  UNAVAILABLE: {
    path: routeCodes.DEFAULT.UNAVAILABLE,
    name: 'Serviço Indisponível',
    component: FeedbackLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Serviço Indisponível',
        component: Unavailable,
      },
    },
  },
  SIGN_FAILED: {
    path: routeCodes.DEFAULT.SIGN_FAILED,
    name: 'Falha na assinatura',
    component: FeedbackLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Falha na assinatura',
        component: SignFailed,
      },
    },
  },
  NOT_FOUND: {
    path: routeCodes.DEFAULT.NOT_FOUND,
    name: 'Sem permissão',
    component: FeedbackLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Sem permissão',
        component: WithoutPermission,
      },
    },
  },
  LGPD_MAIL: {
    path: routeCodes.DEFAULT.LGPD_MAIL,
    name: 'Email enviado',
    component: FeedbackLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Email enviado',
        component: LGPDMailSent,
      },
    },
  },
  LGPD_FINISH_REGISTER: {
    path: routeCodes.DEFAULT.LGPD_FINISH_REGISTER,
    name: 'Registro finalizado',
    component: FeedbackLayout,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Registro finalizado',
        component: LGPDFinishRegister,
      },
    },
  },
  FGTS_REGISTER: {
    path: routeCodes.DEFAULT.FGTS_REGISTER,
    name: 'Cadastro Auto-atendimento FGTS',
    component: DefaultFGTS,
    routes: {
      DEFAULT: {
        path: routeCodes.NULL_ROUTE,
        name: 'Cadastro Auto-atendimento FGTS',
        component: RegisterFGTS,
      },
      TOKEN_SEND_FGTS: {
        path: subRouteCodes.DEFAULT.FGTS_REGISTER.SEND_TOKEN,
        name: 'Código Enviado',
        component: TokenSendFGTS,
      },
      VALIDATE: {
        path: subRouteCodes.DEFAULT.FGTS_REGISTER.VALIDATE,
        name: 'Validar',
        component: TokenValidateFGTS,
      },
    },
  },
}

export default DefaultRoutes
