import React from 'react'
import { Form } from 'react-final-form'
import { Typography, Box } from '@material-ui/core'
import { FieldToken } from 'components/Forms'
import Button from 'components/Button'
import { FormWrapper, GoBack } from 'templates/DefaultTemplate'
import ButtonWithTimer from 'components/ButtonWithTimer'

interface IToken {
  lengthToken: number
  error?: string
  buttonText?: string
  onSubmit: (body: {}) => void
  onResendToken: (body: {}) => void
}

const ConfirmToken: React.FC<IToken> = ({
  lengthToken,
  buttonText = 'Realizar acesso',
  error,
  onSubmit,
  onResendToken,
}) => {
  return (
    <Box>
      <GoBack />
      <Typography variant='h2'>Token de acesso</Typography>
      <Typography variant='subtitle1'>Confirme o token que foi enviado.</Typography>
      <Box mt={3}>
        <Form
          onSubmit={onSubmit}
          mutators={{
            setField: ([[field, value]], state, utils) => {
              utils.changeValue(state, field, () => value)
            },
          }}
          render={({ form, handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <FormWrapper>
                <FieldToken
                  lengthToken={lengthToken}
                  hasError={!!error}
                  setToken={(field, value) => form.mutators.setField([field, value])}
                />
                {error && (
                  <Box width='100%' textAlign='center' mt={2}>
                    <Typography color='error'>{error}</Typography>
                  </Box>
                )}
              </FormWrapper>
              <Box mt={2} display='flex' justifyContent='space-between' width='100%'>
                <ButtonWithTimer time={15} text='Reenviar token' onClick={onResendToken} />
                <Button color='primary' type='submit'>
                  {buttonText}
                </Button>
              </Box>
            </form>
          )}
        />
      </Box>
    </Box>
  )
}

export default ConfirmToken
