import * as React from 'react'

function ProposalSent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={408} height={120} viewBox='0 0 408 120' {...props}>
      <g data-name='Grupo 822'>
        <path
          data-name='Caminho 832'
          d='M261 117.944L204 1l-57 116.944 44.333.056L204 34.429 216.667 118z'
          fill='#fff'
        />
        <path data-name='Caminho 833' d='M144 120V0h120v120z' fill='none' />
        <g data-name='baseline-send-24px' opacity={0.3}>
          <path
            data-name='Caminho 832'
            d='M117 117.944L60 1 3 117.944l44.333.056L60 34.429 72.667 118z'
            fill='#fff'
          />
          <path data-name='Caminho 833' d='M0 120V0h120v120z' fill='none' />
        </g>
        <g data-name='baseline-send-24px' opacity={0.3}>
          <path
            data-name='Caminho 832'
            d='M405 117.944L348 1l-57 116.944 44.333.056L348 34.429 360.667 118z'
            fill='#fff'
          />
          <path data-name='Caminho 833' d='M288 120V0h120v120z' fill='none' />
        </g>
      </g>
    </svg>
  )
}

export default ProposalSent
