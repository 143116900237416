import styled from 'styled-components'
import { Dehaze as DehazeIcon } from '@material-ui/icons'
import { breakpoint, spacing } from 'helpers'
import { Width, Height, ZIndex } from 'constants/css'
import { ICollapse } from 'interfaces'

interface ISidebarItem extends ICollapse {
  active?: 1 | 0
}

interface ILogo {
  isSidePanelVisible?: boolean
  isCollapsed?: boolean
}

interface ICompanyInfos {
  isCollapsed?: boolean
}

export const Logo = styled.div<ILogo>`
  width: 100%;
  background: ${(props) => props.theme.sidebarLogo};
  height: ${Height.NAVBAR};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: ${spacing(1.5)} ${spacing(2)};
`

export const Dehaze = styled(DehazeIcon)`
  color: ${({ theme }) => theme.secondary};
  display: block;
  cursor: pointer;
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
`

export const SideBar = styled.nav`
  width: ${Width.SIDEBAR};
  height: 100%;
  min-height: 100vh;
  background: ${(props) => props.theme.secondary};
  position: fixed;
  left: 0;
  z-index: ${ZIndex.SIDEBAR};
  transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);

  @media (max-width: ${breakpoint('md')}) {
    width: 0;
    overflow: hidden;
  }
`

export const SidebarItem = styled.div<ISidebarItem>`
  display: flex;
  align-items: center;
  color: white;
  width: 100%;
  height: ${Height.SIDEBAR_ITEM};
  transition: 0.2s all ease;
  overflow: hidden;
  white-space: nowrap;
  padding: ${spacing(2)};

  &:hover {
    background: rgba(0, 0, 0, 0.2);
    cursor: pointer !important;
  }

  & > p {
    transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);
    opacity: 0;
  }

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    & > p {
      opacity: 1;
    }
  `}

  ${({ active, theme }) =>
    active &&
    `
    background: rgba(0, 0, 0, 0.2);
    border-right: 5px solid ${theme.primary};

    svg {
      fill: ${theme.primary}
    }
  `}
`

export const SidebarOverlay = styled.div`
  @media (max-width: ${breakpoint('md')}) {
    position: fixed;
    height: 0;
    width: 0;
    background: black;
    visibility: invisible;
    opacity: 0;
    top: 0;
    transition: 0.2s ease;
    transition-property: opacity, visibility;
  }
`

export const SidebarCompanies = styled.div`
  padding: ${spacing(2)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  width: 100%;
  height: ${Height.SIDEBAR_ITEM};
  border-bottom: 1px solid ${({ theme }) => theme.low_dark};
`

export const CompanyInfos = styled.div<ICompanyInfos>`
  opacity: 0;
  width: 0;
  position: absolute;

  ${({ isCollapsed }) =>
    !isCollapsed &&
    `
    position: initial;
    width: auto;
    transition: opacity 0.4s ease;
    transition-delay: 0.1s;
    opacity: 1;
  `}
`
