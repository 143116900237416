import * as React from 'react'

function Error404(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={409} height={95.583} viewBox='0 0 409 95.583' {...props}>
      <defs>
        <style>{'.prefix__a{fill:#fff}.prefix__b{opacity:.3}'}</style>
      </defs>
      <path
        className='prefix__a'
        d='M243.1 90.553h-93.069v-70.43H243.1v15.092h5.031V0H145v95.583h103.129V65.4H243.1zm0-85.522v10.061h-93.069V5.031z'
      />
      <path
        className='prefix__a'
        d='M155.061 7.546h5.031v5.031h-5.031zM162.607 7.546h5.031v5.031h-5.031zM187.761 25.154h-32.7v35.215h32.7zm-5.031 30.184h-22.638V30.185h22.638zM155.061 67.915h32.7v5.031h-32.7zM155.061 77.976h32.7v5.031h-32.7zM195.307 77.976h27.669v5.031h-27.669zM195.307 67.915h27.669v5.031h-27.669zM195.307 27.669h27.669V32.7h-27.669zM195.307 37.73h17.607v5.031h-17.607zM265 34.478l-3.557-3.557-14.571 14.571-14.571-14.571-3.557 3.557 14.571 14.571-14.571 14.571 3.557 3.557 14.571-14.571 14.571 14.571L265 63.62l-14.571-14.571z'
      />
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M387.1 90.553h-93.069v-70.43H387.1v15.092h5.031V0H289v95.583h103.129V65.4H387.1zm0-85.522v10.061h-93.069V5.031z'
        />
        <path
          className='prefix__a'
          d='M299.061 7.546h5.031v5.031h-5.031zM306.607 7.546h5.031v5.031h-5.031zM331.761 25.154h-32.7v35.215h32.7zm-5.031 30.184h-22.638V30.185h22.638zM299.061 67.915h32.7v5.031h-32.7zM299.061 77.976h32.7v5.031h-32.7zM339.307 77.976h27.669v5.031h-27.669zM339.307 67.915h27.669v5.031h-27.669zM339.307 27.669h27.669V32.7h-27.669zM339.307 37.73h17.607v5.031h-17.607zM409 34.478l-3.557-3.557-14.571 14.571-14.571-14.571-3.557 3.557 14.571 14.571-14.571 14.571 3.557 3.557 14.571-14.571 14.571 14.571L409 63.62l-14.571-14.571z'
        />
      </g>
      <g className='prefix__b'>
        <path
          className='prefix__a'
          d='M98.1 90.553H5.031v-70.43H98.1v15.092h5.031V0H0v95.583h103.13V65.4H98.1zm0-85.522v10.061H5.031V5.031z'
        />
        <path
          className='prefix__a'
          d='M10.061 7.546h5.031v5.031h-5.031zM17.607 7.546h5.031v5.031h-5.031zM42.761 25.154h-32.7v35.215h32.7zM37.73 55.338H15.092V30.185H37.73zM10.061 67.915h32.7v5.031h-32.7zM10.061 77.976h32.7v5.031h-32.7zM50.307 77.976h27.669v5.031H50.307zM50.307 67.915h27.669v5.031H50.307zM50.307 27.669h27.669V32.7H50.307zM50.307 37.73h17.608v5.031H50.307zM120 34.478l-3.557-3.557-14.571 14.571-14.571-14.571-3.557 3.557 14.571 14.571L83.744 63.62l3.557 3.557 14.571-14.571 14.571 14.571L120 63.62l-14.571-14.571z'
        />
      </g>
    </svg>
  )
}

export default Error404
