import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { IPermission } from 'interfaces'
import { useCore } from 'hooks'

interface IPermissionProps {
  children?: any
  permissions?: IPermission[]
  disableAction?: boolean
}

const PermissionsFC: React.FC<IPermissionProps> = ({ children, permissions, disableAction }) => {
  const { auth, entity, setEntity, logout } = useCore()
  const history = useHistory()
  const [isValid, setIsValid] = useState<boolean>(true)

  useEffect(() => {
    if (permissions && permissions instanceof Array) {
      for (let permission of permissions) {
        const bind = { auth, entity, setEntity, history, logout }
        if (!permission.validate.call(bind)) {
          if (!disableAction && permission.action) permission.action.call(bind)
          setIsValid(false)
          break
        }
      }
    }
  }, [permissions, history, auth, entity, setEntity, disableAction, logout])

  return isValid ? <>{children}</> : null
}

export default React.memo(PermissionsFC)
