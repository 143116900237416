import { useMediaQuery } from 'hooks'
import React, { useEffect, useState } from 'react'

import { Container, IHeader, StickyContainer } from './styles'

const Header: React.FC<IHeader> = ({
  children,
  noMargin,
  noPadding = true,
  alignItems,
  useSticky,
  marginBottom,
}) => {
  const [showSticky, setShowSticky] = useState<boolean>(false)
  const isSmallDevice = useMediaQuery('sm')

  useEffect(() => {
    const navbarElement = document.querySelector('#content-header')
    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting && isSmallDevice) {
          setShowSticky(true)
        } else {
          setShowSticky(false)
        }
      },
      { rootMargin: '8px' }
    )

    if (navbarElement && isSmallDevice) {
      observer.observe(navbarElement)
    }
    return () => {
      if (navbarElement && isSmallDevice) {
        observer.unobserve(navbarElement)
      }
    }
  }, [isSmallDevice, showSticky])

  return (
    <>
      <Container
        noPadding={noPadding}
        noMargin={noMargin}
        alignItems={alignItems}
        marginBottom={marginBottom}
        id='content-header'
        isSticky={useSticky && showSticky}
      >
        {children}
      </Container>
      {useSticky && <StickyContainer isVisible={showSticky}>{children}</StickyContainer>}
    </>
  )
}

export default Header
