import React, { SetStateAction, Dispatch, useEffect, useMemo } from 'react'

import { SidePanel as SidePanelContainer, Content as Wrapper } from './styles'

import Title from './Title'
import Header from './Header'
import GoBack from './GoBack'
import Action from './Action'
import Item from './Item'
import Card from './Card'
import Content from './Content'
import Subtitle from './Subtitle'

export { Title, Header, GoBack, Action, Item, Card, Content, Subtitle }

export interface ISidePanel {
  isVisible: boolean
  setIsSidePanelVisible: Dispatch<SetStateAction<boolean>>
}

const SidePanel: React.FC<ISidePanel> = ({ isVisible, setIsSidePanelVisible }) => {
  const ref = document.getElementById('sidepanel') as HTMLDivElement
  const observer = useMemo(
    () =>
      new MutationObserver((mutations) => {
        const hasContent = mutations.find((obj: MutationRecord) => {
          const childs = obj.target.childNodes
          return childs.length > 0
        })
        if (!hasContent) {
          return setIsSidePanelVisible(false)
        }
        return setIsSidePanelVisible(true)
      }),
    [setIsSidePanelVisible]
  )

  useEffect(() => {
    if (ref) {
      observer.observe(ref, { childList: true })
    }

    return () => {
      observer.disconnect()
    }
  }, [ref, observer])

  return (
    <SidePanelContainer>
      <Wrapper id='sidepanel' />
    </SidePanelContainer>
  )
}

export default SidePanel
