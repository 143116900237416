import { IEntity, ENTITIES } from 'interfaces'
import DefaultComponent from './pages'
import Routes from './routes'
import buildTheme from 'theme'

const { theme, materialTheme, logo } = buildTheme(ENTITIES.PJ)
export const LegalPersonEntity: IEntity = {
  id: ENTITIES.PJ,
  name: 'Pessoa Juridica',
  path: '/legal-person',
  theme,
  materialTheme,
  logo,
  permissions: [],
  component: DefaultComponent,
  route: Routes,
}

export default LegalPersonEntity
