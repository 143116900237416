import styled from 'styled-components'
import { breakpoint } from 'helpers'

export const Container = styled.div`
  width: 35px;
  height: 35px;
  background: ${({ theme }) => theme.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 35px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  cursor: pointer;

  svg {
    font-size: 1.5rem;
  }

  @media (max-width: ${breakpoint('sm')}) {
    position: fixed;
    right: 15px;
    top: auto;
    bottom: 35px;
    width: 45px;
    height: 45px;
  }
`
