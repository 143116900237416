import styled from 'styled-components'
import { spacing } from 'helpers'

interface IContainer {
  noMarging?: boolean
}

export const Container = styled.div<IContainer>`
  ${({ noMarging }) =>
    !noMarging &&
    `
    margin-bottom: ${spacing(3)};
    margin-top: ${spacing(3)};  
  `}

  &:first-child {
    margin-top: unset;
  }
`
