import React from 'react'
import { Button as MaterialButton, ButtonProps } from '@material-ui/core'
import { useMediaQuery } from 'hooks'

interface IButton extends ButtonProps {
  color?: 'inherit' | 'primary' | 'secondary' | 'default'
  size?: 'small' | 'medium' | 'large'
  variant?: 'text' | 'outlined' | 'contained'
  type?: 'button' | 'submit' | 'reset'
  textWhite?: boolean
  className?: string
  [propName: string]: any
}
export const Button: React.FC<IButton> = (props) => {
  const { children, variant, textWhite, className } = props
  const isSmallDevice = useMediaQuery('sm')
  const buttonProps = {
    ...props,
  }
  delete buttonProps.textWhite
  return (
    <MaterialButton
      variant={variant || 'contained'}
      size={isSmallDevice ? 'small' : 'medium'}
      {...buttonProps}
      disableElevation
      className={textWhite ? 'text-white' : className}
    >
      {children}
    </MaterialButton>
  )
}
export default Button
