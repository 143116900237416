import React from 'react'
import NumberFormat from 'react-number-format'

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void
  onChange: (event: { target: { value: string } }) => void
  prefix?: string
}

const CurrencyInput: React.FC<NumberFormatCustomProps> = (props) => {
  const { inputRef, onChange, prefix, ...other } = props

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        })
      }}
      isNumericString
      prefix={prefix === undefined ? 'R$ ' : prefix}
      decimalScale={2}
      fixedDecimalScale
      thousandSeparator='.'
      decimalSeparator=','
      allowNegative={false}
    />
  )
}

export default CurrencyInput
