import { Height, ZIndex } from 'constants/css'
import { breakpoint } from 'helpers'
import styled from 'styled-components'

export const Navbar = styled.header`
  width: 100%;
  height: ${Height.NAVBAR};
  background: white;
  border-bottom: 1px solid #d2d4da;
  display: flex;
  align-items: center;
  transition: 0.2s all cubic-bezier(0.22, 0.61, 0.36, 1);
  z-index: ${ZIndex.NAVBAR};

  @media (max-width: ${breakpoint('md')}) {
    background: ${({ theme }) => theme.secondary};
    position: initial;
    padding-left: 0;
    color: white;
    min-width: 90vw;
  }

  & > div {
    height: 100%;
  }
`
