import styled from 'styled-components'
import { IItem } from '.'
import { spacing } from 'helpers'

export const ModuleItem = styled.div<IItem>`
  border: 1px solid ${props => props.theme.border};
  border-top: 0;
  display: flex;
  align-items: center;
  transition: all 0.2s ease;
  padding: ${spacing(2)};

  height: 65px;

  ${({ loading, theme }) =>
    !loading &&
    `
    cursor: pointer;
    &:hover {
      background: ${theme.container};
    }
  `}

  &:last-child {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
`
