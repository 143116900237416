export const LGPDListQuery = [
  'id',
  'nome',
  'sobrenome',
  'email',
  'matricula',
  'data_admissao',
  'salario',
  'irrf',
  'inss',
  'cpf',
  'descontos',
  'comprometimento_outros',
]

export const LGPDViewQuery = [
  'id',
  'empresa',
  'email',
  'cpf',
  'ip',
  'contracheque',
  'nome',
  'sobrenome',
  'sexo',
  'data_nascimento',
  'nome_mae',
  'matricula',
  'data_admissao',
  'cargo',
  'salario',
  'descontos',
  'inss',
  'irrf',
  'comprometimento_outros',
  'criado_em',
  'alterado_em',
]
