import { createMuiTheme } from '@material-ui/core/styles'
import { spacing as spacingSize } from 'constants/css'

import buildTheme from './builder'

const defaultTheme = createMuiTheme({
  spacing: spacingSize,
})

export const theme = {
  ...defaultTheme,
  overrides: {
    MuiOutlinedInput: {
      notchedOutline: {
        borderWidth: '2px',
      },
    },
    MuiDialog: {
      container: {},
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontSize: 16,
    fontWeightBold: 500,
    h1: {
      fontSize: '2rem',
      fontWeight: 500,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.5rem',
      },
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 500,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1.25rem',
      },
    },
    h3: {
      fontSize: '1.25rem',
      fontWeight: 500,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '1rem',
      },
    },
    h5: {
      fontSize: '1rem',
      fontWeight: 500,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.875rem',
      },
    },
    subtitle1: {
      fontSize: '0.875rem',
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.75rem',
      },
    },
    subtitle2: {
      fontSize: '0.7rem',
      fontWeight: 400,
    },
    root: {
      fontSize: '1rem',
    },
  },
}

export default buildTheme
