import styled from 'styled-components'
import { Container as MaterialContainer } from '@material-ui/core'
import { breakpoint } from 'helpers'

export const Container = styled(MaterialContainer)`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;

  @media (max-width: ${breakpoint('sm')}) {
    padding: 0;
  }
`
