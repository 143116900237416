import React, { useLayoutEffect, useMemo } from 'react'
import { useCore } from 'hooks'
import { Helpers } from 'assets/styles/helpers'
import { useLocation } from 'react-router-dom'
import buildModules from 'components/RoutesBuilder'
import Spinner from 'components/Spinner'
import FeedbackEntity from './Feedback'

import 'typeface-roboto'
import DefaultEntity from './Default'
import { cancelRequests } from 'services/api'

const Routes = () => {
  const { entity, auth } = useCore()
  const location = useLocation()
  const routes = useMemo(() => {
    if (entity.name !== DefaultEntity.name) {
      return buildModules([entity, FeedbackEntity])
    }
    return buildModules([entity])
  }, [entity])

  useLayoutEffect(() => {
    return () => {
      if (location.pathname && auth.usuario && entity.funcao) {
        cancelRequests()
      }
    }
  }, [auth.usuario, entity.funcao, location.pathname])

  return (
    <>
      <Helpers />
      {routes}
      {!routes && <Spinner noOverlay />}
    </>
  )
}

export default Routes
