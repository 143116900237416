import styled from 'styled-components'
import { CircularProgress, LinearProgress } from '@material-ui/core'
import { ZIndex } from 'constants/css'

interface ILoading {
  noOverlay?: boolean
}

interface ILinearProgressBar {
  absolute?: number
}

interface ILoadOverlay {
  noLoadOverlay?: boolean
}

export const LoadOverlay = styled.div<ILoadOverlay>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.gray_100};
  z-index: ${ZIndex.SPINNER};
  opacity: 0;
  top: 0;
  left: 0;

  ${({ noLoadOverlay }) =>
    noLoadOverlay &&
    `display: none
  `}
`

export const Loading = styled('div')<ILoading>`
  z-index: ${ZIndex.LOADER};

  ${props =>
    props.noOverlay &&
    `
      ${CircularProgressBar} {
        display: none;
      }   
    `}
`

export const CircularProgressBar = styled(CircularProgress)`
  height: 2.5em;
  width: 2.5em;
  border-radius: 200px;
  border: 5px solid white;
  box-sizing: border-box;
  background-size: contain;
  background-color: white;
  box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.27);
  position: fixed;
  z-index: ${ZIndex.CIRCULAR_SPINNER};
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  svg {
    color: ${props => props.theme.primary};
  }
`

export const LinearProgressBar = styled(LinearProgress)<ILinearProgressBar>`
  top: 0;
  left: 0;
  position: fixed;
  z-index: ${ZIndex.SPINNER};
  width: 100%;
  div {
    background-color: ${props => props.theme.primary};
  }

  ${props =>
    props.absolute &&
    `
      position: absolute;
    `}
`
