import * as React from 'react'

function LogoBamaq(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox='0 0 162 90'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      {...props}
    >
      <path
        d='M47.5348 66.9992H48.9969L41.306 78.4695C40.0042 80.4005 39.0228 80.9991 37.5006 80.9991C36.5192 80.9991 35.5178 80.6708 34.9971 80.0915L35.7581 79.0874C36.2188 79.5508 36.8197 79.8212 37.6208 79.8212C38.5221 79.8212 39.2231 79.4156 40.0442 78.1798L40.7853 77.0984L38.1616 66.9992H39.6036L41.7867 75.6502L47.5348 66.9992Z'
        fill='#0473E2'
      />
      <path
        d='M35.5558 67.074H36.9999L34.8643 76.9057H33.4813L33.827 75.3543C32.8101 76.3823 31.3864 76.9991 29.6576 76.9991C26.9118 76.9991 25 75.429 25 72.8309C25 69.4665 27.5627 66.9992 31.0406 66.9992C33.0745 66.9992 34.4982 67.7656 35.1084 69.1301L35.5558 67.074ZM29.9627 75.8216C32.627 75.8216 34.5999 73.8964 34.5999 71.2235C34.5999 69.2983 33.3185 68.1581 31.0813 68.1581C28.4169 68.1581 26.4441 70.102 26.4441 72.7749C26.4441 74.7001 27.7254 75.8216 29.9627 75.8216Z'
        fill='#0473E2'
      />
      <path
        d='M18.612 66.9992C21.1875 66.9992 22.9999 68.6235 22.9999 71.292C22.9999 74.792 20.5961 77.3444 17.3147 77.3444C15.4069 77.3444 14.0715 76.5516 13.4992 75.1207L12.3545 80.9991H11L13.7281 67.0766H15.0254L14.7011 68.7009C15.655 67.618 16.9904 66.9992 18.612 66.9992ZM17.2956 76.1262C19.7948 76.1262 21.6263 74.1345 21.6263 71.3694C21.6263 69.3777 20.4244 68.1981 18.3258 68.1981C15.8267 68.1981 13.9952 70.1898 13.9952 72.9743C13.9952 74.966 15.1971 76.1262 17.2956 76.1262Z'
        fill='#0473E2'
      />
      <path
        d='M57.8291 56.9995L59.2251 48.8353H27.5615L64.0476 17.8499L64.9994 12H19.3125L17.9165 20.1642H48.6917L12.0153 51.2138L11 56.9995H57.8291Z'
        fill='#071D49'
      />
      <path
        d='M116.872 50.9994L120.795 28.7393L140.295 50.9994H146.394L152 18.9997H145.167L141.244 41.3106L121.706 18.9997H115.606L110 50.9994H116.884H116.872Z'
        fill='#071D49'
      />
      <path
        d='M97.0613 18.6418L94.1095 24.3916C99.0592 25.752 101.521 29.474 100.567 34.8132C99.2655 42.2443 93.0526 46.8776 84.5324 46.8776C76.5406 46.8776 72.2225 42.6807 73.3955 35.8784C74.4782 29.9233 78.6932 25.7649 84.7257 24.3531L85.2284 18C75.1356 19.4503 67.9302 25.7906 66.3189 34.8132C64.3855 45.761 71.4104 52.9996 84.2874 52.9996C97.1644 52.9996 105.891 46.1974 107.683 35.8784C109.216 27.2408 105.143 20.9391 97.0484 18.6546L97.0613 18.6418Z'
        fill='#071D49'
      />
      <path
        fill-rule='evenodd'
        clip-rule='evenodd'
        d='M92.0057 11.0723C93.3981 11.4119 94.26 12.8515 93.9308 14.2878L90.1135 30.9417C89.7843 32.378 88.3886 33.267 86.9962 32.9274C85.6038 32.5878 84.7419 31.1482 85.0711 29.7119L88.8883 13.0581C89.2176 11.6218 90.6132 10.7327 92.0057 11.0723Z'
        fill='#0473E2'
      />
    </svg>
  )
}

export default LogoBamaq
