export enum ENTITIES {
  COMPANY = 'company',
  EMPLOYEE = 'employee',
  FEEDBACK = 'feedback',
  SALES_CHANNEL = 'sales_channel',
  DEFAULT = 'default',
  CORBAN = 'corban',
  PJ = 'legal_person',
  SELF_ATTENDANCE = 'self_attendance',
}

export enum MODULES {
  COMPANY = 'empresa',
  EMPLOYEE = 'emprego',
  SALES_CHANNEL = 'canalvenda',
  PJ = 'pessoajuridica',
}
