import * as React from 'react'

function LogoAfinz(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width='50%' height='100%' viewBox='0 0 723.358 274.926'>
      <g id='Grupo_3' data-name='Grupo 3' transform='translate(-599.656 -402.537)'>
        <g id='Grupo_2' data-name='Grupo 2'>
          <path
            id='Caminho_1'
            data-name='Caminho 1'
            d='M771.486,629.5v41.542a102.8,102.8,0,0,1-29.457,4.153c-14.728,0-27.946-4.153-36.254-13.218-13.218,10.952-29.834,15.483-47.961,15.483-30.967,0-58.158-18.126-58.158-57.023,0-37.765,27.568-57.4,67.6-57.4a101.112,101.112,0,0,1,27.19,3.4v-7.932c0-13.218-7.175-21.9-27.945-21.9-19.638,0-40.408,4.909-55.514,10.951V496.571c18.5-6.419,42.674-10.951,61.933-10.951,50.983,0,82.327,18.881,82.327,76.662v56.646c0,8.309,3.777,11.331,10.2,11.331A33.033,33.033,0,0,0,771.486,629.5Zm-77.041,2.265V600.424a50.779,50.779,0,0,0-13.217-1.889c-13.973,0-22.659,5.666-22.659,20.772,0,13.972,7.931,19.259,16.994,19.259C683.494,638.566,689.159,636.678,694.445,631.768Z'
          />
          <path
            id='Caminho_2'
            data-name='Caminho 2'
            d='M794.851,544.155H772.57V490.907h22.281V470.891c0-53.248,35.877-68.354,67.6-68.354,14.352,0,24.548,1.888,35.5,6.421v50.226a52.35,52.35,0,0,0-18.881-3.4c-13.219,0-22.282,5.665-22.282,18.882v16.24H890.4v53.248h-33.61V672.176H794.851Z'
          />
          <path
            id='Caminho_3'
            data-name='Caminho 3'
            d='M913.051,437.447c0-21.032,14.926-29.852,31.549-29.852s31.209,8.82,31.209,29.852-14.586,30.53-31.209,30.53S913.051,458.479,913.051,437.447Z'
          />
          <rect
            id='Retângulo_1'
            data-name='Retângulo 1'
            width='61.933'
            height='181.27'
            transform='translate(913.653 490.907)'
          />
          <path
            id='Caminho_4'
            data-name='Caminho 4'
            d='M1005.04,490.907h55.892v16.239c10.574-12.463,25.68-21.526,48.339-21.526,26.813,0,54.381,18.881,54.381,56.646v129.91h-61.934V557.749c0-10.2-5.287-14.727-14.35-14.727-9.442,0-15.862,5.665-20.393,12.84V672.176H1005.04Z'
          />
          <g id='Grupo_1' data-name='Grupo 1'>
            <path
              id='Caminho_5'
              data-name='Caminho 5'
              d='M1319.615,535.09l-62.25,93h65.649v44.088H1185.929V639.041l72.669-106.31h-69.271V490.907h130.288Z'
            />
            <rect
              id='Retângulo_2'
              data-name='Retângulo 2'
              width='130.544'
              height='43.684'
              transform='translate(1189.456 557.608)'
              fill='#00c6cc'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default LogoAfinz
