import { createGlobalStyle } from 'styled-components'
import 'react-circular-progressbar/dist/styles.css'

export const Helpers = createGlobalStyle`

  .align-items-center-icon {
    align-items: center;
  }

  .text-white {
    color: white !important;
  }

  .cursor-pointer {
    cursor: pointer !important;
  }

  body {
    font-size: 1rem;
    padding: unset !important;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  li {
    list-style: none;
  }

  html {
    background-color: #f0f1f4;
  }

  div.uncompatible-navigator {
    nav.navbar,
    div.sidebar,
    div.sidepanel {
      top: 40px !important;
    }
  
    main {
      margin-top: 90px !important;
    }
  
    .uncompatible-navigator-alert {
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      height: 40px;
      line-height: 40px;
      text-align: center;
      vertical-align: middle;
      align-items: center;
      display: flex;
      justify-content: center;
      color: white;
      background: red;
  
      a {
        color: $white !important;
        margin-left: 3px;
      }
      span {
        margin-left: 3px;
      }
    }
  }
  .format-spans {
    display: grid;
    padding: 16px 0px;

    span {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      color: #111224;
    }
  }

  .th-import-csv {
    th {
      font-size: 16px;
      font-weight: 600;
      color: #282A39;
    }
  }

  .tbody-import-csv {
    td {
      padding: 8px 12px;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
    }
  }

  .styleButton {
    background: #FFF;
    border: 1px solid #D2D4DA;
  }
  
  .selectedStyle {
    border: 1px solid #282A39;
    background: #E8E9EC;
    box-shadow: none;
  
    &:focus {
      box-shadow: none;
    }
  }
  
  /* Estilo para o primeiro elemento */
  .styleButton:first-child,
  .selectedStyle:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  
  /* Estilo para o último elemento */
  .styleButton:last-child,
  .selectedStyle:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  
  /* Estilo comum para todos os elementos, exceto o primeiro e o último */
  .styleButton:not(:first-child):not(:last-child),
  .selectedStyle:not(:first-child):not(:last-child) {
    /* Adicione aqui as atribuições comuns para todos os elementos, exceto o primeiro e o último */
    border-radius: 0;
  }
  
  /* Hide scrollbar for Chrome, Safari and Opera */
  .hide_scroll::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .hide_scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
`
