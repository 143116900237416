import React from 'react'
import { useHistory } from 'react-router-dom'
import { useRoutes, useErrors } from 'hooks'
import { Container, Layout } from 'templates/DefaultTemplate'
import Content from 'templates/FeedbackLayout'

const Feedback: React.FC = ({ children }) => {
  const { error } = useErrors()
  const history = useHistory()
  const { defaultRoutePath } = useRoutes()

  if (history.location.pathname === '/' && !error.code) {
    history.push(defaultRoutePath)
  }

  return (
    <Layout>
      <Container>
        <Content>{children}</Content>
      </Container>
    </Layout>
  )
}

export default Feedback
