import { Grid, useTheme } from '@material-ui/core'
import React from 'react'

const BlockRow: React.FC = ({ children }) => {
  const theme = useTheme()
  return (
    <Grid
      container
      style={{ borderBottom: `1px solid ${theme.palette.grey[100]}`, padding: '8px 16px 16px' }}
      wrap='nowrap'
    >
      {children}
    </Grid>
  )
}

export default BlockRow
