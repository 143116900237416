import React, { useState, useEffect, createContext } from 'react'
import { ILayout } from 'interfaces'
import { useRoutes } from 'hooks'
import { TLayoutContext } from 'types'

import Navbar from './Navbar'
import SideBar from './SideBar'
import Container from './Container'
import SidePanel from './SidePanel'
import Title from './Title'
import UserAvatar from './UserAvatar'
import Content, { ContentTitle } from './Content'
import Header from './Header'
import Actions from './Actions'
import GoBack from './GoBack'

import { Layout, Main, Content as StyledContent } from './styles'

export { Container, Title, UserAvatar, Content, ContentTitle, Header, Actions, GoBack }
export * from './View'

export const LayoutContext = createContext<TLayoutContext>({} as TLayoutContext)

const ModuleTemplate: React.FC<ILayout> = ({ children }) => {
  const { route } = useRoutes()
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isOpened, setIsOpened] = useState(false)
  const [isSidePanelVisible, setIsSidePanelVisible] = useState(false)
  const setOpened = () => setIsOpened((prevState) => !prevState)

  useEffect(() => {
    if (!isSidePanelVisible) {
      setIsCollapsed(false)
    } else {
      setIsCollapsed(true)
    }
  }, [isSidePanelVisible])

  useEffect(() => {
    if (!route) {
      return
    }
    if (isSidePanelVisible) {
      setIsCollapsed(true)
    }
  }, [route, setIsCollapsed, isSidePanelVisible])

  return (
    <Layout isCollapsed={isCollapsed} isOpened={isOpened} isSidePanelVisible={isSidePanelVisible}>
      <SideBar
        setIsCollapsed={() => setIsCollapsed(!isCollapsed)}
        isCollapsed={isCollapsed}
        isOpened={isOpened}
        setIsOpened={setOpened}
        isSidePanelVisible={isSidePanelVisible}
      />
      <SidePanel isVisible={isSidePanelVisible} setIsSidePanelVisible={setIsSidePanelVisible} />
      <StyledContent>
        <Navbar setIsOpened={setOpened} />
        <Main>{children}</Main>
      </StyledContent>
    </Layout>
  )
}

export default ModuleTemplate
