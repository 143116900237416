import React from 'react'
import { Container } from './styles'

export { default as ContentTitle } from './Title'
export { default as ContentBlock } from './Block'
export { default as ContentBlockRow } from './Row'
export { default as ContentBlockItem } from './Item'

export interface IContent {
  withoutBackground?: boolean
  color?: 'default' | 'gray' | 'light' | 'unset'
  margin?: string
  padding?: string
}
const Content: React.FC<IContent> = ({
  children,
  withoutBackground,
  color,
  padding = '32px',
  margin,
}) => {
  return (
    <Container
      withoutBackground={withoutBackground}
      color={color}
      padding={padding}
      margin={margin}
    >
      {children}
    </Container>
  )
}

export default Content
