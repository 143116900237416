import * as React from 'react'

function OnidataMail(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={108.136} height={25.005} opacity='0.6' viewBox='0 0 425.07 120' {...props}>
      <g id='Grupo_8' data-name='Grupo 8' transform='translate(1821.508 -220)'>
        <path
          id='Caminho_1'
          data-name='Caminho 1'
          d='M-1745.592,253.155c.519.329.98.719,1.477,1.072l11.495-4.845h0q1.421-3.03,2.865-6.258c1.173-2.623,3.558-10.717,3.582-10.8h0l-31.573,16.1A34.042,34.042,0,0,1-1745.592,253.155Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_2'
          data-name='Caminho 2'
          d='M-1795.179,319.143c-.519-.329-.98-.718-1.476-1.071l-11.5,4.844h0q-1.421,3.03-2.866,6.258c-1.172,2.623-3.557,10.717-3.581,10.8h0l31.573-16.1A34.044,34.044,0,0,1-1795.179,319.143Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_3'
          data-name='Caminho 3'
          d='M-1770.386,265.555a10.165,10.165,0,0,0-10.165,10.166v20.857a10.166,10.166,0,0,0,10.165,10.166,10.166,10.166,0,0,0,10.166-10.166V275.721A10.165,10.165,0,0,0-1770.386,265.555Z'
          fill='none'
        />
        <path
          id='Caminho_4'
          data-name='Caminho 4'
          d='M-1791.524,291.79v0l-16.739-.2a7.384,7.384,0,0,0-2.44.377,4.791,4.791,0,0,0-3.255,3.03l-6.723,23.94c-1.87,6.107-.537,12.536,3.722,17.959l2.438,3.1h0S-1794.159,295.895-1791.524,291.79Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_5'
          data-name='Caminho 5'
          d='M-1749.324,280.461v0l16.738.25a7.374,7.374,0,0,0,2.442-.369,4.794,4.794,0,0,0,3.264-3.019l6.8-23.919c1.889-6.1.577-12.534-3.665-17.971l-2.428-3.111h0S-1746.676,276.364-1749.324,280.461Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_6'
          data-name='Caminho 6'
          d='M-1732.626,283.45l-16.74-.252-4.938-.075,2.681-4.148c1.017-1.573,5.106-9.98,9.622-19.455a31.69,31.69,0,0,0-5.057-4.054,31.249,31.249,0,0,0-15.062-4.809c-.581-.032-1.156-.088-1.746-.088h-13.04a31.46,31.46,0,0,0-31.46,31.461v6.825l.011,0h.124l16.74.2,4.939.058-2.668,4.157c-1.016,1.583-5.079,10.006-9.566,19.5a31.686,31.686,0,0,0,5.073,4.068,31.228,31.228,0,0,0,15.062,4.809c.581.032,1.156.089,1.745.089h13.04a31.461,31.461,0,0,0,31.461-31.461v-6.85c-.077,0-.13.031-.208.031Zm-27.594,13.128a10.166,10.166,0,0,1-10.166,10.166,10.166,10.166,0,0,1-10.165-10.166V275.721a10.165,10.165,0,0,1,10.165-10.166,10.165,10.165,0,0,1,10.166,10.166Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_7'
          data-name='Caminho 7'
          d='M-1689.5,250.569h-26.438a21.568,21.568,0,0,1-.749,3.587l-6.8,23.918a6.892,6.892,0,0,1-2.685,3.618v36.667a2.224,2.224,0,0,0,2.224,2.224h17.778l0,0-.278-54.73h0c.039-.01,20.282-4.987,20.282,9.724v45.009h17.779a2.224,2.224,0,0,0,2.224-2.224V273.908A23.34,23.34,0,0,0-1689.5,250.569Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_8'
          data-name='Caminho 8'
          d='M-1649.952,250.428c-4.8,0-10.177,5.089-10.177,10.178v50.18c0,4.448,2.62,8.738,6.861,10.083a10.213,10.213,0,0,0,13.5-9.654V260.608A10.18,10.18,0,0,0-1649.952,250.428Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_9'
          data-name='Caminho 9'
          d='M-1652.495,245.056h4.887a7.764,7.764,0,0,0,7.788-6.767,7.635,7.635,0,0,0-7.586-8.5h-4.887a7.764,7.764,0,0,0-7.788,6.766A7.636,7.636,0,0,0-1652.495,245.056Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_10'
          data-name='Caminho 10'
          d='M-1574.882,220.067a17.994,17.994,0,0,0-18.022,17.995v14.2h0a30.357,30.357,0,0,0-11.495-1.81c-16.413.577-29.272,14.378-29.272,30.8v9.215c0,16.251,13.276,30.364,29.522,30.805a30.232,30.232,0,0,0,16.917-4.594h0a17.923,17.923,0,0,0,12.343,4.887,2.244,2.244,0,0,0,2.228-2.25v-97A2.242,2.242,0,0,0-1574.882,220.067Zm-18.3,75.684a10.27,10.27,0,0,1-9.2,10.311,10.124,10.124,0,0,1-11.04-10.081V275.738a10.123,10.123,0,0,1,10.123-10.123c5.341,0,10.12,5.342,10.12,10.122Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_11'
          data-name='Caminho 11'
          d='M-1462.656,303.473c-.042.038-.088.071-.131.108-.122.105-.248.206-.375.3s-.245.188-.372.278-.252.176-.382.259-.277.173-.419.255-.256.146-.388.215c-.152.079-.307.152-.464.223-.13.059-.259.119-.392.173-.167.068-.337.127-.508.186-.13.045-.259.092-.391.132-.184.055-.372.1-.559.144-.126.03-.25.064-.378.089-.212.042-.429.07-.645.1-.109.014-.215.035-.325.046-.328.032-.661.05-1,.05a10.123,10.123,0,0,1-10.123-10.122v-29.8l0,0h18.554a2.25,2.25,0,0,0,2.25-2.25V253.178a2.25,2.25,0,0,0-2.25-2.25H-1479.5l0,0v-18.4A12.521,12.521,0,0,0-1492.026,220h-7.72l0,0v71.22c0,30,19.25,30,30.365,30a30.207,30.207,0,0,0,15.655-4.35v0l-.473-.709S-1459.782,307.783-1462.656,303.473Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_12'
          data-name='Caminho 12'
          d='M-1396.72,280.8a30.369,30.369,0,0,0-25.3-29.938v0l-.076-.011c-.3-.05-.608-.094-.913-.135-.166-.022-.331-.043-.5-.063-.266-.031-.532-.061-.8-.085-.246-.022-.494-.039-.741-.056-.206-.014-.411-.03-.617-.04-.469-.022-.941-.035-1.416-.035h0a30.239,30.239,0,0,0-13.164,2.994q-1.328.639-2.581,1.4a30.413,30.413,0,0,0-3.57,2.538c-.558.46-1.093.946-1.616,1.445s-1.052,1.025-1.546,1.569a30.555,30.555,0,0,0-2.7,3.439,30.389,30.389,0,0,0-2.192,3.813,30.166,30.166,0,0,0-2.644,8.541,30.5,30.5,0,0,0-.35,4.624V290.92a30.3,30.3,0,0,0,11.05,23.431q.563.463,1.147.9a30.269,30.269,0,0,0,3.694,2.368q.646.351,1.31.671a30.239,30.239,0,0,0,13.164,2.994h0c.466,0,.929-.014,1.39-.035.137-.006.274-.014.411-.022.39-.023.779-.058,1.169-.091l.218-.019q.654-.069,1.3-.165c.143-.021.285-.046.428-.07.3-.049.6-.1.9-.161.164-.032.327-.063.49-.1.385-.082.767-.169,1.146-.265.184-.046.365-.1.547-.148.238-.065.475-.131.71-.2.212-.064.423-.129.633-.2.192-.062.381-.128.571-.193.406-.141.808-.289,1.207-.447.134-.053.269-.1.4-.158.252-.1.5-.212.749-.322.114-.05.228-.1.341-.154q1.254-.576,2.454-1.261l.207-.119c.269-.157.536-.316.8-.481h0a17.929,17.929,0,0,0,12.322,4.887,2.249,2.249,0,0,0,2.25-2.249Zm-29.67,25.281a10.1,10.1,0,0,1-7.869-2.958,10.089,10.089,0,0,1-2.948-7.14V275.738a10.124,10.124,0,0,1,10.124-10.123,10.12,10.12,0,0,1,10.12,10.121h0V295.75A10.5,10.5,0,0,1-1426.39,306.079Z'
          fill='#7b7b7b'
        />
        <path
          id='Caminho_13'
          data-name='Caminho 13'
          d='M-1531.039,250.86v0l-.076-.011c-.3-.05-.608-.094-.913-.135-.166-.022-.331-.043-.5-.063-.266-.031-.532-.061-.8-.085-.246-.022-.494-.039-.741-.056-.206-.014-.411-.03-.617-.04-.469-.022-.941-.035-1.416-.035h0a30.239,30.239,0,0,0-13.164,2.994q-1.328.639-2.581,1.4a30.413,30.413,0,0,0-3.57,2.538c-.558.46-1.093.946-1.616,1.445s-1.052,1.025-1.546,1.569a30.555,30.555,0,0,0-2.7,3.439,30.373,30.373,0,0,0-2.192,3.813,30.162,30.162,0,0,0-2.644,8.541,30.518,30.518,0,0,0-.35,4.624V290.92a30.3,30.3,0,0,0,11.05,23.431q.563.463,1.147.9a30.333,30.333,0,0,0,3.694,2.368q.647.351,1.31.671a30.239,30.239,0,0,0,13.164,2.994h0c.466,0,.929-.014,1.39-.035.137-.006.274-.014.411-.022.39-.023.779-.057,1.169-.091l.218-.019q.654-.069,1.3-.165c.143-.021.285-.046.428-.07q.453-.073.9-.161c.164-.032.327-.063.49-.1.385-.082.767-.169,1.146-.265.184-.046.365-.1.547-.148.238-.065.475-.131.71-.2.212-.064.423-.129.633-.2.191-.062.381-.128.57-.193q.61-.211,1.209-.447c.134-.053.268-.1.4-.158.252-.1.5-.212.749-.322q.171-.075.339-.153,1.256-.576,2.457-1.263c.069-.039.138-.078.206-.118.261-.152.521-.307.777-.467l.044,0a17.924,17.924,0,0,0,12.3,4.869,2.249,2.249,0,0,0,2.25-2.249l-.282-38.519A30.369,30.369,0,0,0-1531.039,250.86Zm5.061,24.877V295.7a10.56,10.56,0,0,1-3.191,7.487,9.891,9.891,0,0,1-6.438,3.115,10.093,10.093,0,0,1-7.868-2.958,10.089,10.089,0,0,1-2.948-7.14V275.963a10.086,10.086,0,0,1,3.069-7.253,10.123,10.123,0,0,1,7.292-3.1h0l.249.249a10.1,10.1,0,0,1,9.609,9.609l.226.226Z'
          fill='#7b7b7b'
        />
      </g>
    </svg>
  )
}

const MemoOnidataMail = React.memo(OnidataMail)
export default MemoOnidataMail
