import * as React from 'react'

export function IdentityCardBack(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 180 100'
      width='100%'
      height='80%'
      {...props}
    >
      <defs>
        <clipPath id='prefix__a'>
          <path d='M0 0h180v100H0z' />
        </clipPath>
      </defs>
      <g data-name='Grupo 1255' clipPath='url(#prefix__a)'>
        <g data-name='Grupo 1'>
          <g data-name='Grupo 968'>
            <path data-name='Ret\xE2ngulo 1062' fill='#fff' d='M24 18h131v64H24z' />
            <path
              data-name='Ret\xE2ngulo 1062 - Contorno'
              d='M29 23v54h121V23H29m-5-5h131v64H24z'
              fill='#40b258'
            />
            <path
              data-name='Uni\xE3o 1'
              d='M153 88v-2h6v-5h2v7zM28 88v-2h123v2zm-10 0v-7h2v5h6v2zm141-9V21h2v58zM18 79V21h2v58zm141-60v-5h-6v-2h8v7zM18 19v-7h8v2h-6v5zm10-5v-2h123v2z'
              fill='#cacaca'
            />
          </g>
          <g data-name='Grupo 1256'>
            <path data-name='Ret\xE2ngulo 1122' fill='#cacaca' d='M41 30.798h36v5H41z' />
            <path data-name='Ret\xE2ngulo 1123' fill='#cacaca' d='M41 41.798h97v2H41z' />
            <path data-name='Ret\xE2ngulo 1124' fill='#cacaca' d='M41 46.798h97v2H41z' />
            <path data-name='Ret\xE2ngulo 1125' fill='#cacaca' d='M41 51.798h97v2H41z' />
            <path
              data-name='Caminho 917'
              d='M53 69.668l-.48-.867 7.841-5.821-13.011 6.379.277-1.057c.309-1.186.466-3.115-.18-3.489-.3-.176-1.644-.41-6.093 3.972l-.7-.712c3.642-3.587 5.961-4.9 7.3-4.125.709.41 1.023 1.341.935 2.766a9.86 9.86 0 01-.088.823l16.025-7.857.518.85-9.231 6.853 8.752-3.412.484.864a77.371 77.371 0 00-3.247 2.6c2.226-.8 6.267-2.368 9-3.459l.488.863a297.55 297.55 0 00-3.794 2.928c1.4-.1 3.964-.241 8.739-.452 4.91-.217 9.809-.4 9.858-.4l.037 1c-7.566.283-19.086.777-20 .988a.512.512 0 01-.65-.193.524.524 0 01.121-.678c.042-.042.1-.095.179-.162.132-.114.328-.275.581-.478.373-.3.878-.7 1.5-1.183-.556.217-1.146.446-1.74.674-5.213 2.007-5.64 2.039-5.822 2.052a.566.566 0 01-.595-.353c-.157-.415.207-.793.78-1.323.327-.3.77-.684 1.32-1.137z'
              fill='#707070'
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IdentityCardBack
