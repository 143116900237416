import React from 'react'
import Layout from 'templates/ModuleTemplate'
import ModuleGuard from 'components/ModuleGuard'
import { ILayout } from 'interfaces'

const Company: React.FC<ILayout> = ({ children }) => {
  return (
    <Layout>
      <ModuleGuard>{children}</ModuleGuard>
    </Layout>
  )
}

export default Company
