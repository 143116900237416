import * as React from 'react'

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={362.959} height={122.967} viewBox='0 0 362.959 122.967' {...props}>
      <g data-name='Grupo 917'>
        <g data-name='Grupo 918'>
          <g data-name='Grupo 223' fill='none' stroke='#fff' strokeMiterlimit={10} strokeWidth={3}>
            <path
              data-name='Caminho 606'
              d='M223.386 87.563v28.688a5.231 5.231 0 01-5.216 5.216h-83.454a5.231 5.231 0 01-5.216-5.216v-96.5a5.231 5.231 0 015.216-5.211h10.432'
            />
            <path
              data-name='Caminho 607'
              d='M207.739 14.54h10.432a5.231 5.231 0 015.216 5.216v39.12'
            />
            <path data-name='Caminho 608' d='M212.955 97.995v13.04h-73.023V24.972h5.216' />
            <path data-name='Caminho 609' d='M207.739 24.972h5.216v44.336' />
            <path
              data-name='Caminho 610'
              d='M194.699 11.932h-7.824a10.432 10.432 0 10-20.864 0h-7.823a7.813 7.813 0 00-7.824 7.824v7.824h52.159v-7.824a7.813 7.813 0 00-7.824-7.824z'
            />
            <path data-name='Linha 14' d='M176.443 9.324v5.216' />
            <path
              data-name='Caminho 611'
              d='M233.818 61.483l-28.687 33.591-13.04-13.04'
              strokeLinejoin='round'
            />
            <path data-name='Linha 15' strokeLinejoin='round' d='M155.58 45.836h5.216' />
            <path data-name='Linha 16' strokeLinejoin='round' d='M166.011 45.836h31.295' />
            <path data-name='Linha 17' strokeLinejoin='round' d='M155.58 56.267h5.216' />
            <path data-name='Linha 18' strokeLinejoin='round' d='M166.011 56.267h31.295' />
            <path data-name='Linha 19' strokeLinejoin='round' d='M155.58 66.699h5.216' />
            <path data-name='Linha 20' strokeLinejoin='round' d='M166.011 66.699h31.295' />
          </g>
        </g>
        <g data-name='Grupo 919'>
          <g
            data-name='Grupo 223'
            fill='none'
            stroke='#fff'
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.3}
          >
            <path
              data-name='Caminho 606'
              d='M95.386 87.563v28.688a5.231 5.231 0 01-5.216 5.216H6.716a5.231 5.231 0 01-5.216-5.216v-96.5a5.231 5.231 0 015.216-5.211h10.432'
            />
            <path
              data-name='Caminho 607'
              d='M79.739 14.54h10.432a5.231 5.231 0 015.216 5.216v39.12'
            />
            <path data-name='Caminho 608' d='M84.955 97.995v13.04H11.932V24.972h5.216' />
            <path data-name='Caminho 609' d='M79.739 24.972h5.216v44.336' />
            <path
              data-name='Caminho 610'
              d='M66.699 11.932h-7.824a10.432 10.432 0 10-20.864 0h-7.823a7.813 7.813 0 00-7.824 7.824v7.824h52.159v-7.824a7.813 7.813 0 00-7.824-7.824z'
            />
            <path data-name='Linha 14' d='M48.443 9.324v5.216' />
            <path
              data-name='Caminho 611'
              d='M105.818 61.483L77.131 95.074l-13.04-13.04'
              strokeLinejoin='round'
            />
            <path data-name='Linha 15' strokeLinejoin='round' d='M27.58 45.836h5.216' />
            <path data-name='Linha 16' strokeLinejoin='round' d='M38.011 45.836h31.295' />
            <path data-name='Linha 17' strokeLinejoin='round' d='M27.58 56.267h5.216' />
            <path data-name='Linha 18' strokeLinejoin='round' d='M38.011 56.267h31.295' />
            <path data-name='Linha 19' strokeLinejoin='round' d='M27.58 66.699h5.216' />
            <path data-name='Linha 20' strokeLinejoin='round' d='M38.011 66.699h31.295' />
          </g>
        </g>
        <g data-name='Grupo 920'>
          <g
            data-name='Grupo 223'
            fill='none'
            stroke='#fff'
            strokeMiterlimit={10}
            strokeWidth={3}
            opacity={0.3}
          >
            <path
              data-name='Caminho 606'
              d='M351.386 87.563v28.688a5.231 5.231 0 01-5.216 5.216h-83.454a5.231 5.231 0 01-5.216-5.216v-96.5a5.231 5.231 0 015.216-5.211h10.432'
            />
            <path
              data-name='Caminho 607'
              d='M335.739 14.54h10.432a5.231 5.231 0 015.216 5.216v39.12'
            />
            <path data-name='Caminho 608' d='M340.955 97.995v13.04h-73.023V24.972h5.216' />
            <path data-name='Caminho 609' d='M335.739 24.972h5.216v44.336' />
            <path
              data-name='Caminho 610'
              d='M322.699 11.932h-7.824a10.432 10.432 0 10-20.864 0h-7.823a7.813 7.813 0 00-7.824 7.824v7.824h52.159v-7.824a7.813 7.813 0 00-7.824-7.824z'
            />
            <path data-name='Linha 14' d='M304.443 9.324v5.216' />
            <path
              data-name='Caminho 611'
              d='M361.818 61.483l-28.687 33.591-13.04-13.04'
              strokeLinejoin='round'
            />
            <path data-name='Linha 15' strokeLinejoin='round' d='M283.58 45.836h5.216' />
            <path data-name='Linha 16' strokeLinejoin='round' d='M294.011 45.836h31.295' />
            <path data-name='Linha 17' strokeLinejoin='round' d='M283.58 56.267h5.216' />
            <path data-name='Linha 18' strokeLinejoin='round' d='M294.011 56.267h31.295' />
            <path data-name='Linha 19' strokeLinejoin='round' d='M283.58 66.699h5.216' />
            <path data-name='Linha 20' strokeLinejoin='round' d='M294.011 66.699h31.295' />
          </g>
        </g>
      </g>
    </svg>
  )
}

const MemoSvgComponent = React.memo(SvgComponent)
export default MemoSvgComponent
