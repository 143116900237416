import styled from 'styled-components'
import { IUserAvatar } from '.'

export const UserAvatar = styled.div<IUserAvatar>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  max-width: ${({ size }) => (size && `${size}px`) || '45px'};
  max-height: ${({ size }) => (size && `${size}px`) || '45px'};
  min-height: ${({ size }) => (size && `${size}px`) || '45px'};
  min-width: ${({ size }) => (size && `${size}px`) || '45px'};
  border: ${({ borderSize }) => (borderSize && `${borderSize}px`) || '2px'} solid
    ${({ theme, color }) => (color && theme[color]) || color || theme.primary};
  color: ${({ theme, color }) => (color && theme[color]) || color || theme.primary};
  font-weight: 500;
  border-radius: 40px;
  font-size: larger;
`
