export const portabilityList = [
  'id',
  'proposta__localizador',
  'proposta__valor_solicitado',
  'proposta__valor_recebivel',
  'proposta__prazo',
  'termo_solicitacao_original_html',
  'termo_solicitacao_original_pdf',
  'status',
  'motivo_cancelamento',
]

export const pendingPortabilityList = [
  'id',
  'status',
  'proposta__funcionario__pessoa__nome',
  'proposta__funcionario__pessoa__sobrenome',
  'proposta__funcionario__pessoa__cpf',
  'proposta__funcionario__matricula',
  'proposta__funcionario__pessoa__telefone_celular',
  'contrato_origem',
  'solicitada_em',
]

export const pendingPortabilityView = [
  'id',
  'status',
  'proposta__funcionario',
  'contrato_origem',
  'solicitada_em',
]

export const corbanCEPPortability = [
  'id',
  'contrato_origem',
  'proposta__id',
  'proposta__localizador',
  'proposta__empresa',
  'proposta__cliente_info_no_contrato',
  'proposta__nome',
  'proposta__sobrenome',
  'proposta__cpf',
  'proposta__telefone_celular',
  'proposta__email',
  'proposta__status',
  'localizador',
  'status',
  'solicitada_em',
  'concluida_em',
]

export const cepPortabilitySignContract = [
  'id',
  'termo_solicitacao_original_html',
  'termo_solicitacao_original_pdf',
  'status',
]
