import styled from 'styled-components'
import { breakpoint, spacing } from 'helpers'
import { Height } from 'constants/css'
import { Container as Content } from '../Content/styles'

export interface IHeader {
  noPadding?: boolean
  noMargin?: boolean
  alignItems?: 'flex-start' | 'center' | 'flex-end'
  useSticky?: boolean
  marginBottom?: string
}

interface ISticky {
  isVisible?: boolean
}

export const Container = styled.div<IHeader & { isSticky?: boolean }>`
  display: flex;
  align-items: ${({ alignItems }) => alignItems || 'center'};
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: ${({ marginBottom }) => (marginBottom ? marginBottom : spacing(2))};
  padding: 0 ${({ noPadding }) => (noPadding ? '0' : spacing(1))};
  ${({ noMargin }) =>
    noMargin &&
    `
    margin-bottom: 0;
    padding-bottom: 0;
  `}

  @media (max-width: ${breakpoint('sm')}) {
    display: flex;
    flex-direction: row;

    ${({ isSticky }) =>
      isSticky &&
      `
      visibility: hidden;
    `}

    h1 {
      font-size: 1.1rem;
      color: ${({ theme }) => theme.secondary};
    }

    & + ${Content}, & + * + ${Content} {
      margin-bottom: ${Height.RESPONSIVE_ACTIONS};
    }
  }
`

export const StickyContainer = styled.div<ISticky>`
  display: none;
  box-shadow: 0px 0px 9px -2px #000000;
  z-index: 100;
  overflow: hidden;

  @media (max-width: ${breakpoint('sm')}) {
    ${({ isVisible, theme }) =>
      isVisible &&
      `
      padding: ${spacing(2)};
      display: flex;
      flex-wrap: wrap;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      background: white;
    
      h1 {
        font-size: 1.1rem;
        color: ${theme.secondary};
      }
    `}
  }
`
