import { useContext } from 'react'
import { CoreContext } from 'components/Provider/CoreProvider'

export function useCore() {
  const core = useContext(CoreContext)
  const getCnpjByName = () => {
    const {
      entity: { funcao },
    } = core
    return String(funcao?.entidade?.nome)
      .match(/(\d{3}\.\d{3}\.\d{3}-\d{2}$)|(^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2})/)?.[0]
      ?.replace(/\.|-|\//g, '')
  }
  return {
    ...core,
    cnpj: getCnpjByName(),
  }
}

export default useCore
